import cx from 'classnames'
import React from 'react'
import { TableActionList } from '@carbon/react'
import { Text } from 'theme-ui'
import theme from '../../config/theme'
import Button from '../Button/Button'

/* This is modified version of carbon TableBatchActions component, to make it more customizable */

const TableBatchActions = ({
  className,
  children,
  shouldShowBatchActions,
  totalSelected,
  onCancel,
  translateWithId,
  hideCancelSelections,
  customLabel,
  totalCount,
  onSelectAll,
  ...rest
}: any) => {

  const [isScrolling, setIsScrolling] = React.useState(false)
  const batchActionsClasses = cx(
    {
      [`cds--batch-actions`]: true,
      [`cds--batch-actions--active`]: shouldShowBatchActions,
    },
    className
  )

  const batchSummaryClasses = cx(`cds--batch-summary`, {
    [`cds--batch-summary__scroll`]: isScrolling,
  })

  return (
    <div
      onScroll={() => {
        setIsScrolling((prev) => !prev)
      }}
      aria-hidden={!shouldShowBatchActions}
      className={batchActionsClasses}
      {...rest}
    >
      <div className={batchSummaryClasses}>
        <p className={`cds--batch-summary__para`}>
          <Text sx={(theme?.text as any).body}>
            {customLabel ? (
              customLabel
            ) : (
              <>
                {totalSelected}{' '}
                {totalSelected > 1 || totalSelected === 0 ? 'items' : 'item'}{' '}
                selected
              </>
            )}
          </Text>
        </p>
      </div>
      <TableActionList>
        {children}
        {!hideCancelSelections && (
          <Button
            kind="ghost"
            size="sm"
            className={`cds--batch-summary__cancel`}
            tabIndex={shouldShowBatchActions ? 0 : -1}
            onClick={onCancel}
          >
            Unselect all
          </Button>
        )}
      </TableActionList>
    </div>
  )
}

export default TableBatchActions
