import { OrganizationAccessEnum } from "../types/general.types"

export enum PRIVILEGES {
  FULL = 'FULL',
  READ_ONLY = 'READ_ONLY',
  NONE = 'NONE',
}

export enum ORGANIZATION_PRIVILEGES {
  FULL = 'FULL',
  READ_ONLY = 'READ_ONLY',
  GUEST_AMBASSADOR = 'GUEST_AMBASSADOR',
  SWITCH_PORT = 'SWITCH_PORT',
  MONITOR_ONLY = 'MONITOR_ONLY'
}


export const PrivilegesMap = {
  [PRIVILEGES.FULL]: {
    name: 'Full',
    value: 'FULL',
  },
  [PRIVILEGES.READ_ONLY]: {
    name: 'Read-only',
    value: 'READ_ONLY',
  },
  [PRIVILEGES.NONE]: {
    name: 'None',
    value: 'NONE',
  },
}

export const TagAccessMap = {
  [ORGANIZATION_PRIVILEGES.FULL]: {
    name: 'Full',
    value: 'FULL',
  },
  [ORGANIZATION_PRIVILEGES.READ_ONLY]: {
    name: 'Read-only',
    value: 'READ_ONLY',
  },
  [ORGANIZATION_PRIVILEGES.GUEST_AMBASSADOR]: {
    name: 'Guest ambassador',
    value: 'GUEST_AMBASSADOR',
  },
  [ORGANIZATION_PRIVILEGES.MONITOR_ONLY]: {
    name: 'Monitor only',
    value: 'MONITOR_ONLY',
  },
}

export const backendTagAccessMap = {
  full: ORGANIZATION_PRIVILEGES.FULL,
  'read-only': ORGANIZATION_PRIVILEGES.READ_ONLY,
  'guest-ambassador': ORGANIZATION_PRIVILEGES.GUEST_AMBASSADOR,
  'monitor-only': ORGANIZATION_PRIVILEGES.MONITOR_ONLY,
}


export const backendPrivilegesMap = {
  full: PRIVILEGES.FULL,
  'read-only': PRIVILEGES.READ_ONLY,
  none: PRIVILEGES.NONE
}
