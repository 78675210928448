import {
    addMonths,
    endOfMonth,
    format,
    formatDistanceStrict,
    isAfter,
    isBefore,
    parseISO,
    startOfMonth,
} from 'date-fns'
import { DATE_FORMAT } from './date.constants'
import { DateBoundary, DateValue } from './date.types'

const parseDate = (value: DateValue) => typeof value === 'string' ? parseISO(value) : value;

export const getCurrentDate = (): Date => new Date();

export const formatDateTime = (value: DateValue, formatString: string = DATE_FORMAT.DEFAULT) => {
  if (!value) {
    return '';
  }
  return format(parseDate(value), formatString);
};

export const formatRelativeTime = (date: DateValue, baseDate: Date = new Date(), includeSuffix: boolean = true) => {
  return formatDistanceStrict(parseDate(date), baseDate, { addSuffix: includeSuffix });
};

export const formatEndOfMonth = (date: DateValue, dateFormat: string = DATE_FORMAT.DEFAULT) => {
  return format(endOfMonth(parseDate(date)), dateFormat);
};

export const addMonthsToDate = (date: DateValue, monthsToAdd: number) => {
  return addMonths(parseDate(date), monthsToAdd);
};

export const isDateWithinMonthBoundary = (
  date: DateValue,
  targetMonth: DateValue = new Date(),
  boundaryType: DateBoundary = 'start'
): boolean => {
  const dateToCheck = typeof date === 'string' ? parseISO(date) : date;
  const targetDate = typeof targetMonth === 'string' ? parseISO(targetMonth) : targetMonth;

  const boundaryDate = boundaryType === 'start' ? startOfMonth(targetDate) : endOfMonth(targetDate);

  return boundaryType === 'start' ? isBefore(dateToCheck, boundaryDate) : isAfter(dateToCheck, boundaryDate);
};
