import { useQuery, UseQueryResult } from '@tanstack/react-query'
import REGIONS_URLS from './regionsUrls'
import RegionsAPI from './RegionsAPI'
import { Region } from '../../constants/region.constants'

type UseUserByIdType = () => UseQueryResult<Region[], Error>

export const useRegions: UseUserByIdType = () => {
  return useQuery([REGIONS_URLS.GET_REGIONS], () => RegionsAPI.getRegions(), {
    staleTime: 120 * 60 * 1000, // (120 minutes)
    cacheTime: 120 * 60 * 1000, // (120 minutes)
    select: (result) => result.data,
    keepPreviousData: true,
  })
}
