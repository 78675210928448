import React from 'react';
import { Button as CdsButton } from '@carbon/react';
import { Text } from 'theme-ui';
import { ForwardRefProps } from 'carbon-components-react/typings/shared';
import {
  ButtonDefaultProps,
  ButtonKindProps,
} from 'carbon-components-react/lib/components/Button/Button';

type ButtonProps = ForwardRefProps<HTMLButtonElement, ButtonDefaultProps & ButtonKindProps & {
  fullWidth?: boolean}>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, style, fullWidth, ...props }, ref) => (
    <CdsButton {...props} ref={ref} style={fullWidth ? { ...style, width: '100%' } : style}>
      <Text variant="button" sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        {children}
      </Text>
    </CdsButton>
  )
);

export default Button;
