/** @jsxImportSource theme-ui */
import React, { useState } from 'react'
import { Container, ThemeUIStyleObject } from 'theme-ui'
import SidePanel from './components/side-panel.component'
import { vh100 } from '../../utils/style.utils'
import ProfileWizard from './components/profile-wizard.component'
import { WizardPage } from './profile-setup.constants'
import { useUserMe } from '../../api/users/usersHooks'
import { Loader } from '@boundlessdigital/bng-shared-components'
import { OnboardingStatus } from '../../api/enums'
import { Navigate } from 'react-router-dom'
import { USER_MANAGEMENT } from '../../constants/routes.constants'
import { IUser } from '../../api/users/types'

const profileSetupRootSx: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  height: vh100,
  overflow: 'hidden',
  flex: 1,
  position: 'relative',
}

const getInitialPage = (activeStep) => {
  if (activeStep === 1) {
    return WizardPage.Workspace
  } else if (activeStep === 2) {
    return WizardPage.Products
  } else if (activeStep === 3) {
    return WizardPage.ApiKey
  } else if (activeStep === 4) {
    return WizardPage.Connect
  }
  return WizardPage.ProcessingData
}

const ProfileSetup: React.FC<{ user: IUser }> = ({ user }) => {
  const [page, setPage] = useState<WizardPage>(
    getInitialPage(user.onboarding.activeStep)
  )

  return (
    <Container sx={profileSetupRootSx}>
      <SidePanel page={page} />
      <ProfileWizard page={page} setPage={setPage} />
    </Container>
  )
}

const ProfileSetupWrapper = () => {
  const { data, isInitialLoading } = useUserMe()

  if (isInitialLoading) return <Loader withOverlay />

  if (
    !data?.onboarding ||
    data?.onboarding.status === OnboardingStatus.NOT_APPLICABLE
  ) {
    return <Navigate to={USER_MANAGEMENT} replace />
  }

  return data?.onboarding ? <ProfileSetup user={data} /> : null
}

export default ProfileSetupWrapper
