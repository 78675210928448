// authStore.ts
import { create } from 'zustand'

export interface AuthState {
  accessToken: string | null,
  setAccessToken: (accessToken: string | null) => void,
}

export const useAuthStore = create<AuthState>((set) => ({
  accessToken: null,
  setAccessToken: (accessToken: string | null) => set(() => ({ accessToken })),
}))
