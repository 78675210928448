import esteeLauderLogo from '../../assets/images/company-logos/estee-lauder-logo.png'
import merakiCiscoLogo from '../../assets/images/company-logos/cisco-meraki-logo.png'
import boundlessDigitalLogo from '../../assets/images/company-logos/boundless-blue-logo.png'
import { SAMLTarget, Service } from '../../api/enums'

// TODO: loginTarget and serviceName should be configure per product

export const USER_LOGINS = [{
  domain: 'estee.com',
  label: 'Estee Lauder - Camera Org',
  logo: esteeLauderLogo,
  organizationId: '1093656',
  loginTarget: SAMLTarget.MERAKI_VISION,
  serviceName: Service.ESTEE_LAUDER_VISION_PORTAL,
}, {
  domain: 'broward.org',
  label: 'Broward',
  logo: merakiCiscoLogo,
  organizationId: '1503299',
  loginTarget: SAMLTarget.MERAKI_VISION,
  serviceName: Service.ESTEE_LAUDER_VISION_PORTAL,
}]

export const DEV_LOGINS = [
    {
        label: 'Meraki Vision Test',
        logo: merakiCiscoLogo,
        organizationId: '1051248',
        loginTarget: SAMLTarget.MERAKI_VISION,
        serviceName: Service.ESTEE_LAUDER_VISION_PORTAL,
    },
    {
        label: 'Boundless Digital',
        logo: boundlessDigitalLogo,
        organizationId: '645140646620827265',
        loginTarget: SAMLTarget.MERAKI_VISION,
        serviceName: Service.ESTEE_LAUDER_VISION_PORTAL,
    },
]

export const SAML_USER_DOMAINS = USER_LOGINS.map(({ domain }) => domain)

export const SAML_ADMINS_EMAILS = [
  'jabell@estee.com',
  'boleary@estee.com',
  'kbhagat@estee.com',
  'dtsao@broward.org'
]
