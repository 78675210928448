/** @jsxImportSource theme-ui */
import { useState } from 'react'
import { Box } from 'theme-ui'
import { components } from 'react-select'
import theme from '../../config/theme'

export const useOptionStyles = (isFocused: boolean, isSelected: boolean) => {
  const [isActive, setIsActive] = useState(false)
  const onMouseDown = () => setIsActive(true)
  const onMouseUp = () => setIsActive(false)
  const onMouseLeave = () => setIsActive(false)

  let bg = 'transparent'
  if (isFocused) bg = '#edf4fe'
  if (isActive) bg = '#e5effd'
  if (isSelected) bg = '#F2F7FE'

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  }

  return {
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  }
}

const Option = ({
  isSelected,
  children,
  label,
  value,
  selectOption,
  isFocused,
  innerProps,
  data,
  ...rest
}: any) => {
  const props = useOptionStyles(isFocused, isSelected)

  return (
    <components.Option
      {...rest}
      data={data}
      selectOption={selectOption}
      isSelected={isSelected}
      innerProps={{ ...innerProps, ...props }}
    >
      {isSelected && (
        <Box
          sx={{
            position: 'absolute',
            width: '3px',
            height: '100%',
            left: '-8px',
            top: 0,
            background: (theme?.colors?.interactive as any).default.primary,
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        />
      )}
      {!data.icon ? (
        children
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              '& svg': {
                color: isSelected ? (theme?.colors?.interactive as any).default.primary : '',
                width: '20px',
                height: '20px',
              },
            }}
          >
            {data.icon}
          </Box>
          <Box sx={{ flex: 1, pl: '8px' }}>{children}</Box>
        </Box>
      )}
    </components.Option>
  )
}

export default Option
