import { Checkmark, InProgress, InProgressError, InProgressWarning } from "@carbon/icons-react"
import { Box, ThemeUIStyleObject, Text } from "theme-ui"
import { backendTagAccessMap } from "../../../constants/privileges.constants";
import { AdminStatus } from "../../../api/admin/admins.types";

export const groupTagsAndNetworksByPrivilege = (data) => {
  if (!data) return
  
  const networks = data.networks || [];
  const tags = data.tags || [];

  const combined = networks.concat(tags);

  const grouped = combined.reduce((acc, item) => {
    const access = item.networkAccess || item.tagAccess;
    const value = item.networkId || item.tagName
    const label =  item.networkName || item.tagName
    const type = item.networkName ? 'network' : 'tag'
    acc[access] = acc[access] || [];
    acc[access].push({ type ,value, label });
    return acc;
  }, {});

  return Object.entries(grouped).map(([access, items]) => ({
    tags: items,
    privilege: backendTagAccessMap[access]
  }));
}

export const headerData = [
  {
    header: 'Name',
    key: 'name',
  },
  {
    header: '',
    key: 'dateAccessAutomation.startDate',
  },
  {
    header: 'Status',
    key: 'status',
  },
  {
    header: '',
    key: 'detailed-tag',
  },
  {
    header: 'Permissions',
    key: 'privilege',
  },
]

export const tableWrapperSx: ThemeUIStyleObject = {
  pt: '0',
  '& .cds--data-table th': {
    px: '16px',
    '&.cds--table-column-checkbox': {
      paddingLeft: '16px !important',
    },
  },
  '& td.detailed-tag': {
    // width: '170px',
    textAlign: 'right'
  },
  '& td.automation-cell': {
    width: '50px',
  },
  '& .cds--data-table--md td': {
    px: '16px',
    py: '12px',
    lineHeight: '11px',
    '&.cds--table-column-checkbox': {
      paddingLeft: '16px !important',
    },
  },
  '& .sub-table-container': {
    '& tr': {
      background: 'transparent !important',
    },
    '& .cds--data-table--sm td': {
      py: '20px !important',
    },
    '& .cell-privilege': {
      width: '244px',
    },
  },
  '& .is-disabled': {
    background: '#FAFBFB !important',
    '& td': {
      background: '#FAFBFB !important',
      color: '#8C9196 !important',
    },
    '& .cds--btn--disabled': {
      '&, &:hover': {
        background: '#ffffff',
        borderColor: 'border.secondary',
      },
    },
    '& .cds--table-expand__button': {
      pointerEvents: 'none',
    },
  },
  '& .is-user-disabled': {
    background: '#FAFBFB !important',
    '& td': {
      background: '#FAFBFB !important',
      color: '#8C9196 !important',
    },
    '& .cds--btn--disabled:not(.cds--btn--ghost)': {
      '&, &:hover': {
        background: '#ffffff',
        borderColor: 'border.secondary',
      },
    },
    '& .cds--btn--disabled.cds--btn--ghost': {
      '&, &:hover': {
        background: 'actions.secondary.disabled',
      },
    },
    '& .cds--table-expand__button': {
      pointerEvents: 'none',
      '& svg': {
        fill: '#8C9196 !important',
      },
    },
  },
}

export const getStatusCell = (status) => {
  const statusMap = {
    [AdminStatus.OK]: { icon: <Checkmark />, text: 'Ok' },
    [AdminStatus.UNVERIFIED]: { icon: <InProgress />, text: 'Unverified' },
    [AdminStatus.PENDING]: { icon: <InProgress />, text: 'Pending' },
    [AdminStatus.LOCKED]: { icon: <InProgressError />, text: 'Locked' },
    [AdminStatus.NOT_CREATED]: { icon: <InProgressError />, text: 'On hold' },
  };

  const statusItem = statusMap[status] || statusMap[AdminStatus.UNVERIFIED];
  
  if (statusItem) {
    return (
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        {statusItem.icon}
        <Text as="p" variant="caption">{statusItem.text}</Text>
      </Box>
    );
  }

  return null
};

export const getCellProps = (cell) => {
  if (cell.info.header === 'privilege') {
    return { className: 'action-cell' }
  } else if (cell.info.header === 'detailed-tag') {
    return { className: 'detailed-tag' }
  } else if (cell.info.header === 'dateAccessAutomation.startDate') {
    return { className: 'automation-cell' }
  } else {
    return undefined
  }
}

export const getRowProps = (value, userDataDisabled) => {
  if (userDataDisabled) {
    return { className: 'is-user-disabled' }
  } else if (value.disabled) {
    return { className: 'is-disabled' }
  } else {
    return undefined
  }
}
