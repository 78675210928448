import { Box, ThemeUIStyleObject } from 'theme-ui'

const VisionPortal = ({
  size = 24,
  svgProps,
  sx,
  className = '',
  ...props
}: {
  as?: React.ElementType
  sx?: ThemeUIStyleObject
  className?: string
  size?: number
  svgProps?: any
}) => (
  <Box {...props} className={`svg-icon ${className}`} sx={{ ...{
    display: 'inline-block',
    flexShrink: 0,
  }, ...sx }}>
    <svg width={size} height={size} viewBox="0 0 123 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60.8582 60.2424C67.4644 60.2424 73.7494 53.97 73.7494 47.3771C73.7494 40.7842 67.4644 34.5117 60.8582 34.5117C54.2521 34.5117 47.9671 40.7842 47.9671 47.3771C47.9671 53.97 54.2521 60.2424 60.8582 60.2424Z" fill="url(#paint0_linear_26_27)"/>
      <path d="M105.782 46.115C102.265 37.0354 96.1593 29.1834 88.2193 23.5294C80.2793 17.8754 70.8532 14.6672 61.1061 14.3014C51.3591 14.6672 41.933 17.8754 33.993 23.5294C26.053 29.1834 19.947 37.0354 16.4299 46.115C16.1923 46.7707 16.1923 47.4887 16.4299 48.1444C19.947 57.2239 26.053 65.0759 33.993 70.7299C41.933 76.3839 51.3591 79.5921 61.1061 79.9579C70.8532 79.5921 80.2793 76.3839 88.2193 70.7299C96.1593 65.0759 102.265 57.2239 105.782 48.1444C106.02 47.4887 106.02 46.7707 105.782 46.115ZM61.1061 66.5282C57.2618 66.5282 53.5037 65.3905 50.3073 63.2589C47.1108 61.1274 44.6194 58.0978 43.1483 54.5532C41.6771 51.0085 41.2922 47.1082 42.0422 43.3452C42.7922 39.5823 44.6434 36.1258 47.3618 33.4129C50.0802 30.6999 53.5436 28.8524 57.3141 28.1039C61.0846 27.3554 64.9928 27.7396 68.5445 29.2078C72.0963 30.676 75.132 33.1624 77.2678 36.3524C79.4036 39.5425 80.5436 43.293 80.5436 47.1297C80.5357 52.272 78.4853 57.2016 74.8418 60.8378C71.1983 64.474 66.2589 66.5203 61.1061 66.5282Z" fill="url(#paint1_linear_26_27)"/>
      <path d="M121.598 88.0331C121.578 91.6974 118.592 94.6515 114.927 94.6314L107.932 94.5929C100.734 94.5534 99.7754 84.149 106.846 82.7955V82.7955C109.638 82.2608 111.663 79.8257 111.678 76.9825L112.023 14.229C112.036 11.927 110.366 9.96065 108.092 9.60047V9.60047C102.403 8.69927 103.087 0.305565 108.847 0.33722L116.815 0.381008C119.723 0.39699 122.067 2.76737 122.051 5.67539L121.598 88.0331Z" fill="#2B71FD"/>
      <path d="M0.258286 6.6348C0.269081 2.9705 3.24833 0.00875108 6.91263 0.0195461L13.9078 0.0401539C21.1066 0.0613613 22.0912 10.4632 15.0243 11.8348V11.8348C12.2331 12.3766 10.2149 14.8168 10.2065 17.6601L10.0216 80.4142C10.0148 82.7163 11.6899 84.6784 13.9645 85.0328V85.0328C19.6556 85.9194 18.9931 94.3149 13.2333 94.2979L5.26564 94.2744C2.35759 94.2659 0.00709065 91.9015 0.0156578 88.9934L0.258286 6.6348Z" fill="#2B71FD"/>
      <defs>
      <linearGradient id="paint0_linear_26_27" x1="105.404" y1="47.1041" x2="18.594" y2="47.1041" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1E52BA"/>
      <stop offset="1" stopColor="#2B71FD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_26_27" x1="105.404" y1="47.1041" x2="18.594" y2="47.1041" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1E52BA"/>
      <stop offset="1" stopColor="#2B71FD"/>
      </linearGradient>
      </defs>
    </svg>
  </Box>
)

export default VisionPortal
