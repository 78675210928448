/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, BoxProps, Text, ThemeUIStyleObject } from 'theme-ui'
import { CheckmarkFilled, CheckmarkOutline } from '@carbon/icons-react'

export interface ISelectTileProps extends BoxProps {
  sx?: ThemeUIStyleObject
  label?: string
  caption?: string
  icon?: any
  iconWrapperSx?: ThemeUIStyleObject
  onClick?: () => void
  isSelected?: boolean
  className?: string
  disabled?: boolean
}

const rootSx: ThemeUIStyleObject = {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  background: 'surface.default.primary',
  boxShadow:
    '0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  padding: '16px',
  border: `2px solid transparent`,
  transition: 'all 0.2s',
  '&:not(.disabled):hover': {
    background: 'surface.default.hovered',
    '& .select-tile-right': {
      opacity: 1,
    }
  },
  '&.disabled': {
    cursor: 'default'
  },
  '&.selected': {
    boxShadow: 'none',
    background: 'surface.selected.primary',
    borderColor: 'border.interactive',
    '& .select-tile-right': {
      opacity: 1,
      '& svg': {
        color: 'interactive.default.primary'
      }
    }
  },
  '& .select-tile-right': {
    opacity: 0,
    transition: '0.2s opacity',
    '& svg': {
      color: 'text.disabled'
    }
  },
}

const ProductSelectTile: React.FC<ISelectTileProps> = ({
  sx,
  label,
  caption,
  icon,
  iconWrapperSx,
  onClick = () => {},
  isSelected,
  className = '',
  disabled = false,
}) => {
  const Icon = icon.component
  return (
    <Box
      className={`select-tile ${className} ${isSelected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
      sx={{ ...rootSx, ...sx }}
      onClick={onClick}
    >
      <Box
        className="select-tile-left"
        sx={{ display: 'flex', alignItems: 'flex-start' }}
      >
        {icon && (
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '6px',
              marginTop: '1px',
              ...iconWrapperSx,
            }}
          >
            <Icon />
          </Box>
        )}

        <div>
          {label && (
            <Text as="p" variant="heading">
              {label}
            </Text>
          )}
          {caption && (
            <Text as="p" variant="caption" sx={{ mt: '4px' }}>
              {caption}
            </Text>
          )}
        </div>
      </Box>

      <Box className="select-tile-right">
        {isSelected ? <CheckmarkFilled size={24} /> : <CheckmarkOutline size={24} />}
      </Box>
    </Box>
  )
}
  

export default ProductSelectTile
