import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query'
import { IWorkspace } from './types'
import UsersAPI from './UsersAPI'
import USERS_URLS from './usersUrls'
import { useAuthUser } from '@frontegg/react'
import { AxiosResponse } from 'axios'
import notify from '../../utils/notify.utils'
import { exportErrorMessage } from '../../utils/errors.utils'
import { useUserPersistedStore } from '../../store/userPersistedStore'
import { useMemo } from 'react'

type UseUserWorkspaceListType = () => UseQueryResult<IWorkspace[], Error>

export const useUserWorkspaceList: UseUserWorkspaceListType = () => {
  const { id } = useAuthUser()
  return useQuery(
    [USERS_URLS.WORKSPACE_LIST, id],
    () => UsersAPI.getWorkspaceList(id),
    {
      enabled: !!id,
      select: (result) => result.data?.data,
    }
  )
}

export const useActiveWorkspace = () => {
  const activeWorkspace = useUserPersistedStore(
    (state) => state.activeWorkspace
  )
  const activeWorkspaceId = useMemo(
    () => activeWorkspace?.id ?? '',
    [activeWorkspace?.id]
  )
  return {
    activeWorkspace,
    activeWorkspaceId,
  }
}

type UseUserDefaultWorkspaceType = () => UseQueryResult<IWorkspace, Error>

export const useUserDefaultWorkspace: UseUserDefaultWorkspaceType = () => {
  const { id } = useAuthUser()
  return useQuery(
    [USERS_URLS.WORKSPACE_GET_DEFAULT, id],
    () => UsersAPI.getWorkspaceDefault(id),
    {
      enabled: !!id,
      select: (result) => result.data,
    }
  )
}

type UseSetDefaultWorkspaceType = () => UseMutationResult<
  AxiosResponse<IWorkspace>,
  Error,
  string
>

export const useSetDefaultWorkspace: UseSetDefaultWorkspaceType = () => {
  const { id } = useAuthUser()
  const queryClient = useQueryClient()
  return useMutation(
    (workspaceId) => UsersAPI.setWorkspaceDefault(id, workspaceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS_URLS.WORKSPACE_LIST, id])
        notify({
          title: `Your default workspace has been modified.`,
          kind: 'success',
        })
        // queryClient.invalidateQueries([USERS_URLS.WORKSPACE_GET_DEFAULT, id])
      },
      onError: (error) => {
        notify({
          ...exportErrorMessage(error),
          message: `The changes couldn't be saved. Please try again.`,
        })
      },
    }
  )
}

type UseUsersByWorkspaceType = () => UseQueryResult<IWorkspace[], Error>

export const useUsersByWorkspace: UseUsersByWorkspaceType = () => {
  const { activeWorkspaceId } = useActiveWorkspace()
  return useQuery(
    [USERS_URLS.GET_USERS_BY_WORKSPACE, activeWorkspaceId],
    () => UsersAPI.getUsersByWorkspace(activeWorkspaceId),
    {
      enabled: !!activeWorkspaceId,
      select: (result) => result.data?.data,
    }
  )
}
