import { useCallback } from 'react';

const useHelpCrunch = () => {
  const showWidget = useCallback(() => { window.HelpCrunch('showChatWidget') }, []);
  const hideWidget = useCallback(() => { window.HelpCrunch('hideChatWidget') }, []);

  const initHelpCrunch = useCallback((appId, userData) => {
    try {
      if (!window.helpCrunchInitialized) {
        window.HelpCrunch('init', window.helpcrunchSettings.organization, {
          appId,
          user: userData
        })
        window.helpCrunchInitialized = true;
      }
    } catch (error) {
      console.error("HelpCrunch initialization failed: ", error);
    }
  }, []);
  
  return { showWidget, hideWidget, initHelpCrunch };
};

export default useHelpCrunch;