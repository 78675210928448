/** @jsxImportSource theme-ui */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import {
	Book,
	Debug,
	DocumentSigned,
	InformationSquare,
	IntrusionPrevention,
	Logout,
	Security,
	Settings,
	Upgrade,
	UserAvatar,
	UserMultiple,
	VirtualPrivateCloud,
} from '@carbon/icons-react'
import { AdminPortal, useAuthActions, useAuthUser } from '@frontegg/react'
import { Box, Divider, Flex, Grid, Text } from 'theme-ui'
import { AccessControlIcon, Avatar, Loader } from '@boundlessdigital/bng-shared-components'
import { API_KEY_MANAGEMENT, WORKSPACE } from '../../constants/routes.constants'
import Link from '../Link/Link'
import { useUserWorkspaceList } from '../../api/users/userWorkspacesHooks'
import { useUserPersistedStore } from '../../store/userPersistedStore'
import useActionModalStore from '../../store/useActionModalStore'
import OrganizationManagementModal from './components/OrganizationManagementModal'
import notify from '../../utils/notify.utils'
import { ACTION_ITEM_TYPE } from '../../types/general.types'
import Resources from './Resources'
import ResourceExampleSrc from '../../assets/images/resource-example.png'
import { EXTERNAL_CONTACT_URL } from '../../constants/urls.constants'
import BasicMenu from '../Menu/BasicMenu'
import ProductContainer from '../product-container/product-container.component'
import Button from '../Button/Button'
import { productsConfig } from '../../config/products.config'
import { useApiKeys } from '../../api/api-keys/api-keys.hooks'
import { useUserSummary } from '../../api/admin/admins.hooks'
import AppHeader from '../AppHeader/AppHeader'
import useUserPermissions from '../../hooks/user-permissions.hook'

const openFrontEggAdminPortal = (name = '') => {
	if (name) {
		window.location.href = `#/admin-box/${name}`
	}
	AdminPortal.show()
}

const Header = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { profilePictureUrl, email, tenantId } = useAuthUser()
	const { logout, switchTenant } = useAuthActions()
	const [dataRefetching, setDataRefetching] = useState(false)
	const [openModal, closeModal] = useActionModalStore((state) => [state.openModal, state.closeModal])
	const [activeWorkspace, setActiveWorkspace] = useUserPersistedStore((state) => [state.activeWorkspace, state.setActiveWorkspace])
	const activeWorkspaceName = useMemo(() => activeWorkspace?.name ?? 'Workspace', [activeWorkspace?.name])

	const {data: summaryData, isInitialLoading: isSummaryDataInitialLoading } = useUserSummary()
	const {accessibleProducts, permissions} = useUserPermissions({email})

	const { data: workspaceData } = useUserWorkspaceList()
	const { data: apiKeysData, isInitialLoading } = useApiKeys()

	const filteredProducts = useMemo(() => productsConfig.filter(product => accessibleProducts.includes(product.value)), [email]);

	const allKeys = useMemo(() => {
		return [
			...(apiKeysData?.personalKeys ?? []),
			...(apiKeysData?.workspaceKeys ?? []),
			...(apiKeysData?.sharedKeys ?? []),
			];
	}, [apiKeysData]);

	const hasInvalidKeys = useMemo(() => {
		return allKeys.some(key => key.isApiKeyEnabled && !key.isApiKeyValid);
	}, [allKeys]);

	const showWarningBanner = !isInitialLoading && (!allKeys.length || allKeys.every(key => !key.isApiKeyEnabled) || (!isSummaryDataInitialLoading && !summaryData.organizationsCount))

	useEffect(() => {
		if (workspaceData?.length) {
			const { workspaceId: id, workspaceName: name } = (workspaceData as any).find((v) => v.isDefault) || {}
			const activeWorkspaceData = activeWorkspace ? workspaceData.find((v) => v.workspaceId === activeWorkspace.id) : null
			
			if (!activeWorkspace) {
				if (id && name) {
					setActiveWorkspace({ id, name })
					switchTenant({tenantId: id})
				}
			} else {
				if (!activeWorkspaceData) {
					if (id && name) {
						setActiveWorkspace({id, name })
						switchTenant({tenantId: id})
					}
				} else {
					// TODO: When we invite an user in frontegg it change the active tenant frontegg side.
					// We shouldn't change the active tenant in frontegg.
					// This is a workaround until we figrue it out how to prevent switch tenants on invite

					const isActiveWorkspaceOutOfSyncWithFrontegg = tenantId !== activeWorkspace?.id
					isActiveWorkspaceOutOfSyncWithFrontegg && switchTenant({tenantId: activeWorkspace.id})
				}
			}
		}
	}, [workspaceData, activeWorkspace])

	const workspaceList = useMemo(
		() => [
			{
				title: 'WORKSPACE',
				type: ACTION_ITEM_TYPE.SECTION_TITLE,
			},
			{
				title: 'Workspace details',
				icon: <InformationSquare />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal('account')
					},
				},
			},
			{
				title: 'Users',
				icon: <UserMultiple />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal('users')
					},
				},
			},
			{
				title: 'Security',
				icon: <Security />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal('security')
					},
				},
			},
			{
				title: 'Single sign-on',
				icon: <VirtualPrivateCloud />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal('sso')
					},
				},
			},
			{
				title: 'Audit logs',
				icon: <IntrusionPrevention />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal('audits')
					},
				},
			},
			{
				title: 'API key management',
				icon: <DocumentSigned />,
				itemProps: {
					onClick: () => navigate(API_KEY_MANAGEMENT),
				},
			},
		],
		[navigate],
	)

	const onSaveOrganizationManagement = () => {
		closeModal()
		setDataRefetching(true)
		setTimeout(() => {
			setDataRefetching(false)
			if (Math.random() >= 0.5) {
				notify({
					title: `The list of organizations connected to ${headerProductProps.headerProductText} is now updated.`,
					kind: 'success',
				})
			} else {
				notify({
					title: `Something went wrong... We couldn't update the list of organizations connected to ${headerProductProps.headerProductText}. Please try again.`,
					kind: 'error',
				})
			}
		}, 4000)
	}

	const openOrganizationManagement = (product: any) => {
		openModal({
			title: `${product} - Organization Management`,
			body: (
				<OrganizationManagementModal
					onSave={onSaveOrganizationManagement}
					product={product}
				/>
			),
		})
	}

	const accountActions = useMemo(
		() => [
			{
				title: 'ACCOUNT',
				type: ACTION_ITEM_TYPE.SECTION_TITLE,
			},
			{
				title: 'Profile settings',
				icon: <UserAvatar />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal()
					},
				},
			},
			{
				title: 'Privacy and security',
				icon: <Security />,
				itemProps: {
					onClick: () => {
						openFrontEggAdminPortal('privacy')
					},
				},
			},
			{
				title: 'SUPPORT',
				type: ACTION_ITEM_TYPE.SECTION_TITLE,
			},
			{
				title: 'Knowledge base',
				icon: <Book />,
			},
			{
				title: 'Report bug',
				icon: <Debug />,
			},
			{
				title: 'Suggest feature',
				icon: <Upgrade />,
				divider: true,
			},
			{
				title: 'Log out',
				icon: <Logout />,
				itemProps: {
					onClick: () => logout(),
				},
			},
		],
		[logout],
	)

	const headerProductProps = useMemo(() => {
		const result: any = {
			headerProductText: 'Boundless',
			headerProductIcon: <AccessControlIcon />,
		}
		let isProductChosen = false

		filteredProducts.some(({ path, title, icon }) => {
			const Icon = icon.component
			if (path) {
				if (matchPath({
					path: path,
					end: false,
				}, location.pathname)) {
					result.headerProductText = title
					result.headerProductIcon = <Icon/>
					isProductChosen = true
					return true
				}
			}
			return false
		})
		return result
	}, [location.pathname])

	const renderHeaderMenuContent = useCallback(
		(closeMenu: any) => (
			<Flex>
				<Box
					sx={{
						flex: 1,
						minWidth: '345px',
					}}
					paddingY="24px"
					paddingLeft="42px"
					paddingRight="36px"
				>
					<Box>
						<Box>
							<Text variant="subheading">YOUR PRODUCTS</Text>
						</Box>
						<Divider mt="0.5rem" mb={0} />
						<Grid gap="24px" columns="repeat(auto-fill, 300px)">
							{filteredProducts.map(({isDevelopment, isLocked, component, ...product}) => {
								if (isLocked) return null
								return (
									<ProductContainer
										key={product.title}
										{...product}
										onClick={() => {
											navigate(product.path || '#')
											closeMenu()
										}}
									/>
								)
							}
							)}
						</Grid>
					</Box>

					<Box mt="24px">
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								'& > a': {
									fontSize: '12px',
									lineHeight: '16px',
								},
							}}
						>
							<Text variant="subheading">UPGRADE YOUR PLAN</Text>
						</Box>
							<Divider mt="0.5rem" mb={0} />
							<Grid gap="24px" columns="repeat(auto-fill, 300px)">
								{productsConfig.map(({ isLocked, title, component, ...rest }) => {
									if (!isLocked) return
									return (
										<ProductContainer
											key={title}
											{...rest}
											linkItem={
												<Link onClick={closeMenu} to={EXTERNAL_CONTACT_URL}>
													Contact Sales
												</Link>
											}
										/>
									)

								})}
							</Grid>
					</Box>
				</Box>
				<Resources
					title="RESOURCES"
					data={[
						{
							id: 'resource-1',
							title: 'Learning Center',
							description:
								'The go-to resource for quick and easy answers about the Boundless platform.',
							image: ResourceExampleSrc,
							link: 'https://boundlessdigital.crunch.help/en',
						},
						{
							id: 'resource-2',
							title: 'Explore our Meraki Apps',
							link: 'https://www.boundlessdigital.com/partner/cisco-meraki/',
						},
						{
							id: 'resource-3',
							title: 'Make a Wish',
							link: 'https://airtable.com/appEOyMR0ntznssvP/shrdxAN8QeGFz5b6L',
						},
					]}
				/>
			</Flex>
		),
		[navigate],
	)

	const menuItemSx = {
		px: '14px',
		borderRadius: 0,
		border: 0,
		color: '#fff',
		'& svg': {
			fill: '#6d7175',
		},
		'&:hover, &:active': {
			backgroundColor: '#ffffff1a',
			color: '#fff',
			border: 0,
			'& > svg': {
				fill: '#fff'
			}
		}
	}

	const activeItemSx = {
		backgroundColor: 'rgba(255, 255, 255, 0.25)',
		'& > svg': {
			fill: '#fff'
		}
	}

	const headerItems = useMemo(() => {
		// TODO: This will be refactored using Permission-Based Filtering hook
		const showWorkspaceSetting = permissions['API_KEY_MANAGEMENT']?.includes('FULL ACCESS')
		
		const workspaceSettings = showWorkspaceSetting ? {
			key: 'settings',
			item: (
				<BasicMenu
					key="SETTINGS"
					buttonProps={{
						activeClass: 'menu-active',
						kind: 'ghost',
						children: <Settings size={20} />,
					}}
					list={workspaceList}
					direction="bottom"
					align="end"
					offsetY={8}
				/>
			),
		} : {}

		return [
			{
				key: activeWorkspaceName,
				item: (
					<Button
						sx={{
							...menuItemSx,
							...(matchRoutes([{ path: WORKSPACE }], location) ? activeItemSx : {})
						}}
						key={activeWorkspaceName}
						onClick={() => navigate(WORKSPACE)}
						kind="ghost"
					>
						{activeWorkspaceName}
					</Button>
				),
			},
			workspaceSettings,
			{
				key: 'account',
				item: (
					<BasicMenu
						key="ACCOUNT"
						buttonProps={{
							activeClass: 'menu-active',
							kind: 'ghost',
							children: (
								<Avatar src={profilePictureUrl || undefined} size={24} />
							),
						}}
						list={accountActions}
						direction="bottom"
						align="end"
						offsetY={8}
						offsetX={-16}
					/>
				),
			},

		];
	}, [location, activeWorkspaceName, profilePictureUrl, accountActions, workspaceList]);

	return (
		<Box sx={{
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			zIndex: 10,
			
			'& .cds--header': {
				position: 'initial'
			}
		}}>
			{hasInvalidKeys ? (
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '47px', backgroundColor: '#ffd79d'}}>
					<Text>This workspace has invalid API keys, potentially causing data and operational errors. Please check the status, and refresh or replace your API key to continue managing your users. <Link to={API_KEY_MANAGEMENT}>Go to API key management</Link></Text>
				</Box>
			) : null }
			{ !hasInvalidKeys && showWarningBanner ? (
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '47px', backgroundColor: '#ffd79d'}}>
					<Text>This workspace doesn't seem to have any enabled organizations or active API key. Please check the status of the API key. <Link to={API_KEY_MANAGEMENT}>Go to API key management</Link></Text>
				</Box>
			) : null }
			
			<AppHeader
				{...headerProductProps}
				headerItems={headerItems}
				renderHeaderMenuContent={renderHeaderMenuContent}
			/>
			{dataRefetching && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						width: '100%',
						height: '100%',
						zIndex: 10,
						background: 'rgba(255, 255, 255, 0.5)',
					}}
				>
					<Loader show={true} withOverlay={true} />
					<Text
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: 1000,
							marginTop: 90,
						}}
						variant="displayLarge"
					>
						Please bear with us while we refresh your data.
					</Text>
				</Box>
			)}
		</Box>
	)
}

export default Header
