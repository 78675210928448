import { ThemeUIStyleObject } from "theme-ui"

export const TEAM_EMAIL_DOMAINS = [
	'@thirstysprout.com',
	'@boundlessdigital.com',
	'@boundlessdigital.fr'
];

export const adminHeaderData = [
	{
		header: 'Name',
		key: 'fullName',
	},
	{
		header: 'Organizations',
		key: 'organizationsCount',
	},
	// {
	// 	header: 'Trainings',
	// 	key: 'trainingsCount',
	// },
	{
		header: '',
		key: 'action',
	},
]
  
export const adminSubHeaderData = [
	{
		header: 'Organizations',
		key: 'organizationName',
	},
	{
		header: 'Privileges',
		key: 'organizationAccess',
	},
]

export const adminTableWrapperSx: ThemeUIStyleObject = {
	pt: '0',
	'& .cds--data-table th': {
		px: '16px',
		'&.cds--table-column-checkbox': {
			paddingLeft: '16px !important',
		},
	},
	'& .cds--data-table--md td': {
		px: '16px',
		py: '12px',
		lineHeight: '11px',
		'&.cds--table-column-checkbox': {
			paddingLeft: '16px !important',
		},
	},
	'& .sub-table-container': {
		'& tr': {
			background: 'transparent !important',
		},
		'& .cds--data-table--sm td': {
			py: '20px !important',
		},
		'& .cell-privilege': {
			width: '244px',
		},
	},
}
  