import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as CdsLink } from '@carbon/react';
import { LinkProps } from 'carbon-components-react/lib/components/Link/Link';

const Link: React.FC<LinkProps & { to?: string }> = ({ children, to, ...props }) => {
  const isExternal = to?.startsWith('http://') || to?.startsWith('https://');

  const linkProps = {
    ...props,
    className: `cds--link ${props.className || ''}`,
  };

  if (isExternal) {
    return (
      <CdsLink href={to} target="_blank" rel="noopener noreferrer" {...linkProps}>
        {children}
      </CdsLink>
    );
  } else if (to) {
    return (
      <RouterLink to={to} {...linkProps}>
        {children}
      </RouterLink>
    );
  }

  return <CdsLink {...linkProps}>{children}</CdsLink>;
};

export default Link;