import React from 'react'
import { Box, BoxProps, Divider, Flex, Image, Text, ThemeUIStyleObject } from 'theme-ui'
import Link from '../Link'

export interface IResourcesProps extends BoxProps {
  /**
   * The sx prop lets you style elements inline, using values from your theme.
   * See also:
   * https://theme-ui.com/sx-prop/
   */
  sx?: ThemeUIStyleObject
  data?: {
    id: string
    title: string
    description?: string
    image?: string
    link?: string
  }[]
  title?: string
  footerTitle?: string
  className?: string
}

const rootSx: ThemeUIStyleObject = (theme: any) => ({
  width: 485,
  '& > a:not(.router-link-wrapper)': {
    color: `${theme.colors.primary} !important`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    paddingRight: '32px',
  },
})

const Resources: React.FC<IResourcesProps> = ({
  data,
  sx,
  title = 'RESOURCES',
  footerTitle = 'View all resources',
  ...props
}) => (
  <Box
    sx={(theme) => ({ ...rootSx(theme), ...sx })}
    paddingY="24px"
    paddingX="42px"
    bg="surface.neutral.hovered"
    {...props}
  >
    <Text as="p" variant="subheading">
      {title}
    </Text>
    <Divider mt="12px" mb="12px" />
    {data?.map((item, index) =>
      index > 0 ? (
        <React.Fragment key={item.id}>
          <Link to={item.link}><Text variant="body">{item.title}</Text></Link>
          <Divider mt="12px" mb="12px" />
        </React.Fragment>
      ) : (
        <React.Fragment key={item.id}>
          <Link to={item.link}>
            <Flex sx={{ py: '12px', display: 'flex' }}>
              <Box mr="16px" sx={{ flexShrink: 0 }}>
                {!!item.image && <Image width="120px" src={item.image} />}
              </Box>
              <Box sx={{
                whiteSpace: 'normal'
              }}>
                <Text as="p" variant="heading" mb="4px">
                  {item.title}
                </Text>
                <Text as="p" variant="caption">
                  {item.description}
                </Text>
              </Box>
            </Flex>
          </Link>
          <Divider mt="12px" mb="12px" />
        </React.Fragment>
      )
    )}
    {/* <Link to="#">{footerTitle}</Link> */}
  </Box>
)

export default Resources
