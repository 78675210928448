import React from 'react'
import { create } from 'zustand'

type ActionModalPropsType = {
  title?: string | React.ReactElement
  body?: string | React.ReactElement
  modalProps?: any
}

interface IActionModalStore {
  open: boolean
  props: ActionModalPropsType | null
  closeModal: () => void
  openModal: (props: ActionModalPropsType) => void
}

const useActionModalStore = create<IActionModalStore>((set) => ({
  open: false,
  props: null,
  closeModal: () => {
    set({ open: false })
    setTimeout(() => {
      set({ props: null })
    }, 300)
  },
  openModal: (props: ActionModalPropsType) => {
    set({ props })
    set({ open: true })
  },
}))

export default useActionModalStore
