import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { IUser } from './types'
import UsersAPI from './UsersAPI'
import USERS_URLS from './usersUrls'
import { useAuthUser } from '@frontegg/react'

type UseUserByIdType = (id: string) => UseQueryResult<IUser, Error>

export const useUserById: UseUserByIdType = (id: string) => {
  return useQuery(
    [USERS_URLS.GET_USER_BY_ID, id],
    () => UsersAPI.getUserById(id),
    {
      enabled: !!id,
      retry: 5,
      retryDelay: 2000, // 2 seconds
      staleTime: 10 * 60 * 1000, // (10 minutes)
      cacheTime: 30 * 60 * 1000, // (30 minutes)
      select: (result) => result.data,
    }
  )
}

type UseUserMeType = () => UseQueryResult<IUser, Error>

export const useUserMe: UseUserMeType = () => {
  const { id } = useAuthUser()
  return useUserById(id)
}

export const useUpdateUserCache = () => {
  const queryClient = useQueryClient()

  const update = (id, data: Partial<IUser>) => {
    queryClient.setQueryData([USERS_URLS.GET_USER_BY_ID, id], (prev: any) => ({
      ...prev,
      data: {
        ...prev?.data,
        ...data,
        onboarding: {
          ...prev.data.onboarding,
          ...data.onboarding,
        },
      },
    }))
  }
  return { update }
}
