import { OverflowMenuVertical, Edit, TrashCan } from "@carbon/icons-react";
import { OrganizationAccessEnum } from "../../../types/general.types";
import { useOrganizationsByAdminId } from "../../../api/admin/admins.hooks";
import { useCallback, useEffect, useState } from "react";
import { Permission, TransformedPermission } from "../../../types/admin.types";
import { PRIVILEGES } from "../../../constants/privileges.constants";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import Button from "../../../components/Button/Button";
import BasicMenu from "../../../components/Menu/BasicMenu";

export const useAdminOrganizationData = () => {
  const [adminId, setAdminId] = useState("");
  const [isFetchingEnabled, setIsFetchingEnabled] = useState(false);

  const { data, isLoading, isError } = useOrganizationsByAdminId(adminId, isFetchingEnabled && adminId !== "");

  const fetchOrganizations = useCallback((id) => {
    setAdminId(id);
    setIsFetchingEnabled(true);
  }, []);

  useEffect(() => {
    if ((isError) && isFetchingEnabled) {
      setIsFetchingEnabled(false);
      setAdminId("");
    }
  }, [data, isError, isFetchingEnabled]);

  return {
    data,
    isLoading,
    isError,
    fetchOrganizations,
  };
};



export const parseAdminToUpdate = ({ id, adminName, organizations }) => ({
  adminId: id,
  adminName,
  organizations: transformOrganizationAccess(organizations)
});

const transformOrganizationAccess = (organizations) => {
  return organizations.map(({organizationId, organizationAccess, detailedPermissions, tags, networks }) => {
    const hasFullAccess = organizationAccess === PRIVILEGES.FULL
    
    return {
      organizationId,
      organizationAccess: OrganizationAccessEnum[organizationAccess],
      ...(detailedPermissions && !hasFullAccess ? transformPermissions(detailedPermissions) : {}),
    }
  });
}

export const transformPermissions = (permissions: Permission[] | undefined): TransformedPermission | {} => {
  if (!permissions || !Array.isArray(permissions)) return {}
  
  const flatPermissions = permissions.flatMap(({ tags, privilege }) =>
    tags?.map((tag) => ({
      ...tag,
      access: privilege,
    })) || []
  );

  const transformedTags = flatPermissions
    .filter((perm: any) => perm?.type === 'tag')
    .map((perm) => ({
      tagName: perm?.value,
      tagAccess: OrganizationAccessEnum[perm?.access || ''],
    }));

  const transformedNetworks = flatPermissions
    .filter((perm: any) => perm?.type === 'network')
    .map((perm) => ({
      networkName: perm?.label,
      networkAccess: OrganizationAccessEnum[perm?.access || ''],
      networkId: perm?.value,
    }));


  return {
    ...(transformedTags?.length > 0 && { tags: transformedTags }),
    ...(transformedNetworks?.length > 0 && { networks: transformedNetworks }),
  };
}

export const renderActionCell = (record, selectedRows, onAssignOrganizations, onEditAdmin, onRemoveAdmin) => {
  if (selectedRows.length > 0) return null;

  return (
    <ButtonGroup>
      <Button
        kind="secondary"
        size="md"
        onClick={() => onAssignOrganizations(record.id)}
      >
        Assign organizations
      </Button>

      {/* <BasicMenu
        buttonProps={{
          activeClass: 'hovered',
          kind: 'ghost',
          size: 'md',
          children: <OverflowMenuVertical />,
        }}
        portal
        direction="bottom"
        align="end"
        offsetY={8}
        list={[
          {
            title: 'Edit user',
            icon: <Edit />,
            itemProps: {
              onClick: () => onEditAdmin(record.id),
            },
          },
          {
            title: 'Remove user',
            icon: <TrashCan />,
            color: 'text.critical',
            itemProps: {
              onClick: () => onRemoveAdmin(record.id),
            },
          },
        ]}
      /> */}
    </ButtonGroup>
  );
};
