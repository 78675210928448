/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui'
import { components } from 'react-select'
import { Checkbox } from '@carbon/react'
import { useOptionStyles } from './Option'
import theme from '../../config/theme'

const OptionWithCheckbox = ({
  isSelected,
  children,
  label,
  value,
  selectOption,
  isFocused,
  innerProps,
  ...rest
}: any) => {
  const props = useOptionStyles(isFocused, isSelected)

  return (
    <components.Option
      {...rest}
      selectOption={selectOption}
      isSelected={isSelected}
      innerProps={{ ...innerProps, ...props }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{
          width: '16px',
          height: '16px',
          border: '1px solid grey',
          display: 'inline-block',
          position: 'relative',
          backgroundColor: isSelected ? '#0076CD' : 'transparent',
          borderRadius: '2px',
          '&:after': {
            content: '""',
            position: 'absolute',
            display: isSelected ? 'block' : 'none',
            left: '5px',
            top: '1px',
            width: '5px',
            height: '10px',
            border: 'solid white',
            borderWidth: '0 2px 2px 0',
            transform: 'rotate(45deg)',
            backgroundColor: 'transparent'
          }
        }}>
        </Box>
        <Box sx={{ flex: 1, pl: '10px' }}>{children}</Box>
      </Box>
    </components.Option>
  )
}

export default OptionWithCheckbox
