import { ReactNode } from 'react';
import { Box, Container, Text } from 'theme-ui'
import { Notification } from '@boundlessdigital/bng-shared-components'
import ButtonGroup from '../../../components/Button/ButtonGroup';
import Button from '../../../components/Button/Button';
import useActionModalStore from '../../../store/useActionModalStore';
import { getErrorMessage } from '../../../utils/errors.utils';

interface ErrorModalProps {
  error: any;
  body: ReactNode;
}

const ErrorModal = ({ error, body }: ErrorModalProps) => {
  const closeModal = useActionModalStore(({closeModal}) => closeModal)

  const message = getErrorMessage(error)

  return (
    <Container>
      <Text as="p" variant="body" mb="24px">We have received the following error from Meraki when trying to verify your API key:</Text>
      <Notification
        title={message}
        kind="error"
        hideCloseButton
      />
      <Box sx={{ mt: '24px'}}>
        <Text variant="body">{body}</Text>
      </Box>
      <ButtonGroup mt="24px">
        <Button onClick={closeModal} size="md">
          Got it
        </Button>
      </ButtonGroup>
    </Container>
  )
}

export default ErrorModal
