import { PRIVILEGES, backendPrivilegesMap } from '../../constants/privileges.constants';
import { formatRelativeTime } from '../../utils/date/date.utils'
import {
    Admin,
    AdminStatus,
    Organization,
    TransformedAdmin,
    TransformedOrganization,
} from './admins.types'
import AdminsAPI from './admins.api'
import { groupTagsAndNetworksByPrivilege } from '../../pages/user-management/user-overview/user-overview.utils';
import { ResponseStatusEnum } from '../api.utils';
import notify from '../../utils/notify.utils';
import MultiErrorModal from '../../pages/profile-setup/components/multi-error-modal.component';
import { toast } from 'react-toastify';

/**
 * Data Transformers for Administrator and Organization
 *
 * Purpose:
 * - Facilitate integration with backend data, currently differing in structure from the frontend's mock data.
 * - Serve as an interim solution to align the frontend with backend data formats during development.
 *
 * Usage Context:
 * - Implemented as the frontend transitions from using local mock data to real backend data.
 * - Ensures seamless frontend functionality during backend integration by transforming backend data to expected frontend format.
 *
 * Future Considerations:
 * - Post-integration, a decision will be made whether to:
 *   a) Update frontend to directly align with backend data structures,
 *   b) Modify backend to match frontend expectations, or
 *   c) Retain transformers for data compatibility.
 * - This decision will be based on the alignment of frontend and backend structures post-integration.
 * - Essential to review these transformers once backend integration is complete to determine their necessity.
 */

export const transformAdministratorData = (administrator: Admin): TransformedAdmin => {
  const {
    accountStatus,
    adminId,
    adminName,
    adminEmail,
    organizations,
    organizationsCount,
    trainingsCount,
    trainings,
    ...rest
  } = administrator

  const lastActive = organizations?.length && organizations
    .filter(org => org.lastActive)
    .reduce((latest: Date | null, org) => {
      const orgDate = new Date(org.lastActive as Date);
      return (!latest || orgDate > latest) ? orgDate : latest;
    }, null);

  return {
    ...rest,
    disabled: accountStatus === AdminStatus.DISABLED,
    id: adminId || rest.id,
    fullName: adminName,
    adminName: adminName,
    email: adminEmail,
    adminEmail: adminEmail,
    status: accountStatus || 'unverified',
    organizationsCount: organizations?.length || organizationsCount || 0,
    trainingsCount: trainingsCount || trainings?.length || 0,
    ...(trainings && { trainings }),
    ...(organizations && { organizations: organizations.map(transformOrganizationData) }),
    ...(lastActive && { lastActive: formatRelativeTime(lastActive) })
  } as TransformedAdmin;
}


export const transformAdministratorList = (admins: Admin[]): TransformedAdmin[] => admins.map(transformAdministratorData);

export const transformOrganizationData = (organization: Organization): TransformedOrganization => {
  const { accountStatus, hasDetailedPermissions, organizationId, organizationName, organizationAccess } = organization
  
  return {
    ...organization,
    id: organizationId,
    name: organizationName,
    privilege: backendPrivilegesMap[organizationAccess],
    organizationAccess: backendPrivilegesMap[organizationAccess],
    status: accountStatus,
    detailedPermissions: hasDetailedPermissions ? [] : undefined,
  }
}

export const fetchAdminWithPermissions = async (activeWorkspaceId, id) => {
  const adminData = await AdminsAPI.getAdminById(activeWorkspaceId, id).then(transformAdministratorData);
  const organizationsWithPermissions = adminData.organizations?.filter(org => org.hasDetailedPermissions || org.organizationAccess === PRIVILEGES.NONE) || [];

  const permissionsData = await Promise.all(organizationsWithPermissions.map(async org => {
    const permissions = await AdminsAPI.getAdminOrganizationDetailedPermissions(activeWorkspaceId, id, org.organizationId);
    return { orgId: org.organizationId, permissions };
  }));

  const organizationsWithMergedPermissions = (adminData.organizations || []).map(org => {
    const permissionsEntry = permissionsData.find(entry => entry.orgId === org.organizationId);
    return {
      ...org,
      detailedPermissions: permissionsEntry ? groupTagsAndNetworksByPrivilege(permissionsEntry.permissions) : null,
    };
  });

  return { ...adminData, organizations: organizationsWithMergedPermissions };
};

function handleResponseNotification(
  status: ResponseStatusEnum,
  errors: any[],
  openModal: any,
  messages: {
    successMessage: string,
    partialFailureMessage: string,
    failureMessage: string,
  }
) {
  switch (status) {
    case ResponseStatusEnum.SUCCESS:
      notify({ title: messages.successMessage, kind: 'success' });
      break;
    case ResponseStatusEnum.FAILED:
      const failedToastId = notify({ 
        title: messages.failureMessage,
        kind: 'error',
        actionProps: {
          label: 'See error messages',
          onClick: () => {
            toast.dismiss(failedToastId)
            openModal({
              title: 'Error messages',
              body: <MultiErrorModal errors={errors} />
            })
          },
        },
        preventClose: true
      });
      break;
    case ResponseStatusEnum.PARTIAL_FAILURE:
      const partialFailedToastId = notify({ 
        title: messages.partialFailureMessage,
        kind: 'warning',
        actionProps: {
          label: 'See error messages',
          onClick: () => {
            toast.dismiss(partialFailedToastId)
            openModal({
              title: 'Error messages',
              body: <MultiErrorModal errors={errors} />
            })
          },
        },
        preventClose: true
      });
      break;
  }
}

export const handleCreateApiResponse = (responses, users, openModal): void => {
  responses.forEach(({ data, errors, status }) => {
    const user = users.find(({ adminEmail }) => adminEmail === data.adminEmail)

    handleResponseNotification(status, errors, openModal, {
      successMessage: `User ${user?.adminName} was created successfully`,
      partialFailureMessage: `There were some error while creating ${user?.adminName}`,
      failureMessage: `Failed to create user ${user?.adminName || ''}. Please try again.`,
    });
  });
};

export const handleDeleteApiResponse = (responses, users, openModal): void => {
  responses.forEach(({ data, errors, status }) => {
    const { record } = users.find(({ id }) => id === data.adminId)

    handleResponseNotification(status, errors, openModal, {
      successMessage: `User ${record?.adminName} was removed successfully.`,
      partialFailureMessage: `There were some error while removing ${record?.adminName}`,
      failureMessage: `Failed to remove user ${record?.adminName || ''}. Please try again.`,
    });
  });
};

export const handleAdminUpdateApiResponse = (responses, users, openModal): void => {
  responses.forEach(({ data, errors, status }) => {
    const user = users.find(({ id }) => id === data.adminId)

    handleResponseNotification(status, errors, openModal, {
      successMessage: `User ${user?.record?.adminName}'s permissions were updated successfully`,
      partialFailureMessage: `There were some error while updating ${user?.record?.adminName}`,
      failureMessage: `Failed to update permissions for ${user?.record?.adminName || ''}. Please try again.`,
    });
  });
};