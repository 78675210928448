/** @jsxImportSource theme-ui */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Loader } from '@boundlessdigital/bng-shared-components'
import ProductSelectTile from '../components/product-select-tile.component'
import { Loading, TableToolbarSearch, Toggle } from '@carbon/react'
import { WizardPage } from '../profile-setup.constants'
import { useAuthUser } from '@frontegg/react'
import { useUpdateUserCache } from '../../../api/users/usersHooks'
import { useSetOnboardingOrganizations } from '../../../api/users/onboardingHooks'
import { useSelectedProduct } from '../profile-setup.hooks'
import { useApiKey, useApiKeys, useUpdateApiKeyOrganizations } from '../../../api/api-keys/api-keys.hooks'
import Tile from '../../../components/Tile/Tile'
import ButtonGroup from '../../../components/Button/ButtonGroup'
import DataTable from '../../../components/DataTable/DataTable'
import Button from '../../../components/Button/Button'
import { ErrorFilled, WarningFilled } from '@carbon/icons-react'
import useActionModalStore from '../../../store/useActionModalStore'
import ErrorModal from '../components/error-modal.component'
import { getErrorBody } from '../profile-setup.utils'
import theme from '../../../config/theme'
import { basicTooltipProps } from '../../../utils/general.utils'
import NoResourcesBox from '../../tag-management/components/NoResourcesBox'

const headerData = [
  {
    header: 'Non-selected organization',
    key: 'name',
  },
  {
    header: 'Error',
    key: 'error',
  },
  {
    header: '',
    key: 'isSelected',
  },
]

const ConnectStep = ({ setPage }) => {
  const openModal = useActionModalStore((state) => state.openModal)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedOrganizations, setSelectedOrganizations] = useState<Record<string, boolean | undefined>>({})
  const [errors, setErrors] = useState<Record<string, any>>({})
  const [loading, setLoading] = useState<Record<string, any>>({})
  const [collapsedGroups, setCollapsedGroups] = useState<Record<string, boolean | undefined>>({})
  const [searchTerm, setSearchTerm] = useState('');

  const { id } = useAuthUser()
  const { update: updateUserCache } = useUpdateUserCache()
  const setOnboardingOrganizationsMutation = useSetOnboardingOrganizations(id)
  const product = useSelectedProduct()
  const { data: apiKeysData, isFetching } = useApiKeys(true)
 
  const apiKeyId = useMemo(
    () => apiKeysData?.personalKeys?.[0]?.apiKeyId,
    [apiKeysData]
  )
  const isAnyLoading = useMemo(
    () =>  Object.values(loading).filter(Boolean).length,
    [loading]
  )

  const { data: apiKey, isLoading: isApiKeyLoading } = useApiKey(apiKeyId)
  const updateApiKeyOrganizations = useUpdateApiKeyOrganizations(apiKeyId ?? '')

  const onToggleActive = useCallback((id) => async (checked) => {
    setErrors((prev) => ({ ...prev, [id]: null }))
    setLoading((prev) => ({ ...prev, [id]: true }))

    try {
      await updateApiKeyOrganizations.mutateAsync({organizationId: id, disable: !checked})

      setSelectedOrganizations((prev) => ({ ...prev, [id]: checked }))
    } catch(error: any) {
      setErrors((prev) => ({ ...prev, [id]: error.response.data.errors }))
    } finally {
      setLoading((prev) => ({ ...prev, [id]: false }))
    }
  }, [])

  useEffect(() => {
    if (apiKey?.organizations.length) {
      apiKey.organizations.forEach((organization) => {
        if (organization.organizationEnabledStatus === "FAILED_TO_ENABLE") {
          setErrors((prev) => ({
            ...prev,
            [organization.organizationId]: organization.organizationEnabledErrors,
          }))
        }
        setSelectedOrganizations((prev) => ({
          ...prev,
          [organization.organizationId]: organization.organizationEnabledStatus === "ENABLED",
        }))
      })
    }
  }, [apiKey?.organizations])
  

  const tableData = useMemo(
    () =>
      apiKey?.organizations
        ? apiKey.organizations.map((organization) => ({
          id: organization.organizationId,
          record: {
            id: organization.organizationId,
            name: organization.organizationName,
            isSelected: !!selectedOrganizations[organization.organizationId],
            error: !!errors[organization.organizationId],
            props: (cell) => {
              if (cell.info.header === 'isSelected') {
                return {
                  className: 'action-cell',
                }
              }
              return {}
            },
          },
        }))
        .filter(({ record }) => {
          if (!searchTerm.trim()) {
            return true;
          }

          const nameMatch = record.name.toLowerCase().includes(searchTerm.toLowerCase());
          
          return nameMatch
        })
        : [],
    [apiKey?.organizations, selectedOrganizations, errors, searchTerm]
  )

  const selectedAmount = Object.keys(selectedOrganizations).filter(
    (key) => selectedOrganizations[key]
  ).length

  const openErrorModal = (error) => {
    openModal({
      title: error.errorCode === 'E1000' ? 'Whitelist IP address' : 'Error message',
      body: <ErrorModal error={error} body={getErrorBody(error)} />
    })
  }

  const renderCell = useCallback(
    (cell, { record }) => {
      const error = errors?.[record.id]?.[0]

      if (cell.info.header === 'isSelected') {
        return (
          <Box
            sx={{
              '& .cds--toggle__text': {
                color: error?.errorCode === 'E1000' ? (theme?.colors?.text as any).critical : 'initial'
              }
            }}
          >
            <Toggle
              id={record.id}
              labelA="Enable"
              labelB="Disable"
              size="sm"
              toggled={cell.value}
              onToggle={onToggleActive(record.id)}
              disabled={loading[record.id]}
            />
          </Box>
        )
      }
      if (cell.info.header === 'error') {
        if (loading[record.id]) {
          return (
            <Flex sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Loading small withOverlay={false} />
            </Flex>
          )
        }
        if (cell.value && !record.isSelected) {
          const tooltipMessge = error.errorCode === 'E1000' ? 'We could not enable the organization as it has IP restrictions in place.' : 'This organization cannot be enabled at this time.'
          
          return (
            <Flex sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                kind="ghost"
                size="sm"
                onClick={() => openErrorModal(error)}
                {...basicTooltipProps(tooltipMessge)}
              >
                {error.errorCode === 'E1000' ? (
                  <WarningFilled style={{ marginRight: '8px', fill: (theme?.colors?.text as any).critical }} />
                ): (
                  <ErrorFilled style={{ marginRight: '8px', fill: '#8c9196' }} />
                )}
                <Text variant="footnote">{error.errorCode === 'E1000' ? 'Resolve this issue': 'See error message'}</Text>
              </Button>
            </Flex>
          )
        }
        return <Box sx={{ minWidth: '170px'}}></Box>
      }
      return <Text variant="body" sx={{ whiteSpace: 'nowrap' }}>{cell.value}</Text>
    },
    [onToggleActive, errors, loading]
  )

  const onSubmit = async () => {
    setIsSubmitting(true)
    if (selectedAmount > 0) {
      const organizationKeys = Object.keys(selectedOrganizations).filter(
        (key) => selectedOrganizations[key]
      )

      const organizations = organizationKeys.map((key) => ({
        name:
          apiKey?.organizations.find((item) => item.organizationId === key)
            ?.organizationName ?? '',
      }))
      const result = await setOnboardingOrganizationsMutation.mutateAsync({
        organizations,
      })
      if (result.data) {
        updateUserCache(id, { onboarding: result.data })
      }
      setIsSubmitting(false)
      setPage(WizardPage.ProcessingData)
    }
  }

  const handleEnableAll = useCallback(() => {
    const orgsToEnable = tableData.filter(org => !selectedOrganizations[org.id]).map(org => org.id);

    handleBatchUpdate(orgsToEnable)
  }, [selectedOrganizations, tableData]);

  const handleDisableAll = useCallback(() => {
    const orgsToDisable = tableData.filter(org => selectedOrganizations[org.id]).map(org => org.id);

    handleBatchUpdate(orgsToDisable, true)
  }, [selectedOrganizations, tableData]);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleBatchUpdate = useCallback(async (orgs, disable = false) => {
    orgs.forEach((id, index) => {
      const isLastItem = index === orgs.length - 1;
      
      setLoading(prev => ({...prev, [id]: true}));
  
      updateApiKeyOrganizations.mutateAsync({
        organizationId: id,
        disable,
        shouldInvalidate: isLastItem
      })
        .then(() => {
          setSelectedOrganizations(prevSelected => ({...prevSelected, [id]: !disable}));
        })
        .catch(error => {
          setErrors(prevErrors => ({...prevErrors, [id]: error.response.data.errors}));
        })
        .finally(() => {
          setLoading(prev => ({...prev, [id]: false}));
        });
    });
  }, [handleEnableAll, handleDisableAll, selectedOrganizations]);

  return (
    <>
      <ProductSelectTile
        key={product.title}
        icon={product.icon}
        label={product.title}
        caption={product.description}
        sx={{ mt: '16px' }}
        disabled
      />
      <Tile
        wrapperSx={{
          mt: '16px',
          position: 'relative',
          '& .cds--toggle__switch': { marginRight: '8px' },
          '& td.action-cell': {
            width: '140px',
            '& > .cds--toggle': {
              marginRight: '16px',
            },
          },
        }}
        title="Available organizations"
        footer={
          <ButtonGroup>
            <Button
              size="md"
              kind="primary"
              onClick={onSubmit}
              disabled={selectedAmount === 0 || isSubmitting}
            >
              <Flex sx={{ minWidth: '80px', alignItems: 'center', justifyContent: 'center' }}>
                {isSubmitting ? <Loading small withOverlay={false} /> : 'Connect'}
              </Flex>
            </Button>
          </ButtonGroup>
        }
      >
        <Text as="p" variant="body" style={{ marginBottom: 12 }}>
          Select one or more organizations to connect to {product?.title}
        </Text>

       
        <TableToolbarSearch
          size="md"
          onChange={handleSearchChange}
          placeholder="Search..."
          value={searchTerm}
          persistent
        />

        <Text
          as="p"
          sx={{ my: '12px' }}
          variant="caption"
        >
          Enabled organizations will appear at the top of the list.
        </Text>

        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              backgroundColor: '#0078d4',
              height: '32px',
              color: 'white',
              pl: '16px',
              borderRadius: '4px 4px 0 0'
            }}
          >
            <Text
              as="p"
              sx={{ my: '12px' }}
              variant="body"
            >
              { searchTerm ? `${tableData.length} items selected` : `${selectedAmount ? selectedAmount + '/' : ''} ${tableData.length} organizations`}
            </Text>
            <Box sx={{
              '& .cds--btn': {
                color: 'white',
                borderRadius: '0',
                '&:hover': {
                  color: '#0078d4'
                },
                '&:disabled': {
                  color: '#8c9196'
                }
              }
            }}>
              <Button
                size="sm"
                kind="ghost"
                onClick={handleEnableAll}
                disabled={selectedAmount === tableData.length || !!isAnyLoading}
              >
                Enable all
              </Button>
              <Button
                size="sm"
                kind="ghost"
                onClick={handleDisableAll}
                disabled={!selectedAmount || !!isAnyLoading}
              >
                Disable all
              </Button>
            </Box>
          </Box>
          { isApiKeyLoading && !tableData.length && (
            <Box sx={{ position: 'relative', widht: '100%', height: '200px'}}>
              <Loader/>
            </Box>
          )}
          { !isApiKeyLoading && (!!tableData.length || searchTerm) && (
            <Box sx={{
              maxHeight: '280px',
              overflowY: 'scroll',
              '& .cds--data-table-content': {
                overflowX: 'initial',
                outline: 'none'
              }
            }}>
              <DataTable
                headerData={headerData}
                data={tableData}
                renderCell={renderCell}
                groupProps={{
                  isDropdown: true,
                  onHeaderClick: (key) => {
                    setCollapsedGroups((prev) => ({
                      ...prev,
                      [key]: !prev[key],
                    }))
                  },
                  groupOptions: [
                    {
                      condition: (row) => row.isSelected,
                      key: 'selected',
                      name: 'Enabled organizations',
                      isCollapsed: collapsedGroups['selected'],
                    },
                    {
                      condition: (row) => !row.isSelected,
                      isCollapsed: collapsedGroups['non-selected'],
                      key: 'non-selected',
                      name: 'Disabled organizations',
                    },
                  ],
                }}
                noDataComponent={!isApiKeyLoading && searchTerm && (
                  <NoResourcesBox onReset={ () => setSearchTerm('') } buttonText="Reset Search" />
                )}
                noHeader
              />
            </Box>
          )}
        </Box>
        

      </Tile>
    </>
  )
}

export default ConnectStep
