import { isTeamMemberEmail } from '../utils/general.utils';
import { productsConfig } from '../config/products.config';
import { ProductEnum } from '../types/general.types';
import { SAML_USER_DOMAINS, SAML_ADMINS_EMAILS } from '../pages/vision-portal-access-control/vision-portal-access-control.constants';

// TODO: This entire hook is a Workaround until we defined a proper role/permissisons strategy.

// TODO: Replace this by 3 components:
//       - PermissionsGate: Used for conditionally rendering UI elements based on permissions.
//       - ProtectedRoute: Used for protecting routes in your application.
//       - Permission-Based Filtering function: Used for conditionally filtering items in an array based on permissions.

const getUserType = (user) => {
  const isTeamMember = isTeamMemberEmail(user.email);
  const isSAMLUser = SAML_USER_DOMAINS.some(domain => user.email.endsWith(domain))
  const isSAMLAdmin = SAML_ADMINS_EMAILS.includes(user.email)

  if (isTeamMember) {
    return 'developer'
  }

  if (isSAMLAdmin) {
    return 'samlAdmin'
  }

  if (isSAMLUser) {
    return 'samlUser'
  }

  return 'user'  
}

const useUserPermissions = (user) => {
  const fullList = productsConfig.filter(product => !product.isLocked);
  const readyProducts = fullList.filter(product => !product.isDevelopment);
  const generalProducts = readyProducts.filter(product => product.value !== ProductEnum.ESTEE_LAUDER_VISION_PORTAL)
  const samlProduct = readyProducts.filter(product => product.value === ProductEnum.ESTEE_LAUDER_VISION_PORTAL)

  const userType = getUserType(user)

  let accessibleProducts;
  let permissions;

  switch (userType) {
    case 'developer':
      accessibleProducts = fullList;

      permissions = {
        API_KEY_MANAGEMENT: ['FULL ACCESS'],
        ...fullList.reduce((acc, curr) => {
          acc[curr.value as string] = ['FULL ACCESS']
          return acc
        }, {})
      }
      break;

    case 'samlUser':
      accessibleProducts = samlProduct

      permissions = {
        ...samlProduct.reduce((acc, curr) => {
          acc[curr.value as string] = ['FULL ACCESS']
          return acc
        }, {})
      }

      break;

    case 'samlAdmin':
      accessibleProducts = samlProduct;

      permissions = {
        API_KEY_MANAGEMENT: ['FULL ACCESS'],
        ...samlProduct.reduce((acc, curr) => {
          acc[curr.value as string] = ['FULL ACCESS']
          return acc
        }, {})
      }

      break;

    default:
      accessibleProducts = generalProducts

      permissions = {
        API_KEY_MANAGEMENT: ['FULL ACCESS'],
        ...generalProducts.reduce((acc, curr) => {
          acc[curr.value as string] = ['FULL ACCESS']
          return acc
        }, {})
      }

      break;
  }

  return {
    accessibleProducts: accessibleProducts.map(product => product.value),
    userType,
    permissions: {
      WORKSPACE: ['FULL ACCESS'],
      ...permissions
    }
  };
};

export default useUserPermissions;
