import { SAML_ADMINS_EMAILS } from '../pages/vision-portal-access-control/vision-portal-access-control.constants'
import { TEAM_EMAIL_DOMAINS } from '../constants/admin.constants'
import { Region, regionMapping } from '../constants/region.constants'
import { WithId } from '../types/general.types'
import { formatDateTime } from './date/date.utils'

export const basicTooltipProps = (content?: string, props?: any, isHtml = false) => {
  if (content) {
    const tooltipProps: Record<string, string> = {}
    if (props) {
      Object.keys(props).forEach((prop) => {
        tooltipProps[`data-tooltip-${prop}`] = props[prop]
      })
    }
    return {
      'data-tooltip-id': 'basic',
      [`data-tooltip-${isHtml ? 'html' : 'content'}`]: content,
      ...tooltipProps,
    }
  }
  return {}
}

export const createDataTableRecord = <T extends object, P>(
  { id, ...rest }: WithId<T>,
  cellProps: P
): { id: string, record: Omit<T, 'id'> & { props: P } } => ({
  id,
  record: {
    ...rest,
    id,
    props: cellProps
  }
});

export const debounce = (callback: (...args: any[]) => void, wait: number) => {
  let timeoutId: any = null;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const getPlural = (count: number, singular: string, plural: string) => {
  return count === 1 ? singular : plural
}

export function sleep(ms?: number) {
  return new Promise((res) => {
    setTimeout(res, ms)
  })
}

export const validateEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // NOSONAR
  return regex.test(email)
}

export const validateWorkspaceName = (name) => {
  const regex = /^[A-Za-z0-9 ]{1,24}$/;
  return regex.test(name);
};

export const downloadCsv = (name: string, data: any) => {
  if (name && data) {
    const blob = new Blob([data], { type: 'text/csv' })
    const filename = `${name} - ${new Date().toLocaleString()}.csv`
    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    // cleanup
    window.URL.revokeObjectURL(link.href)
  }
}

export const transformRegion = (regionKey: Region): string => regionMapping[regionKey] || regionKey

export function getBuildDate() {
  return formatDateTime(__BUILD_DATE__|| '') || undefined
}

export const capitalize = (string) => {
  return string && string[0].toUpperCase() + string.slice(1);
}

export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const isAllEmpty = (obj: Record<string, []>)=> Object.values(obj).every(arr => Array.isArray(arr) && arr.length === 0);

export const extractId = (idWithPrefix: string | null | undefined): string | null => {
  if (!idWithPrefix) {
    return null;
  }

  const parts = idWithPrefix.split('|');

  if (parts.length < 2) {
    return idWithPrefix;
  }

  return parts[1];
};

export const isTeamMemberEmail = (email) => {
  return email && TEAM_EMAIL_DOMAINS.some(domain => email.endsWith(domain))
}

export const mockDelay = (response, delay = 1000) => {
  return new Promise(resolve => setTimeout(() => {
    resolve({
      status: 200,
      headers: {
        'Content-Type': response.headers ? response.headers['Content-Type'] : 'application/json'
      },
      data: response.data,
      text: () => Promise.resolve(response.data),
      json: () => Promise.resolve(response.data)
    });
  }, delay));
};

