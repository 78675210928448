const BASE_URL = '/operations';

const getPaginationQueryParams = (pagination?: Record<string, string> | undefined): string => {
  return Object.entries(pagination || {}).map((entry) => `${entry[0]}=${entry[1]}`).join('&')
}

const OPERATIONS_URLS = {
  GET_OPERATIONS: (workspaceId: string, pagination?: Record<string, string> | undefined) => `${BASE_URL}?workspaceId=${workspaceId}`,
  UNDO_OPERATION: (operationId: string) => `${BASE_URL}/${operationId}/undo`,
  REDO_OPERATION: (operationId: string) => `${BASE_URL}/${operationId}/redo`,
}

export default OPERATIONS_URLS;
