import cx from 'classnames'
import ChevronRight from '@carbon/icons-react/lib/ChevronRight'
import { TableCell } from '@carbon/react'
import Button from '../Button/Button'

/* This is modified version of carbon TableExpandRow component, to support alignment of the button */

const TableExpandRow = ({
  ariaLabel,
  className: rowClassName,
  children,
  isExpanded,
  onExpand,
  expandIconDescription,
  isSelected,
  expandHeader,
  alignRight,
  customExpandLabel,
  activeOnExpanded,
  ...rest
}: any) => {
  const className = cx(
    {
      [`cds--parent-row`]: true,
      [`cds--expandable-row`]: isExpanded,
      [`cds--expandable-row--active`]: isExpanded && activeOnExpanded,
      [`cds--data-table--selected`]: isSelected,
    },
    rowClassName
  )
  const previousValue = isExpanded ? 'collapsed' : undefined

  return (
    <tr {...rest} className={className} data-parent-row>
      {alignRight ? children : null}
      <TableCell
        className={`cds--table-expand`}
        data-previous-value={previousValue}
        headers={expandHeader}
      >
        {customExpandLabel ? (
          <Button
            aria-label={ariaLabel}
            className={customExpandLabel ? '' : `cds--table-expand__button`}
            onClick={onExpand}
            kind="ghost"
            size="sm"
            style={{ marginRight: 16 }}
          >
            {customExpandLabel}
            <ChevronRight
              className={`cds--table-expand__svg`}
              aria-label={expandIconDescription}
              style={{ marginLeft: '4px' }}
            />
          </Button>
        ) : (
          <button
            className={`cds--table-expand__button`}
            onClick={onExpand}
            title={expandIconDescription}
            aria-label={ariaLabel}
          >
            <ChevronRight
              className={`cds--table-expand__svg`}
              aria-label={expandIconDescription}
            />
          </button>
        )}
      </TableCell>
      {!alignRight ? children : null}
    </tr>
  )
}

export default TableExpandRow
