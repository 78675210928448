const API_KEYS_BASE = '/admins';

const getPaginationQueryParams = (pagination?: Record<string, string|null> | undefined): string => {
  return Object.entries(pagination || {}).map((entry) => `&${entry[0]}=${entry[1]}`).join('')
}

const ADMINS_URLS = {
  BASE: `${API_KEYS_BASE}`,
  DOWNLOAD_ADMINS_AS_CSV: `${API_KEYS_BASE}/export-as-csv`,
  GET_ADMINS: (workspaceId: string, pagination?: Record<string, string | null> | undefined) => `${API_KEYS_BASE}?workspaceId=${workspaceId}${getPaginationQueryParams(pagination)}`,
  GET_ADMIN_BY_ID: (workspaceId: string, adminId: string) => `${API_KEYS_BASE}/${adminId}?workspaceId=${workspaceId}`,
  UPDATE_ADMIN_NAME_AND_TRAININGS: (adminId: string) => `${API_KEYS_BASE}/${adminId}`,
  UPDATE_ADMIN_TRAININGS_STATUS: (adminId: string) => `${API_KEYS_BASE}/${adminId}/trainings/status`,
  GET_ADMIN_ORGANIZATIONS: (workspaceId: string, adminId: string) => `${API_KEYS_BASE}/${adminId}/organizations?workspaceId=${workspaceId}`,
  GET_ADMIN_ORGANIZATION_DETAILED_PERMISSIONS: (workspaceId: string, adminId: string, organizationId: string) => `${API_KEYS_BASE}/${adminId}/organizations/${organizationId}/detailed-permissions?workspaceId=${workspaceId}`,
  RESEND_ORGANIZATION_INVITATION: (adminId: string, organizationId: string) => `${API_KEYS_BASE}/${adminId}/organizations/${organizationId}/resend-invitation`,
  RESEND_ADMIN_INVITATIONS: (adminId: string) => `${API_KEYS_BASE}/${adminId}/resend-invitations`,
  GET_USER_SUMMARY: (workspaceId: string) => `/user-summary?workspaceId=${workspaceId}`,
}

export default ADMINS_URLS;
