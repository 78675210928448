import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
    IOnboarding,
    IOnboardingApiKeyPayload,
    IOnboardingOrganizationsPayload,
    IOnboardingProductsPayload,
    IOnboardingWorkspacePayload,
} from './types'
import UsersAPI from './UsersAPI'
import notify from '../../utils/notify.utils'
import { exportErrorMessage } from '../../utils/errors.utils'
import { AxiosResponse } from 'axios'

type UseSetOnboardingWorkspaceType = (
  userId: string
) => UseMutationResult<
  AxiosResponse<IOnboarding>,
  Error,
  IOnboardingWorkspacePayload
>

export const useSetOnboardingWorkspace: UseSetOnboardingWorkspaceType = (
  userId: string
) => {
  return useMutation(
    (params) => UsersAPI.setOnboardingWorkspaceDetails(userId, params),
    {
      onError: (error) => {
        notify(exportErrorMessage(error))
      },
    }
  )
}

type UseSetOnboardingProductsType = (
  userId: string
) => UseMutationResult<
  AxiosResponse<IOnboarding>,
  Error,
  IOnboardingProductsPayload
>

export const useSetOnboardingProducts: UseSetOnboardingProductsType = (
  userId: string
) => {
  return useMutation(
    (params) => UsersAPI.setOnboardingProducts(userId, params),
    {
      onError: (error) => {
        notify(exportErrorMessage(error))
      },
    }
  )
}

type UseSetOnboardingApiKeyType = (
  userId: string
) => UseMutationResult<
  AxiosResponse<IOnboarding>,
  Error,
  IOnboardingApiKeyPayload
>

export const useSetOnboardingApiKey: UseSetOnboardingApiKeyType = (
  userId: string
) => {
  return useMutation((params) => UsersAPI.setOnboardingApiKey(userId, params), {
    onError: (error) => {
      notify(exportErrorMessage(error))
    },
  })
}

type UseSetOnboardingOrganizationsType = (
  userId: string
) => UseMutationResult<
  AxiosResponse<IOnboarding>,
  Error,
  IOnboardingOrganizationsPayload
>

export const useSetOnboardingOrganizations: UseSetOnboardingOrganizationsType =
  (userId: string) => {
    return useMutation(
      (params) => UsersAPI.setOnboardingOrganizations(userId, params),
      {
        onError: (error) => {
          notify(exportErrorMessage(error))
        },
      }
    )
  }
