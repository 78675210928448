import { pagesConfig } from './pages.config';
import { productsConfig } from './products.config';

const configToRoute = (config: any[]): any[] => {
  return config.map(({path, component, isDevelopment, children, isLocked, configuration, value}) => {
    if (isLocked) return null

    return {
      path: children ? `${path}` : path,
      component,
      isDevelopment,
      value,
      configuration,
      children: children ? configToRoute(children.map(child => ({...child, value}))) : undefined
    }
  })
}

export const routesConfig = configToRoute([...pagesConfig, ...productsConfig]).filter(Boolean);
