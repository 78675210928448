import APIAbstract from '../api.abstract'
import ADMINS_URLS from './admins.urls'
import { API_USER_MANAGEMENT_BASE_URL } from '../constants'
import { AdminByIdResponse, AdminsResponse } from './admins.types'

class AdminsAPI extends APIAbstract {
  public getAdmins = async (workspaceId: string, pagination?: Record<string, string>): Promise<AdminsResponse> => {
    const response = await this.get(ADMINS_URLS.GET_ADMINS(workspaceId, pagination))
    return response.data
  }

  public getAdminById = async (workspaceId: string, adminId: string): Promise<AdminByIdResponse> => {
    const response = await this.get(ADMINS_URLS.GET_ADMIN_BY_ID(workspaceId, adminId))
    return response.data
  }
  
  public getUserSummary = async (workspaceId): Promise<any> => {
    const response = await this.get(ADMINS_URLS.GET_USER_SUMMARY(workspaceId))
    return response.data
  }

  public getAdminOrganizationDetailedPermissions = async (
    workspaceId: string,
    adminId: string,
    organizationId: string,
  ): Promise<any> => {
    const response = await this.get(ADMINS_URLS.GET_ADMIN_ORGANIZATION_DETAILED_PERMISSIONS(workspaceId, adminId, organizationId))
    return response.data
  }

  public createAdmin = async (payload): Promise<any> => {
    const response = await this.post(ADMINS_URLS.BASE, payload)
    return response.data
  }

  public downloadAdminAsCsv = async (payload): Promise<any> => {
    const response = await this.post(ADMINS_URLS.DOWNLOAD_ADMINS_AS_CSV, payload)
    return response.data
  }

  public updateAdmin = async (payload): Promise<any> => {
    const response = await this.put(ADMINS_URLS.BASE, payload)
    return response
  }

  public updateAdminNameAndTrainings = async ({ updatedAdmin, workspaceId }): Promise<any> => {
    const body = {
      trainingIds: updatedAdmin?.trainings.map(training => training.trainingId || training.value),
      adminName: updatedAdmin.fullName,
      workspaceId: workspaceId,
    }
    const response = await this.put(ADMINS_URLS.UPDATE_ADMIN_NAME_AND_TRAININGS(updatedAdmin.id), body)
    return response.data
  }

  public updateAdminTrainingsStatus = async ({ adminId, trainings, workspaceId }): Promise<any> => {
    const body = {
      trainings,
      workspaceId: workspaceId,
    }
    const response = await this.put(ADMINS_URLS.UPDATE_ADMIN_TRAININGS_STATUS(adminId), body)
    return response.data
  }

  public resendAdminOrganizationInvite = async ({ adminId, organizationId, workspaceId }): Promise<any> => {
    const response = await this.post(ADMINS_URLS.RESEND_ORGANIZATION_INVITATION(adminId, organizationId), { workspaceId })
    return response.data
  }
  public resendAdminInvites = async ({ adminId, workspaceId }): Promise<any> => {
    const response = await this.post(ADMINS_URLS.RESEND_ADMIN_INVITATIONS(adminId), { workspaceId })
    return response.data
  }

  public deleteAdmin = async (payload: { admins: any[], workspaceId: string }): Promise<any> => {
    const response = await this.delete(ADMINS_URLS.BASE, payload)
    return response.data
  }
}

export default new AdminsAPI({ apiEndpoint: API_USER_MANAGEMENT_BASE_URL })
