/** @jsxImportSource theme-ui */
import { useState } from 'react'
import { Loader, SelectField, Notification } from '@boundlessdigital/bng-shared-components'
import ProductSelectTile from '../components/product-select-tile.component'
import { Box, Text } from 'theme-ui'
import { vendorOptions } from '../../api-key-management/api-key-form/constants'
import { useAuthUser } from '@frontegg/react'
import { useSetOnboardingApiKey } from '../../../api/users/onboardingHooks'
import { useUpdateUserCache } from '../../../api/users/usersHooks'
import { WizardPage } from '../profile-setup.constants'
import { useSelectedProduct } from '../profile-setup.hooks'
import { useCreateApiKey } from '../../../api/api-keys/api-keys.hooks'
import { useUserDefaultWorkspace } from '../../../api/users/userWorkspacesHooks'
import ButtonGroup from '../../../components/Button/ButtonGroup'
import Tile from '../../../components/Tile/Tile'
import theme from '../../../config/theme'
import TextField from '../../../components/inputs/TextField'
import Button from '../../../components/Button/Button'
import useActionModalStore from '../../../store/useActionModalStore'
import ErrorModal from '../components/error-modal.component'
import { APIKeyScopeType, Vendors } from '../../../api/enums'
import MultiErrorModal from '../components/multi-error-modal.component'
import { handleMerakiApiKeyErrors } from '../profile-setup.utils'


const ApiKeyStep = ({ setPage }) => {
  const openModal = useActionModalStore((state) => state.openModal)
  const { data, isLoading } = useUserDefaultWorkspace()
  const { id } = useAuthUser()
  const product = useSelectedProduct()
  const { update: updateUserCache } = useUpdateUserCache()
  const createApiKeyMutation = useCreateApiKey()
  const setOnboardingApiKeyMutation = useSetOnboardingApiKey(id)
  const [name, setName] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [error, setError] = useState<Record<string, any> | null>(null)

  const onSubmit = async () => {
    setError(null)
    if (name && apiKey) {
      try {
        await createApiKeyMutation.mutateAsync({
          apiKeyName: name,
          apiKeyValue: `${apiKey}`,
          apiKeyVendor: Vendors.MERAKI,
          workspaceId: data?.workspaceId ?? '',
          workspaceVisibility: APIKeyScopeType.PRIVATE,
        })

        const result = await setOnboardingApiKeyMutation.mutateAsync({
          name,
          key: apiKey,
          vendor: Vendors.MERAKI,
        })
            
        if (result.data) {
          updateUserCache(id, { onboarding: result.data })
        }
        setPage(WizardPage.Connect)

      } catch (error: any) {
        setError(handleMerakiApiKeyErrors(error))
      }
    }
  }

  const openErrorModal = () => {
    const { errors, title = '', body } = error || {}

    if (errors) {
      openModal({
        title,
        body: <MultiErrorModal errors={errors} />
      })
    } else {
      openModal({
        title,
        body: <ErrorModal error={error} body={body} />
      })
    }
  }

  return (
    <>
      <ProductSelectTile
        key={product.title}
        icon={product.icon}
        label={product.title}
        caption={product.description}
        sx={{ mt: '16px' }}
        disabled
      />
      <Tile
        wrapperSx={{ mt: '16px', position: 'relative' }}
        contentSx={{ '& > .select-tile:not(:last-child)': { mb: '12px' } }}
        title="Key details"
        footer={
          <ButtonGroup>
            <Button
              size="md"
              kind="primary"
              onClick={onSubmit}
              disabled={!name || !apiKey}
            >
              Continue
            </Button>
          </ButtonGroup>
        }
      >
        <Loader
          show={
            createApiKeyMutation.isLoading ||
            setOnboardingApiKeyMutation.isLoading ||
            isLoading
          }
          withOverlay
        />
        <TextField
          label="Name your API key"
          placeholder="First Last's API key"
          onChange={(e) => setName(e.target.value)}
          value={name}
          clearable
        />
        <Box sx={{ mt: '12px' }}>
          <TextField
            label="Enter your API key"
            placeholder="5e5b1b9edc1d4db79e56aa6f029d11f7"
            type="password"
            autoComplete="off"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            isError={error}
          />
        </Box>
        <Box sx={{ mt: '12px' }}>
          <SelectField
            label="Choose your vendor"
            options={vendorOptions}
            value={vendorOptions[0]}
            disabled
            sx={{...theme?.text?.body}}
          />
        </Box>
        { error ? (
          <Box sx={{ mt: '24px' }}>
            <Notification
              title="Ooops! We could not verify your key at this time."
              message={<Text color={(theme?.colors?.text as any).secondary}>{error.description}</Text>}
              kind="error"
              actionProps={{
                label: error.linkText,
                onClick: () => openErrorModal(),
              }}
              hideCloseButton
            />
          </Box>
        ) : null }
      </Tile>
    </>
  )
}

export default ApiKeyStep
