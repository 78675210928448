/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, BoxProps, ThemeUIStyleObject, Text } from 'theme-ui'
import { basicTooltipProps } from '../../utils/general.utils'

export interface IIconLabelProps extends BoxProps {
  rootSx?: ThemeUIStyleObject
  label: string | number
  icon?: React.ReactElement | string
  tooltipContent?: string
}

const IconLabel: React.FC<IIconLabelProps> = ({
  rootSx,
  label,
  icon = null,
  tooltipContent,
}) => {
  return (
    <Box
      {...basicTooltipProps(tooltipContent)}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        padding: '4px 8px',
        ...rootSx
      }}
    >
      {icon}
      <Text variant="body" sx={{ ml: '8px'}}>
        {label}
      </Text>
    </Box>
  )
}

export default IconLabel
