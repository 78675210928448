export const BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const API_KEY_BASE_URL = import.meta.env.VITE_API_API_KEY_BASE_URL;
export const API_USER_MANAGEMENT_BASE_URL = import.meta.env.VITE_API_USER_MANAGEMENT_BASE_URL;
export const API_SWITCH_PORT_MANAGEMENT_URL = import.meta.env.VITE_API_SWITCH_PORT_MANAGEMENT_URL;
export const API_ESTEE_LAUDER_VISION_PORTAL_BASE_URL = import.meta.env.VITE_API_ESTEE_LAUDER_VISION_PORTAL_BASE_URL;
export const API_SAML_BASE_URL = import.meta.env.VITE_API_SAML_BASE_URL;
export const API_OPERATION_MANAGEMENT_BASE_URL = import.meta.env.VITE_API_OPERATION_MANAGEMENT_BASE_URL;
export const EA_BASE_URL = import.meta.env.VITE_API_EA_BASE_URL;

export const EA_IHG_USERNAME = import.meta.env.VITE_API_EA_IHG_USERNAME;
export const EA_IHG_PASSWORD = import.meta.env.VITE_API_EA_IHG_PASSWORD;
