/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { ProgressIndicator, ProgressStep } from '@carbon/react'
import WorkspaceStep from '../steps/workspace.step'
import ProductsStep from '../steps/products.step'
import ApiKeyStep from '../steps/api-key.step'
import ConnectStep from '../steps/connect.step'
import ProcessingDataStep from '../steps/processing-data.step'
import { WizardPage } from '../profile-setup.constants'

interface IProfileWizardProps {
  page: WizardPage
  setPage: React.Dispatch<React.SetStateAction<WizardPage>>
}

const profileWizardWrapperSx: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  minWidth: '548px',
  px: ['36px', '36px', '36px', '80px'],
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',
}

const progressWrapper: ThemeUIStyleObject = {
  width: '100%',
  height: '44px',
}

const ProfileWizard: React.FC<IProfileWizardProps> = ({ page, setPage }) => {
  const pageValues = Object.values(WizardPage)

  return (
    <Box sx={profileWizardWrapperSx}>
      <Box sx={{ minHeight: 0, py: '50px' }}>
        <ProgressIndicator sx={progressWrapper} spaceEqually>
          {pageValues.map((value, index) => {
            let props: any = {}
            if (index + 1 === pageValues.length)
              return <React.Fragment key={value} />

            if (value === page) {
              props.current = true
            } else if (index < pageValues.indexOf(page)) {
              props.complete = true
            }
            return <ProgressStep key={value} label={value} {...props} />
          })}
        </ProgressIndicator>

        {page === WizardPage.Workspace && <WorkspaceStep setPage={setPage} />}
        {page === WizardPage.Products && <ProductsStep setPage={setPage} />}
        {page === WizardPage.ApiKey && <ApiKeyStep setPage={setPage} />}
        {page === WizardPage.Connect && <ConnectStep setPage={setPage} />}
        {page === WizardPage.ProcessingData && <ProcessingDataStep />}
      </Box>
    </Box>
  )
}

export default ProfileWizard
