import axios from 'axios'
import { useAuthStore } from '../store/authStore'

const apiClient = axios.create()

apiClient.interceptors.request.use((config) => {
  const accessToken = useAuthStore.getState().accessToken
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

apiClient.interceptors.response.use((response) => {
  return response
}, (error) => {
  return Promise.reject(error)
})

export default apiClient