import { Container } from 'theme-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_MANAGEMENT } from '../../constants/routes.constants'
import theme from '../../config/theme'
import Tile from '../Tile/Tile'
import Button from '../Button/Button'

interface ErrorBoundaryFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export function ErrorBoundaryFallback({error, resetErrorBoundary}: ErrorBoundaryFallbackProps) {
  const [retries, setRetries] = useState(0)
  const navigate = useNavigate()

  const handleReset = () => {
    setRetries(retries + 1)

    if (retries) {
      resetErrorBoundary()
    } else {
      navigate(USER_MANAGEMENT)
    }
  }

  return (
    <Container
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Tile
        wrapperSx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '500px'
        }}
        title={ retries ? 'The error persisted' : 'An error occurred'}
        description={ retries ? 'Click below to navigate away' : 'You may retry. If the issue persists, please navigate away.'}
        titleSx={{
          paddingBottom: 20,
        }}
        descriptionSx={{ color: (theme?.colors?.text as any).secondary, paddingBottom: 20 }}
      >
        <Button onClick={handleReset}>{retries ? 'Navigate away' : 'Try again'}</Button>
      </Tile>
    </Container>
  );
}
