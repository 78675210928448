/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, BoxProps, ThemeUIStyleObject, Text } from 'theme-ui'
import theme from '../../config/theme'
import { ProductIcon } from '../../config/config.types'

export interface IProductContainerProps extends BoxProps {
  /**
   * The component used for the root node. Either a string to use an HTML element or a component.
   */
  as?: React.ElementType
  /**
   * The sx prop lets you style elements inline, using values from your theme.
   * See also:
   * https://theme-ui.com/sx-prop/
   */
  sx?: ThemeUIStyleObject
  className?: string
  /**
   * Product title.
   */
  title: string
  /**
   * Product description.
   */
  description?: string
  /**
   * Product icon.
   */
  icon: ProductIcon
  /**
   * Link item.
   */
  linkItem?: React.ReactElement
}

const rootSx: ThemeUIStyleObject = (theme: any) => {
  return {
    display: 'flex',
    transition: 'background 0.2s',
    p: '12px',
    pt: '24px',
    borderRadius: '4px',
    '& a': {
      ...theme.text.footnote,
      mt: '4px',
    },
    '&.clickable': {
      cursor: 'pointer',
      '&:hover': {
        bg: theme.colors.surface.neutral.hovered ?? 'transparent',
        '& > div': {
          transform: 'translateY(-12px)',
        },
      },
    },
  }

}

const flexSx: ThemeUIStyleObject = {
  display: 'flex',
  transition: 'transform 0.2s',
  '& .svg-icon': {
    mr: '8px',
  },
}

const titleSx: ThemeUIStyleObject = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const descriptionSx: ThemeUIStyleObject = {
  mt: '4px',
  overflow: 'hidden',
}

const ProductContainer = ({
  sx,
  icon,
  onClick,
  linkItem,
  className = '',
  ...rest
}: IProductContainerProps) => {
  const { component: Icon, description, title} = icon
  return (
    <Box
      {...rest}
      sx={{ ...rootSx(theme), ...sx }}
      className={`${className} ${onClick ? 'clickable' : ''}`}
      onClick={onClick}
    >
      <Box sx={flexSx}>
        <Icon />
        <Box>
          <Text as="p" sx={{ ...titleSx, ...(theme.text as any).heading }}>
            {title}
          </Text>
          <Text as="p" sx={{ ...descriptionSx, ...(theme.text as any).caption }}>
            {description}
          </Text>
          {linkItem}
        </Box>
      </Box>
    </Box>
  )
}

export default ProductContainer
