/** @jsxImportSource theme-ui */
import React from 'react'
import {
  Box,
  ThemeUIStyleObject,
  Label,
  Input,
  InputProps,
  Textarea,
  Text,
} from 'theme-ui'
import { ForwardRefProps } from 'carbon-components-react/typings/shared'
import cx from 'classnames'
import { WarningAltFilled, WarningFilled, Close } from '@carbon/icons-react'
import theme from '../../config/theme'
import Button from '../Button/Button'
import { basicTooltipProps } from '../../utils/general.utils'

export interface ITextFieldProps extends Omit<InputProps, 'size'> {
  wrapperSx?: ThemeUIStyleObject
  inputSx?: ThemeUIStyleObject
  label?: string | React.ReactElement
  inputClassName?: string
  size?: 'lg' | 'md' | 'sm' | number
  helperText?: string | React.ReactElement
  icon?: React.ReactElement
  isError?: boolean
  isWarning?: boolean
  clearable?: boolean
  rightLabel?: string
  isTextarea?: boolean
  hasTooltip?: boolean
}

const inputSizes = {
  lg: '48px',
  md: '40px',
  sm: '32px',
}

const inputStyles: (props: {
  size?: 'lg' | 'md' | 'sm' | number
}) => ThemeUIStyleObject = ({ size }) => {
  if (typeof size === 'string' && inputSizes[size]) {
    return {
      height: inputSizes[size],
    };
  }

  else if (typeof size === 'number') {
    return {
      height: `${size}px`,
    };
  }

  return {};
};

const TextField: React.FC<ForwardRefProps<HTMLInputElement, ITextFieldProps>> =
  React.forwardRef(
    (
      {
        wrapperSx,
        inputSx,
        label,
        id,
        size = 'md',
        icon,
        helperText,
        isError,
        isWarning,
        inputClassName,
        rightLabel,
        clearable,
        isTextarea,
        hasTooltip,
        ...props
      },
      ref
    ) => {
      const { colors, text, forms } = theme as any

      const handleClear = (e: any) => {
        const parentBox = e.target.closest('div')
        const inputElem = parentBox.querySelector('input')

        if (inputElem) {
          inputElem.value = ''
          if (props.onChange) {
            const fakeEvent = {
              target: inputElem,
              currentTarget: inputElem,
            }
            props.onChange(fakeEvent as React.ChangeEvent<HTMLInputElement>)
          }
        }
        inputElem.focus()
      }

      const inputClass = cx(inputClassName, {
        [`is-error`]: !!isError,
        [`is-warning`]: !!isWarning,
      })

      const renderWarning = () => {
        const tooltipMessage: string = (helperText || '') as string;

        if (isError) {
          return <WarningFilled {...basicTooltipProps(hasTooltip ? tooltipMessage : '')}/>
        } else if (isWarning) {
          return <WarningAltFilled />
        } else {
          return null
        }
      }

      const getWarningColor = () => {
        if (isError) {
          return colors.text.critical;
        } else if (isWarning) {
          return colors.text.warning;
        } else {
          return colors.text.critical;
        }
      }
      return (
        <Box sx={wrapperSx}>
          {label && <Label htmlFor={id} sx={forms.label}>{label}</Label>}
          <Box
            sx={{
              position: 'relative',
              '& svg:not(.close-btn)': {
                position: 'absolute',
                right: '14px',
                top: '50%',
                transform: 'translateY(-50%)',
                '& [data-icon-path="inner-path"]': { fill: '#000000' },
              },
            }}
          >
            {rightLabel ? (
              <Text
                sx={{ position: 'absolute', right: 0, top: -24, ...text.caption }}
              >
                <Text variant="body">{rightLabel}</Text>
              </Text>
            ) : null}
            {isTextarea ? (
              <Textarea
                id={id}
                sx={{ ...inputSx, ...text.body }}
                ref={ref as any}
                className={inputClass}
                placeholder={props.placeholder}
                rows={3}
              />
            ) : (
              <Input
                autofillBackgroundColor={colors.text.onColor}
                id={id}
                sx={{ ...inputStyles({ size }), ...inputSx, ...forms.input }}
                {...props}
                ref={ref}
                className={inputClass}
              />
            )}
            {icon || renderWarning()}
            {clearable && props?.value && (
              <Button
                kind="ghost"
                size="sm"
                onClick={handleClear}
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <Close className="close-btn" />
              </Button>
            )}
          </Box>
          {!hasTooltip && helperText && (
            <Text
              as="p"
              sx={{ pt: '4px', ...text.footnoe }}
              color={getWarningColor()}
            >
              {helperText}
            </Text>
          )}
        </Box>
      )
    }
  )

export default TextField
