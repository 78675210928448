/** @jsxImportSource theme-ui */
import React, { useCallback, useMemo, useState } from 'react'
import cx from 'classnames'
import {
  DataTable as CdsDataTable,
  Table,
  TableBody,
  TableContainer,
  TableExpandedRow,
  TableExpandHeader,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarSearch,
  TableCell,
  Tag,
  Pagination,
  Loading,
} from '@carbon/react'
import { Box, ThemeUIStyleObject, Text } from 'theme-ui'
import { ChevronDown, Restart } from '@carbon/icons-react'
import Link from '../Link'
import TableBatchActions from './TableBatchActions'
import TableExpandRow from './TableExpandRow'
import { DataTableSize } from 'carbon-components-react/lib/components/DataTable/Table'
import ButtonMenu from '../Button/ButtonMenu'
import ChevronRight from '@carbon/icons-react/lib/ChevronRight'
import { PageChange } from '../../types/general.types'
import theme from '../../config/theme'
import Button from '../Button/Button'
import { Loader } from '@boundlessdigital/bng-shared-components'
import { BasicMenuListType } from '@boundlessdigital/bng-shared-components/dist/components/Menu'
import { ORDER_TYPE } from './useSort'
import NoResourcesBox from '../../pages/tag-management/components/NoResourcesBox'

export interface IDataTableHeader {
  header: string
  key: string
}

export interface IDataTableData {
  id: string
  record: any
  subTableData?: {
    headerData: IDataTableHeader[]
    renderCell: any
    keyField?: string
    useData?: any
    noDataText?: string
    filterData?: (record: any) => boolean
    data?: {
      id: string
      [key: string]: any
    }[]
  }
  expandableComponent?: any
}

export interface IDataTableProps {
  headerData: IDataTableHeader[]
  data: IDataTableData[]
  isDataLoading?: boolean
  renderCell: any
  withSearch?: boolean
  searchableKeys?: string[]
  actionBar?: any
  filterProps?: {
    list: {
      key: string
      content: any
      onClose?: any
    }[]
    onReset?: any
    resetText?: string
    sx?: ThemeUIStyleObject
  }
  batchActions?: {
    label: string
    onClick?: any
    disabled?: boolean
    menuList?: BasicMenuListType[]
    portal?: any
    divider?: boolean
  }[]
  selectable?: boolean
  expandable?: boolean
  noHeader?: boolean
  customHeader?: any
  customHeaderRender?: any
  selectedRows?: any
  setSelectedRows?: any
  expandedRows?: any
  setExpandedRows?: React.Dispatch<React.SetStateAction<any[]>>
  size?: DataTableSize
  className?: string
  transparent?: boolean
  withBorders?: boolean
  sortable?: boolean
  noDataComponent?: any
  expandableAlignedLeft?: boolean
  customBatchActionProps?: any
  groupProps?: {
    type?: 'header' | 'body' | string
    isDropdown?: boolean
    onHeaderClick?: (key: string) => void
    groupOptions: {
      condition: (row: any) => boolean
      key: string
      name?: string
      isCollapsed?: boolean
    }[]
  }
  customTopLabel?: any
  pagination?: {
    page: number
    pageSize: number
    pageSizes?: number[]
    totalItems?: number
    onChange: (data: PageChange) => void
  }
  sortProps?: { key?: string; order?: ORDER_TYPE }
  onSort?: (key: string, order?: ORDER_TYPE) => void
  customExpandLabel?: string
  activeOnExpanded?: boolean
  noInheritStyles?: boolean
}

const getNextSortOrder = (currentOrder?: ORDER_TYPE & 'NONE') => {
  if (currentOrder === 'NONE') return ORDER_TYPE.ASC
  if (currentOrder === ORDER_TYPE.ASC) return ORDER_TYPE.DESC
  return undefined
}

const DataTable: React.FC<IDataTableProps> = ({
  headerData,
  data,
  isDataLoading,
  withSearch,
  actionBar,
  filterProps,
  batchActions,
  selectable,
  expandable,
  renderCell,
  noHeader,
  customHeader,
  customHeaderRender,
  selectedRows,
  setSelectedRows,
  expandedRows,
  setExpandedRows,
  size = 'md',
  className,
  transparent,
  withBorders,
  sortable,
  noDataComponent,
  customBatchActionProps,
  expandableAlignedLeft,
  groupProps,
  customTopLabel,
  pagination,
  sortProps,
  onSort,
  customExpandLabel,
  activeOnExpanded,
  noInheritStyles,
  searchableKeys,
}) => {
  const isControlledSelectable = !!(selectedRows && setSelectedRows)
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = useMemo(() => {
    if (!searchTerm) return data;

    const headersKeys = headerData.map(header => header.key)

    return data.filter(item => {
      return (searchableKeys || headersKeys).some(key => {
        const value = item.record[key];
        return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
  }, [data, searchTerm, searchableKeys, headerData]);

  const handleSelectRow = (value: any, rowId: any) => {
    if (isControlledSelectable) {
      if (value) {
        setSelectedRows([...selectedRows, rowId])
      } else {
        setSelectedRows(selectedRows.filter((id: any) => id !== rowId))
      }
    }
  }

  const handleExpandRow = (value: any, rowId: any) => {
    if (expandable && setExpandedRows) {
      if (value) {
        setExpandedRows([...expandedRows, rowId])
      } else {
        setExpandedRows(expandedRows.filter((id: any) => id !== rowId))
      }
    }
  }

  const handleSelectAll = (clearAll: any) => {
    if (isControlledSelectable) {
      if (clearAll) {
        setSelectedRows([])
      } else {
        setSelectedRows(filteredData.map((row) => row.id));
      }
    }
  }

  const handleSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleCancelSelections = () => {
    if (isControlledSelectable) {
      setSelectedRows([])
    }
  }

  const isSelected = (rowId: any) =>
    isControlledSelectable ? selectedRows.includes(rowId) : false

  const modifiedData = useMemo(
    () =>
      filteredData.map((value) => ({
        id: value.id,
        ...value.record,
      })),
    [filteredData]
  )
  const dataHashMap = useMemo(
    () =>
      data.reduce((map, value) => {
        map[value.id] = value
        return map
      }, {} as Record<string, any>),
    [data]
  )

  const renderExpandableRow = (isExpandable: boolean, row: any, rowData: any, headers: any) => {
    if (isExpandable) {
      let expandableComponent: any = rowData.expandableComponent
      if (rowData.subTableData) {
        if (rowData.subTableData.useData) {
          expandableComponent = <SubDataTableWithHook rowData={rowData} />
        } else {
          expandableComponent = <SubDataTable customHeaderRender={customHeaderRender} data={rowData} />
        }
      } else if (typeof rowData.expandableComponent === 'function') {
        expandableComponent = rowData.expandableComponent(dataHashMap[row.id])
      }
      return (
        <TableExpandedRow
          className="non-hoverable"
          colSpan={headers.length + 2}
        >
          {expandableComponent}
        </TableExpandedRow>
      )
    }
    return null
  }

  const groupAndRenderData = (
    rows: any,
    getRowProps: any,
    getSelectionProps: any,
    headers: any
  ) => {
    return (
      <>
        {groupProps?.groupOptions.map((groupOption) => {
          const filteredByGroup = filterByGroupCondition(
            rows,
            groupOption.condition
          )
          if (filteredByGroup?.length <= 0) return null
          return (
            <React.Fragment key={groupOption.key}>
              {groupProps?.type === 'body' ? (
                <TableBody>
                  {!!groupOption.name ? (
                    <TableRow>
                      {groupProps?.isDropdown && (
                        <TableCell
                          className={`group-expand-cell`}
                          onClick={groupProps?.onHeaderClick?.bind(
                            this,
                            groupOption.key
                          )}
                        >
                          <div>
                            {groupOption.isCollapsed ? (
                              <ChevronRight />
                            ) : (
                              <ChevronDown />
                            )}
                          </div>
                        </TableCell>
                      )}
                      <TableCell
                        className={`group-cell`}
                        colSpan={headers.length + 2}
                        onClick={groupProps?.onHeaderClick?.bind(
                          this,
                          groupOption.key
                        )}
                      >
                        {groupOption.name}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              ) : groupOption?.name ? (
                  <TableHead>
                  <TableRow
                    onClick={groupProps?.onHeaderClick?.bind(
                      this,
                      groupOption.key
                    )}
                    style={{
                      cursor: groupProps?.isDropdown ? 'pointer' : 'default',
                    }}
                  >
                    <TableHeader colSpan={headers.length + 2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {groupProps?.isDropdown && (
                          <div className={`group-expand-cell-header`}>
                            <div>
                              {groupOption.isCollapsed ? (
                                <ChevronRight />
                              ) : (
                                <ChevronDown />
                              )}
                            </div>
                          </div>
                        )}
                        {groupOption.name}
                      </div>
                    </TableHeader>
                  </TableRow>
                </TableHead>
              ) : null}
              {!groupOption.isCollapsed && (
                <TableBody>
                  {renderRows(
                    filteredByGroup,
                    getRowProps,
                    getSelectionProps,
                    headers
                  )}
                </TableBody>
              )}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const filterByGroupCondition = (rows: any, condition: any) =>
    rows.filter((row: any) => {
      const rowData = dataHashMap[row.id]
      if (!rowData) return false
      return condition(rowData.record)
    })

  const renderRows = (rows: any, getRowProps: any, getSelectionProps: any, headers: any) =>
    rows.map((row: any) => {
      const rowData = dataHashMap[row.id]
      if (!rowData) return null
      const isExpandable = !!(
        expandable &&
        (rowData.subTableData || rowData.expandableComponent)
      )
      const RowComponent = isExpandable ? TableExpandRow : TableRow
      const rowProps = {
        ...getRowProps({ row }),
        ...rowData.record?.rowProps,
      }
      if (isExpandable) {
        rowProps.alignRight = !expandableAlignedLeft
        if (setExpandedRows) {
          const isExpanded = expandedRows.includes(row.id)
          rowProps.isExpanded = isExpanded
          rowProps.onExpand = () => handleExpandRow(!isExpanded, row.id)
        }
        if (customExpandLabel) {
          rowProps.customExpandLabel = customExpandLabel
        }
        if (activeOnExpanded) {
          rowProps.activeOnExpanded = activeOnExpanded
        }
      }
      if (isControlledSelectable) {
        rowProps.isSelected = isSelected(row.id)
      }
      const selectionProps = getSelectionProps({ row })
      const selectRowProps = isControlledSelectable
        ? {
          id: `data-table-2__select-row-${row.id}`,
          name: `select-row-${row.id}`,
          checked: isSelected(row.id),
          onChange: (value: any) => handleSelectRow(value, row.id),
          onSelect: selectionProps.onSelect,
        }
        : {
          ...selectionProps,
          onSelect: (evt: any) => {
            getSelectionProps({ row }).onSelect(evt)
          },
        }
      if (rowData.record?.onClick) {
        rowProps.onClick = rowData.record.onClick
        rowProps.className = rowProps.className ?? ''
        rowProps.className += ' clickable'
      }

      return (
        <React.Fragment key={row.id}>
          <RowComponent {...rowProps}>
            {selectable && (
              <TableSelectRow
                ariaLabel={selectRowProps.id}
                {...selectRowProps}
              />
            )}
            {row.cells.map((cell: any) => (
              <TableCell
                key={cell.id}
                {...dataHashMap[row.id].record?.props?.(cell)}
              >
                {renderCell(cell, dataHashMap[row.id])}
              </TableCell>
            ))}
          </RowComponent>
          {renderExpandableRow(isExpandable, row, rowData, headers)}
        </React.Fragment>
      )
    })

  return (
    <>
      <CdsDataTable
        rows={modifiedData}
        headers={headerData}
        size={size}
        isSortable={sortable}
        className={className}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getSelectionProps,
          getRowProps,
          getToolbarProps,
          getBatchActionProps,
          selectedRows: _selectedRows,
          onInputChange,
        }: any) => {
          let batchActionProps = getBatchActionProps()
          const { onSelect, ...selectionProps } = getSelectionProps()
          if (isControlledSelectable) {
            batchActionProps.shouldShowBatchActions = selectedRows.length > 0
            batchActionProps.totalSelected = selectedRows.length
            batchActionProps.onCancel = () => {
              handleCancelSelections()
            }

            selectionProps.onSelect = (evt: any) => {
              onSelect(evt)
              handleSelectAll(selectedRows.length > 0)
            }
            selectionProps.checked =
              selectedRows.length > 0 && rows.length <= selectedRows.length
            selectionProps.indeterminate =
              !selectionProps.checked && selectedRows.length >= 1
          } else {
            selectionProps.onSelect = onSelect
          }
          if (customBatchActionProps) {
            batchActionProps = {
              ...batchActionProps,
              ...customBatchActionProps,
            }
          }

          const classes = cx({
            ['transparent-table']: transparent,
            ['cds--show-batch-actions']:
              batchActionProps.shouldShowBatchActions,
            ['with-borders']: withBorders,
            ['no-inherit-styles']: noInheritStyles,
          })

          return (
            <TableContainer className={classes}>
              {(withSearch || !!actionBar || filterProps) && (
                <Box pb="16px">
                  {(withSearch || !!actionBar) && (
                    <Box
                      sx={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      {withSearch && (
                        <Box
                          pr={actionBar ? '8px' : '0px'}
                          sx={{
                            display: 'flex',
                            width: '100%',
                            flex: 1,
                            color: 'red',
                            '& .cds--search-input': {
                              ...(theme?.text as any).body
                            }
                         }}
                        >
                          <TableToolbarSearch
                            size="md"
                            onChange={handleSearchTermChange}
                            placeholder={searchableKeys ? `Search by ${searchableKeys.slice(0, -1).join(', ')}${searchableKeys.length > 1 ? ' and ' : ''}${searchableKeys[searchableKeys.length - 1]}`: "Search..."}
                            persistent
                          />
                        </Box>
                      )}
                      {actionBar}
                    </Box>
                  )}
                  {filterProps && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: '16px',
                        '& > .cds--tag': { mr: '8px', mt: 0, mb: 0 },
                        ...filterProps.sx,
                      }}
                    >
                      {filterProps.list.map((item) => (
                        <Tag
                          key={`filter-${item.key}`}
                          type="outline"
                          size="md"
                          filter
                          onClose={item.onClose}
                        >
                          {item.content}
                        </Tag>
                      ))}
                      <Link onClick={filterProps.onReset} renderIcon={Restart}>
                        {filterProps.resetText}
                      </Link>
                    </Box>
                  )}
                </Box>
              )}
              {customTopLabel}
              {!!batchActions && (
                <TableToolbar {...getToolbarProps()}>
                  <TableBatchActions {...batchActionProps}>
                    {batchActions.map((item) => (
                      <React.Fragment key={`batch-action-${item.label}`}>
                        {item.label && item.onClick && (
                          <Button
                            size="sm"
                            kind="ghost"
                            onClick={() => {
                              item.onClick(selectedRows || _selectedRows)
                            }}
                            disabled={!!item.disabled}
                          >
                            {item.label}
                          </Button>
                        )}
                        {item.label && item.menuList && (
                          <ButtonMenu
                            list={item.menuList}
                            portal={item.portal}
                            direction="bottom"
                            title={item.label}
                            buttonProps={{
                              kind: 'ghost',
                              size: 'sm',
                            }}
                            hideIcon
                          />
                        )}
                        {item.divider && (
                          <Box
                            sx={{
                              background: (theme?.colors?.text as any).onColor,
                              display: 'inline-flex',
                              height: '16px',
                              width: '1px',
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </TableBatchActions>
                </TableToolbar>
              )}
              {data?.length <= 0 && !!noDataComponent ? (
                noDataComponent
              ) : (
                <Table {...getTableProps()}>
                  {!noHeader && !customHeader && (
                    <TableHead>
                      <TableRow>
                        {selectable ? isDataLoading ? <Box as="td" sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '52px', padding: '3px 4px 3px 18px' }}><Loading withOverlay={false} small /></Box> : <TableSelectAll {...selectionProps} /> : null}
                        {headers.map((header: any) => {
                          const headerProps = {
                            ...getHeaderProps({
                              header,
                              isSortable: !!header.sort,
                            }),
                          }
                          if (headerProps.key) {
                            headerProps.className = `${headerProps.className ?? ''
                              } header-key-${headerProps.key}`
                          }
                          if (header.sort && sortProps && onSort) {
                            if (sortProps.key === headerProps.key) {
                              headerProps.isSortHeader = true
                              headerProps.sortDirection =
                                sortProps.order ?? 'NONE'
                            }
                            headerProps.onClick = () => {
                              const nextOrder = getNextSortOrder(
                                headerProps.sortDirection
                              )
                              onSort(headerProps.key, nextOrder)
                            }
                          }
                          return !customHeaderRender ? (
                            <TableHeader {...headerProps}>
                              <Text variant="body">{header.header}</Text>
                            </TableHeader>
                          ) : (
                            customHeaderRender(header, headerProps)
                          )
                        })}
                        {expandable && <TableExpandHeader />}
                      </TableRow>
                    </TableHead>
                  )}
                  {customHeader}
                  {groupProps ? (
                    groupAndRenderData(
                      rows,
                      getRowProps,
                      getSelectionProps,
                      headers
                    )
                  ) : (
                    <TableBody>
                      {renderRows(
                        rows,
                        getRowProps,
                        getSelectionProps,
                        headers
                      )}
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          )
        }}
      </CdsDataTable>
      {pagination && (
        <Pagination
          backwardText="Previous page"
          forwardText="Next page"
          itemsPerPageText="Items per page:"
          pageNumberText="Page Number"
          pageSizes={[50, 100, 200]}
          {...pagination}
        />
      )}
    </>
  )
}

const SubDataTable = ({ customHeaderRender, data, isLoading = false }: any) => {
  const modifiedData = useMemo(
    () =>
      data.subTableData.data.map((value: any) => ({
        id: value[data.subTableData.keyField ?? 'id'],
        ...value,
      })),
    [data.subTableData.data, data.subTableData.keyField]
  )

  const dataHashMap = useMemo(
    () =>
      data.subTableData.data.reduce((map: any, value: any) => {
        map[value[data.subTableData.keyField ?? 'id']] = value
        return map
      }, {}),
    [data.subTableData.data, data.subTableData.keyField]
  )

  return (
    <Box className="sub-table-container">
      <Loader show={isLoading} />
      {/* @ts-ignore */}
      { isLoading || modifiedData?.length ? (
        <CdsDataTable
          rows={modifiedData}
          headers={data.subTableData.headerData}
          size="sm"
        >
          {({
            rows: _rows,
            headers: _headers,
            getHeaderProps,
            getTableProps,
          }: any) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {_headers.map((header: any) => {
                    return !customHeaderRender ? (
                      <TableHeader {...getHeaderProps({ header })}>
                        <Text variant="body">{header.header}</Text>
                      </TableHeader>
                    ) : (
                      customHeaderRender(header, {...getHeaderProps({ header })})
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {_rows.map((_row: any) => (
                  <TableRow key={_row.id}>
                    {_row.cells.map((cell: any) => (
                      <TableCell
                        key={cell.id}
                        {...data.subTableData?.cellProps?.(cell)}
                      >
                        {data.subTableData.renderCell(cell, dataHashMap[_row.id])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CdsDataTable>
      ) : (
        <NoResourcesBox text={data.subTableData.noDataText} />
      )}
    </Box>
  )
}

const SubDataTableWithHook = ({ rowData }: any) => {
  const {
    data: _data,
    isInitialLoading,
    isLoading,
  } = rowData.subTableData.useData()

  const data = useMemo(() => {
    let result = _data ?? []
    if (rowData.subTableData.filterData) {
      result = result.filter(rowData.subTableData.filterData)
    }
    return {
      ...rowData,
      subTableData: {
        ...rowData.subTableData,
        data: result,
      },
    }
  }, [_data, rowData])

  if (isInitialLoading) {
    return (
      <div sx={{ position: 'relative', width: '100%', minHeight: 200 }}>
        <Loader show={isInitialLoading} />
      </div>
    )
  }

  return <SubDataTable data={data} isLoading={isLoading} />
}

export default DataTable
