import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { useEffect } from 'react'
import { useAuthStore } from '../store/authStore'
import * as Sentry from '@sentry/react'

const AuthProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()
  const setAccessToken = useAuthStore((state) => state.setAccessToken);

  useEffect(() => {
    if (user?.accessToken) {
      setAccessToken(user.accessToken);
    }
  }, [user?.accessToken, setAccessToken]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect()
    }
  }, [isAuthenticated, loginWithRedirect])

  return isAuthenticated ? children : null
}

export default AuthProvider
