import * as PATHS from '../constants/routes.constants'

import {
	AccessControlIcon,
	ChargebackCalculatorIcon,
	FlexTemplatesIcon,
	TagManagementIcon,
} from '@boundlessdigital/bng-shared-components'
import { Product } from "./config.types";
import { userManagementConfig } from '../pages/user-management/user-management.config';
import { configurationBackupConfig } from '../pages/configuration-backup/configuration-backup.config';
import { switchPortManagementConfig } from '../pages/switch-port-management/switch-port-management.config';
import { visionPortalAccessControlConfig } from '../pages/vision-portal-access-control/vision-portal-access-control.config';

export const productsConfig: Product[] = [
	userManagementConfig,
	configurationBackupConfig,
	switchPortManagementConfig,
	visionPortalAccessControlConfig,
	{
		title: 'Access control',
		icon: {
			title: 'Access control',
			component: AccessControlIcon,
			description: 'Refine Meraki dashboard access with granular role-based permissions.',
		},
		description: 'Refine Meraki dashboard access with granular role-based permissions.',
		isLocked: true,
	},
	{
		title: 'Flextemplates',
		icon: {
			title: 'Flextemplates',
			component: FlexTemplatesIcon,
			description: 'Streamline Meraki network configuration updates at scale.',
		},
		description: 'Streamline Meraki network configuration updates at scale.',
		isLocked: true,
	},
	{
		title: 'Smart Track',
		icon: {
			title: 'Smart Track',
			component: ChargebackCalculatorIcon,
			description: 'Enhance the management of your Cisco Meraki Enterprise Agreement.',
		},
		description: 'Enhance the management of your Cisco Meraki Enterprise Agreement.',
		isLocked: true,
	},
	{
		title: 'Tag Management',
		icon: {
			title: 'Tag Management',
			component: TagManagementIcon,
			description: 'Simplify tagging, boost compliance and control over Meraki’s resources.',
		},
		description: 'Simplify tagging, boost compliance and control over Meraki’s resources.',
		isLocked: true,
	}
];
