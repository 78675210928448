import React from 'react'
import { ChevronDown } from '@carbon/icons-react'
import {
  ButtonDefaultProps,
  ButtonKindProps,
} from 'carbon-components-react/lib/components/Button/Button'
import { BasicMenuListType } from '@boundlessdigital/bng-shared-components/dist/components/Menu'
import BasicMenu, { IBasicMenuProps } from '../Menu/BasicMenu'

export interface IButtonMenuProps extends Omit<IBasicMenuProps, 'menuButton'> {
  list: BasicMenuListType[]
  title: string
  buttonProps?: ButtonDefaultProps & ButtonKindProps
  hideIcon?: boolean
}

const ButtonMenu: React.FC<IButtonMenuProps> = ({
  list,
  title,
  buttonProps,
  hideIcon = false,
  ...props
}) => (
  <BasicMenu
    buttonProps={{
      activeClass: 'hovered',
      kind: 'secondary',
      size: 'md',
      ...buttonProps,
      onClick: (e) => e.stopPropagation(),
      children: (
        <>
          {title} {!hideIcon && <ChevronDown style={{ marginLeft: 4 }} />}
        </>
      ),
    }}
    portal
    direction="bottom"
    align="end"
    offsetY={8}
    list={list}
    {...props}
  />
)

export default ButtonMenu
