/** @jsxImportSource theme-ui */
import { useEffect, useMemo, useRef, useState } from 'react'
import { Text } from 'theme-ui'
import { Loader } from '@boundlessdigital/bng-shared-components'
import { Information } from '@carbon/icons-react'
import { WizardPage } from '../profile-setup.constants'
import { useSetOnboardingWorkspace } from '../../../api/users/onboardingHooks'
import { useAuthUser } from '@frontegg/react'
import { useRegions } from '../../../api/regions/regionsHooks'
import { basicTooltipProps, transformRegion, validateWorkspaceName } from '../../../utils/general.utils'
import Tile from '../../../components/Tile/Tile'
import ButtonGroup from '../../../components/Button/ButtonGroup'
import TextField from '../../../components/inputs/TextField'
import SelectField from '../../../components/inputs/SelectField'
import Button from '../../../components/Button/Button'

const WorkspaceStep = ({ setPage }) => {
    const [name, setName] = useState('')
    const [region, setRegion] = useState<any>()
    const [isNameValid, setIsNameValid] = useState<boolean>()
    const { data: regionsData } = useRegions()
    const { id } = useAuthUser()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const setOnboardingWorkspaceMutation = useSetOnboardingWorkspace(id)
    const regionsList = useMemo(
        () =>
            regionsData?.map((v) => ({ value: v, label: transformRegion(v) })) || [],
        [regionsData],
    )
    
    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    useEffect(() => {
        if (!region && regionsList.length > 0) {
            setRegion(regionsList[0])
        }
    }, [regionsList, region])

    const onSubmit = async () => {
        if (name && region?.value) {
            await setOnboardingWorkspaceMutation.mutateAsync({
                name: name.trim(),
                region: region?.value,
            })
            setPage(WizardPage.Products)
        }
    }

    const handleWorkspaceNameChange = (e) => {
        setIsNameValid(validateWorkspaceName(e.target.value))
        setName(e.target.value)
    };

    return (
        <Tile
            wrapperSx={{ mt: '24px', position: 'relative' }}
            title="Workspace details"
            footer={
                <ButtonGroup>
                    <Button
                        onClick={onSubmit}
                        disabled={!name.trim() || !isNameValid || !region?.value}
                        size="md"
                        kind="primary"
                    >
                        Continue
                    </Button>
                </ButtonGroup>
            }
        >
            <Loader show={setOnboardingWorkspaceMutation.isLoading} withOverlay />
            <TextField
                ref={inputRef}
                label="Pick a name"
                placeholder="My Workspace"
                wrapperSx={{ mb: '16px' }}
                icon={
                    <Information
                        {...basicTooltipProps(
                            `Create a Workspace name of up to 24 characters<br />using only letters and numbers (A-Z, a-z, 0-9).<br />Please refrain from using any special symbols.`,
                            null,
                            true,
                        )}
                    />
                }
                isError={!isNameValid && name}
                helperText={!isNameValid && name ? 'Workspace name is not valid.' : ''}
                onChange={handleWorkspaceNameChange}
                value={name}
                maxLength={24}
                rightLabel={`${name.length}/24`}
            />
            <SelectField
                label="Select a region"
                options={[regionsList[0]]}
                value={region}
                onChange={(newValue: any) => setRegion(newValue)}
                placeholder="Select a region"
                width="100%"
            />
            <Text as="p" variant="caption" mt="16px" sx={{ maxWidth: '640px' }}>
                This is where your workspace data will be securely stored. Note that it
                may differ from your Meraki organization region. Once chosen, this
                parameter can't be changed.
            </Text>
        </Tile>
    )
}

export default WorkspaceStep
