import { UseQueryResult } from '@tanstack/react-query'

export enum AdminStatus {
  OK = 'ok',
  LOCKED = 'locked',
  PENDING = 'pending',
  UNVERIFIED = 'unverified',
  DISABLED = 'disabled',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NOT_CREATED = 'not_created'
}

export interface Organization {
  createdAt: Date;
  organizationAccess: string;
  workspaceId: string;
  accountStatus: AdminStatus;
  organizationId: string;
  updatedAt: Date;
  lastActive?: Date;
  organizationAdminId: string;
  adminId: string;
  organizationName: string;
  hasDetailedPermissions: boolean;
}

export interface TransformedOrganization extends Organization {
  id: string;
  name: string;
  privilege: string;
  status: AdminStatus;
  detailedPermissions: any[] | undefined;
}

interface BaseAdmin {
  createdAt: Date;
  adminName: string;
  workspaceId: string;
  updatedAt: Date;
  adminEmail: string;
  adminId: string;
}


export interface Admin extends BaseAdmin {
  accountStatus: AdminStatus;
  userId: string;
  id?: string;
  organizationsCount: number;
  organizations?: Organization[]
  trainingsCount?: number;
  trainings?: any[]
};

export interface TransformedAdmin extends Omit<Admin, 'organizations'> {
  id: string;
  fullName: string;
  email: string;
  disabled: boolean;
  status: AdminStatus;
  organizationsCount: number;
  trainingsCount: number;
  organizations?: TransformedOrganization[]
  trainings?: any[];
  lastActive?: string;
};

export type UseAdminsType = () => UseQueryResult<Admin[], Error>;

export interface AdminsResponse {
  data: Admin[];
  cursor: string | null;
}

export interface AdminByIdResponse extends Admin {
  note: string;
}
