/** @jsxImportSource theme-ui */
import { Box, Text } from 'theme-ui'
import { PlayFilledAlt } from '@carbon/icons-react'

export const VideoPreviewBox = ({ title, description, duration, imageSrc, imageAlt, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          overflow: 'hidden',
          marginRight: '16px',
          display: 'flex',
          flexShrink: 0,
          minWidth: 0,
          marginTop: '12px',
          borderRadius: '4px',
          '& img': {
            width: '173px',
            height: 'auto',
          },
        }}
      >
        <Box
          sx={{
            bg: '#FFFFFF',
            border: `1px solid #B5B5B5`,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '28px',
            height: '28px',
            borderRadius: '28px',
            opacity: '0.8',
            '& svg': {
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
        >
          <PlayFilledAlt size={16} color="#717171" />
        </Box>
        <img
          src={imageSrc}
          alt={imageAlt}
        />
        <Box
          sx={{
            position: 'absolute',
            background: '#FFFFFF',
            px: '4px',
            height: '20px',
            bottom: '6px',
            left: '6px',
            opacity: '0.8',
            borderRadius: '4px',
          }}
        >
          <Text variant="body">{duration}</Text>
        </Box>
      </Box>

      <Box sx={{
        color: 'rgba(255, 255, 255, 0.87)',
        marginTop: '12px',
      }}>
        <Text as="p" variant="heading" color="inherit">
          {title}
        </Text>
        <Text
          sx={{ mt: '4px', mb: '-4px' }}
          as="p"
          variant="caption"
          color="inherit"
        >
          {description}
        </Text>
      </Box>
    </Box>
  )
}
