import { Flex } from "theme-ui";
import Link from "../../components/Link";
import { DEFAULT_CONTENT, WizardPage } from "./profile-setup.constants";
import { Content } from "./profile-setup.types";
import Button from "../../components/Button/Button";
import { Copy } from "@carbon/icons-react";
import notify from "../../utils/notify.utils";
import { getErrorMessage } from "../../utils/errors.utils";

export const getContentByPage = (page: WizardPage, product?: { title: string }): Content => {
  switch (page) {
    case WizardPage.Products:
      return {
        ...DEFAULT_CONTENT,
        title: 'Pick your first product',
        description: 'Choose the product you want to use first. Don\'t worry, you\'ll be able to add more from our platform in a moment.',
      };
    case WizardPage.ApiKey:
      return {
        ...DEFAULT_CONTENT,
        title: 'Create a Meraki API key',
        description: 'Now, let’s get linked your API to Boundless. To do that, please name your API key, provide a key for it, and wait for it to be validated.',
        resource: {
          imageSrc: 'https://i.vimeocdn.com/video/844367950-00be70a8593147d06617918edf49cc9de8544d48ae02ec7b234c7af0156009ff-d',
          imageAlt: 'Where do I find my API key?',
          title: 'Where do I find my API key?',
          description: 'Check out this tutorial to learn how to enable the API in your organization and create an API key on the Meraki dashboard.',
          duration: '0:43',
          videoSrc: 'https://player.vimeo.com/video/383057089?h=d56617f30a&badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479',
          videoTitle: 'Enable meraki API and generate API key'
        },
      };
    case WizardPage.Connect:
    case WizardPage.ProcessingData:
      return {
        ...DEFAULT_CONTENT,
        title: 'Connect your organizations to Boundless',
        description: `Now, let's choose the organizations you'd like to manage using ${product?.title}. To do that, mark them as “Enabled” and click on “Continue.” This will allow us to connect the data from selected organizations and run changes.`,
        resource: {
          imageSrc: 'https://i.vimeocdn.com/video/844367950-00be70a8593147d06617918edf49cc9de8544d48ae02ec7b234c7af0156009ff-d',
          imageAlt: 'Where do I find my API key?',
          title: 'Where do I find my API key?',
          description: 'This quick tutorial shows you where to find meraki API key in the dashboard settings. Follow the steps to get your key. Keep it secure and use it as needed.',
          duration: '0:43',
          videoSrc: 'https://player.vimeo.com/video/383057089?h=d56617f30a&badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479',
          videoTitle: 'Enable meraki API and generate API key'
        },
      };
    default:
      return DEFAULT_CONTENT;
  }
};

export const handleOpenVideoModal = (openModal, videoSrc, videoTitle) => {
  openModal({
    title: '',
    body: (
      <div style={{ padding: '50% 0 0 0', position: 'relative' }}>
        <iframe
          src={videoSrc}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          title={videoTitle}
        ></iframe>
      </div>
    ),
    modalProps: {
      className: 'plain-modal',
      size: 'lg',
    },
  })
}

const handleCopy = async (ip) => {
  try {
    await navigator.clipboard.writeText(ip);
    notify({title: 'Content copied to clipboard', kind: 'success'})
  } catch (err) {
    notify({title: 'Failed to copy' , kind: 'error'})
  }
}

export const getErrorBody = (error) => {
  const ipAddress = error.metadata?.ipAddresses?.[0] || ''

  if (error.errorCode === 'E1000') {
    return  (
      <>
        To address the current issue, please ensure that the specified IP address ranges are added to the whitelist within organizations where IP restrictions are enforced. This can be accomplished through the Meraki dashboard: navigate to <strong>Organizations &gt; Configure &gt; Settings</strong>.
        <Flex sx={{ borderRadius: '4px', mt: '24px', p: 2, height: '40px', border: '1px solid #c9cccf', alignItems: 'center', justifyContent: 'space-between'}}>
          {ipAddress}

          <Button size="sm" kind="ghost" onClick={() => handleCopy(error.metadata?.ipAddresses?.[0] || '')}>
            <Copy />
          </Button>
        </Flex>
      </>
    )
  }

  if (error.errorCode === 'E1001') {
    return 'If you have recently generated that API key, it may take some time for it to work. Please wait 2 minutes and try again.';
  }

  return (
    <>For more information, see <Link to="https://documentation.meraki.com/">Meraki documentation</Link></>
  )
}

export const handleMerakiApiKeyErrors = (error) => {
  const { errors } = error?.response?.data || {}

  let parsedError = {}

  if (error.response.status === 500 && !errors) {
    parsedError = {
      title: 'Error message',
      description: 'Please verify the error message and try again.',
      linkText: 'View error message',
      message: 'Internal server error',
      body: 'Please verify the error message and try again.'
    }

  } else if (errors?.length === 1) {
    const message = getErrorMessage(errors[0])
    const body = getErrorBody(errors[0])

    switch (errors[0]?.errorCode) {
      case 'E1000':
        parsedError = {
          title: 'Whitelist IP address',
          description: 'We could not verify yout API key because of your organizations have IP access restrictions in place.',
          linkText: 'Resolve this issue',
          message,
          body
        }
        break;
      case 'E1001':
        parsedError = {
          title: 'Error message',
          description: 'If you have recently generated that API key, it may take some time for it to work. Please wait 2 minutes and try again.',
          linkText: 'View error message',
          message,
          body
        }
        break;
      default:
        parsedError = {
          title: 'Error message',
          description: 'Please verify the error message and try again.',
          linkText: 'View error message',
          message,
          body
        }
    }
  } else {
    parsedError = {
      title: 'Error messages',
      description: 'Please verify the error messages and try again.',
      linkText: 'View error messages',
      errors
    }
  }

  return parsedError
}
