/** @jsxImportSource theme-ui */
import React, { useMemo } from 'react'
import { Box, Container, Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { CheckmarkFilled, WarningFilled } from '@carbon/icons-react'
import { Loading } from '@carbon/react'
import theme from '../../../config/theme'
import IconLabel from '../../../components/Labels/IconLabel'

export interface IDataLoadingTileProps {
  sx?: ThemeUIStyleObject
  isLoading?: boolean
  label?: string
  icon?: any
  status?: 'loading' | 'success' | 'error' | 'pending'
  loaded?: number
  total?: number
}

const rootSx: ThemeUIStyleObject = {
  background: 'surface.default.primary',
  boxShadow:
    '0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  padding: '16px',
  mt: '16px',
}

const DataLoadingTile: React.FC<IDataLoadingTileProps> = ({
  label,
  isLoading,
  icon,
  status,
  loaded,
  total,
  sx,
}) => {
  const StatusComponent = useMemo(() => {
    let statusProps: any = null
    switch (status) {
      case 'success':
        statusProps = {
          icon: <CheckmarkFilled sx={{ color: 'text.success' }} />,
          label: 'Completed',
        }
        break
      case 'pending':
        statusProps = {
          icon: null,
          label: 'In queue',
        }
        break
      case 'loading':
        statusProps = {
          icon: <Loading small withOverlay={false} />,
          label: 'Loading...',
        }
        break
      case 'error':
        statusProps = {
          icon: <WarningFilled />,
          label: 'Error',
        }
        break
    }
    if (statusProps) {
      return (
        <IconLabel
          rootSx={{ '& span': { color: (theme?.colors?.text as any).secondary } }}
          {...statusProps}
        />
      )
    }
    return null
  }, [status])

  const isCompleted = status === 'success'

  return (
    <Container sx={{ ...rootSx, sx }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          // mb: '16px',
        }}
      >
        <Flex
          sx={{
            '& svg': {
              color: isCompleted ? 'secondary' : '',
            },
          }}
        >
          {icon}
          <Text
            as="div"
            variant="displaySmall"
            sx={{ marginLeft: '8px' }}
            color={isCompleted ? 'secondary' : ''}
          >
            {label}
          </Text>
        </Flex>

        {StatusComponent}
      </Box>
      {/* {isLoading ? (
        <Loading small withOverlay={false}/>
      ) : (
        <Text
          as="p"
          variant="displayLarge"
          color={isCompleted ? 'secondary' : ''}
        >
          {loaded}/{total}
        </Text>
      )} */}
    </Container>
  )
}

export default DataLoadingTile
