import * as PATHS from '../../constants/routes.constants'

import { UserManagementIcon } from '@boundlessdigital/bng-shared-components'
import { Product } from '../../config/config.types'
import { ProductEnum } from '../../types/general.types'
import { asyncComponentLoader } from '../../components/component-loader/component-loader.component'

export const userManagementConfig: Product = {
    title: 'User Management',
		description: 'Streamline user management, execute bulk edits, and effortlessly schedule access for optimal control.',
		value: ProductEnum.USER_MANAGEMENT,
		icon: {
			title: 'User Management',
			component: UserManagementIcon,
			description: 'Improve the management of your Cisco Meraki user base and get better control.',
		},
		path: PATHS.USER_MANAGEMENT,
    component: asyncComponentLoader(() => import(/* webpackChunkName: "user-management" */ './user-management.component')),
    isDevelopment: false,
    isLocked: false,
		configuration: {
			wrapper: {
				title: 'User management configuration',
        description: 'Manage user settings, organizational structures, and system configurations.',
        path: PATHS.USER_MANAGEMENT_CONFIGURATION,
				component: asyncComponentLoader(() => import(/* webpackChunkName: "user-management/configuration" */ '../configuration/configuration.component')),
			},
			children: [{
				title: 'Rollback',
				index: true,
				description: 'Track and manage your activity. Easily undo and redo actions and review user operations.',
				path: 'rollback',
				component: asyncComponentLoader(() => import(/* webpackChunkName: "user-management/configuration/rollback" */ '../configuration/components/rollback.component')),
			}]
		},
    children: [
      {
				title: 'User overview',
				description: 'Oversee and manage access and for each user.',
				path: PATHS.MANAGE_USER,
        component: asyncComponentLoader(() => import(/* webpackChunkName: "user-management/user-overview" */ './user-overview/user-overview.component')),
      },
      {
				title: 'Invite users',
        description: 'Add new administrators to Meraki organizations',
        path: PATHS.ADD_ADMIN,
				component: asyncComponentLoader(() => import(/* webpackChunkName: "user-management/add-admin" */ './add-admin/add-admin.component')),
      },
      {
				title: 'Manage users',
				description: 'Manage user access and permissions to Meraki.',
        path: PATHS.MANAGE_ADMINISTRATORS,
				component: asyncComponentLoader(() => import(/* webpackChunkName: "user-management/manage-user" */ './manage-users.component')),
      }
    ]
  }