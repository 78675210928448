import React from 'react'
import { ListDropdown } from '@carbon/icons-react'
import { Container, Text, ThemeUIStyleObject } from 'theme-ui'
import Button from '../../../components/Button/Button'

const rootSx: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  py: '24px',
  '& > svg': { width: '40px', height: '40px', color: 'text.secondary' },
}

const NoResourcesBox: React.FC<{
  buttonText?: string
  text?: string
  showCaption?: boolean
  caption?: string
  onReset?: any
}> = ({ text, caption, showCaption, buttonText, onReset }) => {
  return (
    <Container sx={rootSx}>
      <ListDropdown size={32} />
      <Text
        as="p"
        variant="displaySmall"
        sx={{ maxWidth: '400px', textAlign: 'center', my: '16px' }}
      >
        {text || 'No results found.'}
      </Text>
      {showCaption && (
        <Text mb="16px" as="p" variant="body" color="secondary">
          {caption || 'There are no items that match your search.'}
        </Text>
      )}
      {!!onReset && (
        <Button kind="secondary" size="md" onClick={onReset}>
          {buttonText || 'Reset Filters'}
        </Button>
      )}
    </Container>
  )
}

export default NoResourcesBox
