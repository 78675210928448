export enum Region {
  US_CANADA_PUERTO_RICO = 'US_CANADA_PUERTO_RICO',
  LATIN_AMERICA_CARIBBEAN = 'LATIN_AMERICA_CARIBBEAN',
  ASIA_PACIFIC = 'ASIA_PACIFIC',
  EUROPE = 'EUROPE',
  AFRICA_MIDDLE_EAST_INDIA = 'AFRICA_MIDDLE_EAST_INDIA',
}

export const regionMapping: Record<Region, string> = {
  [Region.US_CANADA_PUERTO_RICO]: 'United States',
  [Region.LATIN_AMERICA_CARIBBEAN]: 'Latin America, Caribbean',
  [Region.ASIA_PACIFIC]: 'Asia Pacific',
  [Region.EUROPE]: 'Europe',
  [Region.AFRICA_MIDDLE_EAST_INDIA]: 'Africa, Middle East, India',
}
