/* ----------- MAIN PAGES -------------- */
export const HOMEPAGE = `/`
export const WORKSPACE = `${HOMEPAGE}workspace`

export const API_KEY_MANAGEMENT = `${HOMEPAGE}api-key-management`
export const VIEW_API_KEY = `${API_KEY_MANAGEMENT}/:id`
export const EDIT_API_KEY = `${API_KEY_MANAGEMENT}/:id/edit`
export const CONNECT_API_KEY = `${API_KEY_MANAGEMENT}/connect-api-key`

export const API_GATEWAY = `${HOMEPAGE}api-gateway`
export const API_GATEWAY_CONFIGURATION = `${API_GATEWAY}/configuration`
export const API_GATEWAY_CONFIGURATION_ORGANIZATIONS = `${API_GATEWAY_CONFIGURATION}/organizations`
export const API_GATEWAY_CONFIGURATION_BEHAVIOUR = `${API_GATEWAY_CONFIGURATION}/behaviour`
export const API_GATEWAY_CONFIGURATION_LOGGING = `${API_GATEWAY_CONFIGURATION}/logging`
export const API_GATEWAY_CONFIGURATION_RATE_LIMITING = `${API_GATEWAY_CONFIGURATION}/rate-limiting`
export const API_GATEWAY_CONFIGURATION_RETRY_POLICY = `${API_GATEWAY_CONFIGURATION}/retry-policy`
export const API_GATEWAY_CONFIGURATION_IP_RESTRICTIONS = `${API_GATEWAY_CONFIGURATION}/restrictions`
export const API_GATEWAY_CONFIGURATION_IP_CACHING = `${API_GATEWAY_CONFIGURATION}/caching`

export const USER_MANAGEMENT = `${HOMEPAGE}user-management`
export const MANAGE_USER = `${USER_MANAGEMENT}/manage-user/:userId`
export const ADD_ADMIN = `${USER_MANAGEMENT}/add-admin`
export const MANAGE_ADMINISTRATORS = `${USER_MANAGEMENT}/manage-administrators`
export const USER_MANAGEMENT_CONFIGURATION = `${USER_MANAGEMENT}/configuration`
export const USER_MANAGEMENT_CONFIGURATION_ORGANIZATIONS = `${USER_MANAGEMENT_CONFIGURATION}/organizations`
export const USER_MANAGEMENT_CONFIGURATION_ROLLBACK = `${USER_MANAGEMENT_CONFIGURATION}/rollback`

export const CONFIGURATION_BACKUP = `${HOMEPAGE}configuration-backup`
export const BACKUP_OVERVIEW = `${HOMEPAGE}configuration-backup/:backupId`
export const CONFIGURATION_BACKUP_CONFIGURATION = `${CONFIGURATION_BACKUP}/configuration`
export const CONFIGURATION_BACKUP_CONFIGURATION_ORGANIZATIONS = `${CONFIGURATION_BACKUP_CONFIGURATION}/organizations`
export const CONFIGURATION_BACKUP_CONFIGURATION_ROLLBACK = `${CONFIGURATION_BACKUP_CONFIGURATION}/rollback`

export const SWITCH_PORT_MANAGER = `${HOMEPAGE}switch-port-manager`

export const ESTEE_LAUDER_VISION_PORTAL = `${HOMEPAGE}estee-lauder-vision-portal`

export const ACCESS_CONTROL = `${HOMEPAGE}access-control`

export const TAG_MANAGEMENT = `${HOMEPAGE}tag-management`

export const SMART_TRACK = `${HOMEPAGE}smart-track`;
export const RENEWALS_MANAGEMENT = `${SMART_TRACK}/renewals-management`;
export const DEVICE_MANAGEMENT = `${SMART_TRACK}/device-management`;
export const DEVICE_CLAIM_LOG = `${SMART_TRACK}/device-claim-log`;
export const CONSUMPTION_REPORT = `${SMART_TRACK}/consumption-report`;

/* ----------- COMPLETE PROFILE -------------- */
export const PROFILE_SETUP = `${HOMEPAGE}profile-setup`;

/* ----------- EA IHG -------------- */
export const EA_IHG_ROOT = `${HOMEPAGE}ea-ihg`
export const EA_IHG_RENEWALS_DASHBOARD = `${HOMEPAGE}ea-ihg/renewals/dashboard`
export const EA_IHG_RENEWALS_MANAGEMENT = `${HOMEPAGE}ea-ihg/renewals/management`
export const EA_IHG_RENEWALS_LOGS = `${HOMEPAGE}ea-ihg/renewals/logs`

export const EA_IHG_DEVICE_MANAGEMENT = `${HOMEPAGE}ea-ihg/device-management`
export const EA_IHG_DEVICE_CLAIM_LOG = `${HOMEPAGE}ea-ihg/device-claim-log`

export const EA_IHG_PARTNER_PRICING = `${HOMEPAGE}ea-ihg/partner-pricing`
export const EA_IHG_PARTNER_PRICING_VIEW = `${HOMEPAGE}ea-ihg/partner-pricing/view/:id`
export const EA_IHG_SETTINGS = `${HOMEPAGE}ea-ihg/settings`
