/** @jsxImportSource theme-ui */
import React from 'react'
import {
  ClickEvent,
  Menu,
  MenuAlign,
  MenuArrowModifiers,
  MenuButtonModifiers,
  MenuChangeEvent,
  MenuDirection,
  MenuDivider,
  MenuHeader,
  MenuInstance,
  MenuItem,
  MenuModifiers,
  MenuOverflow,
  MenuPosition,
  MenuProps,
  MenuReposition,
  MenuViewScroll,
} from '@szhsin/react-menu'
import { Box, Text } from 'theme-ui'
import {
  ButtonDefaultProps,
  ButtonKindProps,
} from 'carbon-components-react/lib/components/Button/Button'
import { ClassNameProp, EventHandler, RenderProp } from '@boundlessdigital/bng-shared-components/dist/types'
import Button from '../Button/Button'
import { BasicMenuListType } from '@boundlessdigital/bng-shared-components/dist/components/Menu'

export interface IBasicMenuProps extends Omit<MenuProps, 'menuButton'> {
  /**
   * List of menu items.
   */
  list?: BasicMenuListType[]

  /**
   * Can be a string or a function which receives a modifier object and returns a CSS `class` string.
   */
  menuClassName?: ClassNameProp<MenuModifiers>
  /**
   * This prop is forwarded to the `style` prop of menu DOM element.
   */
  menuStyle?: React.CSSProperties
  /**
   * Can be a string or a function which receives a modifier object and returns a CSS `class` string.
   */
  arrowClassName?: ClassNameProp<MenuArrowModifiers>
  /**
   * This prop is forwarded to the `style` prop of menu arrow DOM element.
   */
  arrowStyle?: React.CSSProperties
  /**
   * Set `true` to display an arrow pointing to its anchor element.
   */
  arrow?: boolean
  /**
   * Properties of this object are spread to a DOM element which captures focus for the menu.
   */
  focusProps?: React.HTMLAttributes<HTMLElement>
  /**
   * Set the horizontal distance (in pixels) between menu and its anchor element.
   * The value can be negative.
   * @default 0
   */
  offsetX?: number
  /**
   * Set the vertical distance (in pixels) between menu and its anchor element.
   * The value can be negative.
   * @default 0
   */
  offsetY?: number
  /**
   * Set alignment of menu with anchor element.
   * @default 'start'
   */
  align?: MenuAlign
  /**
   * Set direction in which menu expands against anchor element.
   * @default 'bottom'
   */
  direction?: MenuDirection
  /**
   * Set the position of menu related to its anchor element:
   *
   * - 'auto' menu position is adjusted to have it contained within the viewport,
   * even if it will be detached from the anchor element.
   * This option allows to display menu in the viewport as much as possible.
   *
   * - 'anchor' menu position is adjusted to have it contained within the viewport,
   * but it will be kept attached to the edges of anchor element.
   *
   * - 'initial' menu always stays at its initial position.
   * @default 'auto'
   */
  position?: MenuPosition
  /**
   * Make the menu list scrollable or hidden when there is not enough viewport space to
   * display all menu items. The prop is similar to the CSS `overflow` property.
   * @default 'visible'
   */
  overflow?: MenuOverflow
  /**
   * Set computed overflow amount down to a child `MenuGroup`.
   * The `MenuGroup` should have `takeOverflow` prop set as `true` accordingly.
   */
  setDownOverflow?: boolean
  /**
   * Can be a MenuButton, a button element, or a React component. It also can be a render function that returns one.
   * If a React component is provided, it needs to implement the following contracts:
   * Accepts a ref prop that is forwarded to an element to which menu will be positioned. The element should be able to receive focus.
   * Accepts onClick and onKeyDown event props.
   */
  customMenuButton?: RenderProp<MenuButtonModifiers, React.ReactElement>
  /**
   * Button properties are spread to the root DOM element of the Button props.
   */
  buttonProps?: ButtonDefaultProps & ButtonKindProps & { activeClass?: string }
  /**
   * Properties of this object are spread to the root DOM element containing the menu.
   */
  containerProps?: Omit<React.HTMLAttributes<HTMLElement>, 'className'>
  /**
   * Specify bounding box padding in pixels. Use a syntax similar to the CSS
   * `padding` property but sizing units are discarded.
   * @example '10', '5 10', '1 2 4', or '2 5 3 1'
   */
  boundingBoxPadding?: string
  /**
   * Set the behaviour of menu and any of its descendent submenus when window is scrolling:
   * - 'initial' The window scroll event is ignored and has no effect on menu.
   * - 'auto' Menu will reposition itself based on the value of `position` prop when window is scrolling.
   * - 'close' menu will be closed when window is scrolled.
   * @default 'initial'
   */
  viewScroll?: MenuViewScroll
  /**
   * - If `true`, menu is rendered as a direct child of `document.body`,
   * - or you can specify a target element in the DOM as menu container.
   *
   * Portal allows menu to visually “break out” of its container. Typical use cases may include:
   * - An ancestor container is positioned and CSS `overflow` is set to a value other than `visible`.
   * - You have a DOM structure that creates a complex hierarchy of stacking contexts,
   * and menu is overlapped regardless of `z-index` value.
   */
  portal?:
  | boolean
  | {
    /**
     * A DOM node under which menu will be rendered.
     */
    target?: Element | null
    /**
     * When `target` is null, setting this value `true` prevents menu from rendering into the DOM hierarchy of its parent component.
     */
    stablePosition?: boolean
  }
  /**
   * Specify when menu is repositioned:
   * - 'initial' Don't automatically reposition menu. Set to this value when you want
   * to explicitly reposition menu using the `repositionFlag` prop.
   * - 'auto' Reposition menu whenever its size has changed, using the `ResizeObserver` API.
   * @default 'auto'
   */
  reposition?: MenuReposition
  /**
   * Use this prop to explicitly reposition menu. Whenever the prop has a new value,
   * menu position will be recalculated and updated.
   * You might use a counter and increase it every time.
   *
   * *Warning: don't update this prop in rapid succession,
   * which is inefficient and might cause infinite rendering of component.
   * E.g., don't change the value of this prop in `window` scroll event.*
   */
  repositionFlag?: number | string
  /**
   * Set a delay in `ms` before opening a submenu when mouse moves over it.
   * @default 300
   */
  submenuOpenDelay?: number
  /**
   * Set a delay in `ms` before closing a submenu when it's open and mouse is
   * moving over other items in the parent menu list.
   * @default 150
   */
  submenuCloseDelay?: number
  /**
   * Set a CSS `class` on the container element of menu for theming purpose.
   */
  theming?: string
  /**
   * Event fired when descendent menu items are clicked.
   */
  onItemClick?: EventHandler<ClickEvent>
  /**
   * Menu component ref which can be used to programmatically open or close menu.
   */
  instanceRef?: React.Ref<MenuInstance>
  /**
   * Event fired after menu is open or closed.
   */
  onMenuChange?: EventHandler<MenuChangeEvent>
}

const MenuButton =
  ({ customMenuButton, buttonProps }: any) =>
    ({ open }: any) => {
      if (customMenuButton) return customMenuButton({ open })
      if (!buttonProps) return null

      const { activeClass = '', ...rest } = buttonProps || {}
      return <Button className={open ? activeClass : ''} {...rest} />
    }

export const HeaderChild = ({ item }: any) => (
  <React.Fragment key={item.title}>
    <MenuHeader>{item.title}</MenuHeader>
    <Box mb="4px">
      <MenuDivider />
    </Box>
  </React.Fragment>
)

const ListItemContent = ({ item }: any) => (
  <Text variant="button" sx={{ display: 'flex', alignItems: 'center', color: item.color || 'unset' }}>
    {item.icon} {item.title}
  </Text>
)

const ListChild = ({ item }: any) => (
  <React.Fragment key={item.title}>
    <MenuItem {...item.itemProps}>
      <ListItemContent item={item} />
    </MenuItem>
    {item.divider && (
      <Box my="4px">
        <MenuDivider />
      </Box>
    )}
  </React.Fragment>
)

const renderListItem = (item: any) => {
  if (!item.hide) {
    if (!item.type) {
      return <ListChild key={item.title} item={item} />
    } else {
      return <HeaderChild key={item.title} item={item} />
    }
  } else {
    return null
  }
}

const BasicMenu = ({
  list,
  customMenuButton,
  buttonProps,
  ...props
}: IBasicMenuProps) => (
  <Menu
    unmountOnClose
    {...props}
    menuButton={MenuButton({ buttonProps, customMenuButton })}
  >
    {list?.map(renderListItem)}
  </Menu>
)

export default BasicMenu
