const BASE_URL = '/api-keys';

const getPaginationQueryParams = (pagination?: Record<string, string|null> | undefined): string => {
  return Object.entries(pagination || {}).map((entry) => `&${entry[0]}=${entry[1]}`).join('')
}

const API_KEYS_URLS = {
  GET_API_KEYS: `${BASE_URL}`, // GET by ownerId
  GET_API_KEY: `${BASE_URL}/:id`, // GET by id
  GET_API_KEY_ORGANIZATION_DEVICES: `${BASE_URL}/:id/organizations/:organizationId`, // GET by id
  CREATE_API_KEY: `${BASE_URL}`, // POST params CreateApiKey
  UPDATE_API_KEY: `${BASE_URL}/:id`, // PUT params UpdatedApiKey
  SHARE_API_KEY: `${BASE_URL}/:id/share-with-users`, // POST params userIds
  REVOKE_API_KEY: `${BASE_URL}/:id/revoke-from-users`, // POST params userIds
  ENABLE_ORGANIZATIONS: `${BASE_URL}/:id/organizations/:organizationId/enable`, // POST params organizationIds
  DISABLE_ORGANIZATIONS: `${BASE_URL}/:id/organizations/:organizationId/disable`, // POST params organizationIds
  DELETE_API_KEY: `${BASE_URL}/:id`, // DELETE by id
  DISABLE_API_KEY: `${BASE_URL}/:id/disable`, // POST by id
  ENABLE_API_KEY: `${BASE_URL}/:id/enable`, // POST by id
  REFRESH_API_KEY: `${BASE_URL}/:id/refresh`, // POST by id
  GET_ORGANIZATIONS_BY_API_KEY: `/organizations/get-by-api-key`, // POST by apiKeyValue
  GET_ORGANIZATIONS_NETWORKS: (workspaceId: string, organizationId: string, pagination?: Record<string, string> | undefined) => `/organizations/${organizationId}/networks?workspaceId=${workspaceId}${getPaginationQueryParams(pagination)}`,
  GET_ORGANIZATIONS_TAGS: (workspaceId: string, organizationId: string, pagination?: Record<string, string> | undefined) => `/organizations/${organizationId}/tags?workspaceId=${workspaceId}${getPaginationQueryParams(pagination)}`,
}

export default API_KEYS_URLS;
