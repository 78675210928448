import { Box, ThemeUIStyleObject } from 'theme-ui'

const SwitchPortIcon = ({
  size = 24,
  svgProps,
  sx,
  className = '',
  ...props
}: {
  as?: React.ElementType
  sx?: ThemeUIStyleObject
  className?: string
  size?: number
  svgProps?: any
}) => (
  <Box {...props} className={`svg-icon ${className}`} sx={{ ...{
    display: 'inline-block',
    flexShrink: 0,
  }, ...sx }}>
    <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 17.3792V45H48V10H53.9675C57.3014 10 60 13.3012 60 17.3792Z" fill="url(#paint0_linear_15_509)"/>
      <path d="M53 10V24.1958V27.9813C53 27.9813 47.9144 28.3126 47.9144 26.1832C47.9144 24.0539 45.741 24.1958 45.741 24.1958C45.741 24.1958 25.6967 24.1958 23.5443 24.1958C21.392 24.1958 21.5392 26.3252 21.5392 26.3252C17.1096 26.3252 14.4296 26.3252 10 26.3252V17.6657C10 13.4589 13.326 10 18.2423 10H53Z" fill="url(#paint1_linear_15_509)"/>
      <path d="M22 25V60H16.0327C12.6988 60 10 56.6988 10 52.6208V25H22Z" fill="url(#paint2_linear_15_509)"/>
      <path d="M48.2372 37.4361C48.2372 37.4361 50.573 35.3573 54.8767 32.4151C59.1801 29.4729 60 23 60 23V52.4731C60 56.6326 56.3358 60 51.8089 60C51.8089 60 18.1941 60 15.53 60C12.8659 60 8.3573 56.149 10.6115 52.0298C12.8658 47.9108 21.3499 41.6483 21.3499 43.286C21.3499 44.9237 22.0747 45.0275 23.4084 45.0275C24.7421 45.0275 27.7027 45.0275 27.7027 45.0275C27.7027 45.0275 27.7027 49.0104 27.7027 49.786C27.7027 50.5613 28.0872 50.4824 28.0872 50.4824H40.5747C40.5747 50.4824 41.0373 50.3273 41.0373 49.786C41.0373 49.2444 41.0373 45.0275 41.0373 45.0275C41.0373 45.0275 43.5812 45.0275 44.7375 45.0275C45.8938 45.0275 48.2372 45.2712 48.2372 43.286C48.2372 41.3009 48.2372 37.4361 48.2372 37.4361Z" fill="url(#paint3_linear_15_509)"/>
      <defs>
      <linearGradient id="paint0_linear_15_509" x1="48.972" y1="10.5837" x2="57.8443" y2="32.8383" gradientUnits="userSpaceOnUse">
      <stop stopColor="#736DEB"/>
      <stop offset="0.72686" stopColor="#746EEC"/>
      <stop offset="1" stopColor="#7671EE"/>
      </linearGradient>
      <linearGradient id="paint1_linear_15_509" x1="-24.5774" y1="66.9758" x2="62.604" y2="43.3418" gradientUnits="userSpaceOnUse">
      <stop stopColor="#4944C2"/>
      <stop offset="1" stopColor="#7671EE"/>
      </linearGradient>
      <linearGradient id="paint2_linear_15_509" x1="6.3844" y1="18.2402" x2="23.0684" y2="69.187" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7671EE"/>
      <stop offset="0.202574" stopColor="#625DDA"/>
      <stop offset="1" stopColor="#434188"/>
      </linearGradient>
      <linearGradient id="paint3_linear_15_509" x1="91.969" y1="28.296" x2="10.0436" y2="59.2285" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7671EE"/>
      <stop offset="0.774324" stopColor="#4D499F"/>
      <stop offset="1" stopColor="#434188"/>
      </linearGradient>
      </defs>
    </svg>
  </Box>
)

export default SwitchPortIcon
