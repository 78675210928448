import { APIKeyScopeType, Vendors } from '../../../api/enums'
import { Events, GroupSecurity, Security } from '@carbon/icons-react'

export const vendorOptions = [{ value: Vendors.MERAKI, label: 'Meraki' }]

export const permissionOptions = [
  { value: APIKeyScopeType.PRIVATE,
    label: 'Private key',
    icon: <Security />,
    tooltipText: 'Only you have permission to use this API key.'
  },
  {
    value: APIKeyScopeType.SHARED,
    label: 'Shared key',
    icon: <GroupSecurity />,
    tooltipText: `Multiple users in your workspace can use this key for automations, but they can't view or change it's settings.`
  },
  {
    value: APIKeyScopeType.WORKSPACE,
    label: 'Workspace key',
    icon: <Events />,
    tooltipText: `Everyone in your workspace can use this key, but they can't view or change it's settings.`
  },
]

export const ApiPermissionMap = {
  [APIKeyScopeType.PRIVATE]: 'Private',
  [APIKeyScopeType.SHARED]: 'Shared',
  [APIKeyScopeType.WORKSPACE]: 'Workspace',
}

export const organizationsList = [
  { value: '1', label: 'Organization name 1' },
  { value: '2', label: 'Organization name 2' },
  { value: '3', label: 'Organization name 3' },
  { value: '4', label: 'Organization name 4' },
  { value: '5', label: 'Organization name 5' },
  { value: '6', label: 'Organization name 6' },
  { value: '7', label: 'Organization name 7' },
  { value: '8', label: 'Organization name 8' },
  { value: '9', label: 'Organization name 9' },
  { value: '10', label: 'Organization name 10' },
  { value: '11', label: 'Organization name 11' },
]
