import { useEffect } from 'react'
import { debounce } from '../utils/general.utils'

export const useVhFix = (delay = 200) => {
  useEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    const debouncedHandleResize = debounce(onResize, delay)

    window.addEventListener('resize', debouncedHandleResize)

    onResize()

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [delay])
}
