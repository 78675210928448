import { Navigate } from 'react-router-dom'
import { useUserMe } from '../api/users/usersHooks'
import { OnboardingStatus } from '../api/enums'
import { PROFILE_SETUP } from '../constants/routes.constants'
import { Loader } from '@boundlessdigital/bng-shared-components'

const ProfileCheckProvider = ({ children }) => {
  const { data, isInitialLoading } = useUserMe()

  if (!data && isInitialLoading) {
    return <Loader withOverlay />
  }
  if (
    data?.onboarding?.status === OnboardingStatus.IN_PROGRESS ||
    data?.onboarding?.status === OnboardingStatus.NOT_STARTED
  ) {
    return <Navigate to={PROFILE_SETUP} replace />
  }

  return children
}

export default ProfileCheckProvider
