import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useTrainings } from '../api/trainings/trainings.hooks';
import { useUserMe } from '../api/users/usersHooks';
import { routesConfig } from '../config/routes.config';
import useHelpCrunch from '../hooks/helpcrunch.hook';
import NotFound from '../components/not-found/not-found.component';
import useUserPermissions from '../hooks/user-permissions.hook';

const RootRoutes = () => {
  useTrainings();
  const { data } = useUserMe();
  const { initHelpCrunch, showWidget, hideWidget } = useHelpCrunch();

  useEffect(() => {
    if (data?.email) {
      initHelpCrunch(import.meta.env.VITE_HELP_CRUNCH_APP_ID, {
        user_id: data.userId,
        email: data.email,
        name: data.name,
      });
  
      showWidget();
    }

    return () => {
      hideWidget();
    };
  }, [data, initHelpCrunch, showWidget, hideWidget]);

  const renderConfiguration = (configuration, backPath) => {
    const { wrapper: {
      path,
      component: Component,
      index
    }, children } = configuration

    return (
      <Route key={path || 'index'} path={path} element={<Component backPath={backPath} {...configuration} />} index={index}>
        {children && children.map(({
          path: childPath,
          index: childIndex,
          component: ChildComponent
        }) => {
          return <Route key={childPath || 'index'} path={childPath} element={<ChildComponent />} index={childIndex}/>
        })}
      </Route>
    );
  };

  const renderRoutes = (routes) => {
    const routess = routes.map((route, ix) => {
      const { path, component: Component, children, index, configuration } = route
      const { permissions } = useUserPermissions(data)

      const userIsAllowed = Object.keys(permissions).includes(route.value)

      if (!userIsAllowed) return null;

      return (
        <React.Fragment key={`${path}-${ix}`}>
          <Route path={path} element={<Component />} index={index} />
          {children?.length && renderRoutes(children)}
          {configuration && renderConfiguration(configuration, path)}
        </React.Fragment>
      );
    });

    return routess
  };

  return (
    <Routes>
      {renderRoutes(routesConfig)}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RootRoutes;
