/** @jsxImportSource theme-ui */
import { useMemo, useState } from 'react'
import { Loader } from '@boundlessdigital/bng-shared-components'
import ProductSelectTile from '../components/product-select-tile.component'
import { WizardPage } from '../profile-setup.constants'
import { useAuthUser } from '@frontegg/react'
import { useSetOnboardingProducts } from '../../../api/users/onboardingHooks'
import { useUpdateUserCache } from '../../../api/users/usersHooks'
import Tile from '../../../components/Tile/Tile'
import ButtonGroup from '../../../components/Button/ButtonGroup'
import Button from '../../../components/Button/Button'
import { productsConfig } from '../../../config/products.config'
import useUserPermissions from '../../../hooks/user-permissions.hook'


const ProductsStep = ({ setPage }) => {
  const [product, setProduct] = useState<any>(null)
  const { id, email } = useAuthUser()
  const setOnboardingProductsMutation = useSetOnboardingProducts(id)
  const { update: updateUserCache } = useUpdateUserCache()
  const {accessibleProducts} = useUserPermissions({email})

  const filteredProducts = useMemo(() => {
      return productsConfig.filter(product => accessibleProducts.includes(product.value))
  }, [email]);

  const onSubmit = async () => {
    if (product?.title) {
      const result = await setOnboardingProductsMutation.mutateAsync({
        productName: product.value,
      })
      if (result.data.product) {
        updateUserCache(id, { onboarding: result.data })
      }
      setPage(WizardPage.ApiKey)
    }
  }

  return (
    <Tile
      wrapperSx={{ mt: '24px', position: 'relative' }}
      contentSx={{ '& > .select-tile:not(:last-child)': { mb: '12px' } }}
      title="Products"
      footer={
        <ButtonGroup>
          <Button
            onClick={onSubmit}
            disabled={!product?.title}
            size="md"
            kind="primary"
          >
            Continue
          </Button>
        </ButtonGroup>
      }
    >
      <Loader show={setOnboardingProductsMutation.isLoading} withOverlay />
      {filteredProducts.map((item) => (
        <ProductSelectTile
          key={item.title}
          icon={item.icon}
          label={item.title}
          caption={item.description}
          onClick={() => {
            setProduct(item)
          }}
          isSelected={product?.title === item.title}
        />
      ))}
    </Tile>
  )
}

export default ProductsStep
