import { useCallback } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { ErrorBoundaryFallback } from './error-boundary.fallback'
import { captureSentryException } from '../../utils/errors.utils'
import { ErrorBoundaryProps } from './error-boundary.types'

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const errorHandler = useCallback((error: Error, { componentStack }: { componentStack: string }) => {
    captureSentryException({ error, componentStack })
  }, [])
  
  return (
    <ReactErrorBoundary FallbackComponent={ ErrorBoundaryFallback } onError={ errorHandler }>
      { children }
    </ReactErrorBoundary>
  )
}
