/** @jsxImportSource theme-ui */
import { Box, Text } from 'theme-ui'
import {
    ReactComponent as BoundlessBrand,
} from '../../../assets/images/boundless-brand-sidepanel.svg'
import useActionModalStore from '../../../store/useActionModalStore'
import { useContent, useSelectedProduct } from '../profile-setup.hooks'
import { VideoPreviewBox } from './video-preview.component'
import { handleOpenVideoModal } from '../profile-setup.utils'

const SidePanel = ({ page }) => {
  const product = useSelectedProduct();
  const { title, description, resource } = useContent(page, product);
  const openModal = useActionModalStore((state) => state.openModal);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'center',
      maxWidth: '648px',
      px: ['36px', '36px', '36px', '86px'],
      height: '100%',
      maxHeight: '100%',
      bg: '#0D1B3F',
      overflow: 'auto',
      color: 'rgba(255, 255, 255, 0.87)'
    }}>
      <Box sx={{
        minHeight: 0,
        py: '50px',
      }}>
        <Box sx={{ mb: '40px' }}>
          <BoundlessBrand width={151} height={30} />
        </Box>
        <Text as="p" variant="displayLarge" mb="20px">{title}</Text>
        <Text as="p" variant="body">{description}</Text>

        {resource && (
          <>
            <Text as="p" variant="subheading" sx={{ mt: '40px' }}>
              Resources
            </Text>

            <VideoPreviewBox
              title={resource.title}
              description={resource.description}
              duration={resource.duration}
              imageSrc={resource.imageSrc}
              imageAlt={resource.imageAlt}
              onClick={() => handleOpenVideoModal(openModal, resource?.videoSrc, resource?.videoTitle)}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default SidePanel
