import { useMemo, useState } from 'react'

type SortProps = {
  key?: string
  order?: ORDER_TYPE
}

export enum ORDER_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface Sort {
  sortQuery: any
  sortProps: SortProps
  setSortProps: (sortProps: SortProps) => void
  handleSort: (key: any, order: any) => void
}

const useSort = (
  {
    initialKey,
    initialOrder,
    queryKey = 'ordering',
  }: {
    initialKey?: string
    initialOrder?: ORDER_TYPE
    queryKey?: string
  } = { queryKey: 'ordering' }
): Sort => {
  const [sortProps, setSortProps] = useState<SortProps>({
    key: initialKey,
    order: initialOrder,
  })

  const sortQuery = useMemo(() => {
    if (sortProps?.key) {
      const sortKey = sortProps.key
      return {
        [queryKey]: `${sortProps.order === ORDER_TYPE.DESC ? '-' : ''
          }${sortKey}`,
      }
    }
    return {}
  }, [sortProps, queryKey])

  const handleSort = (key: any, order: any) => {
    if (order) {
      setSortProps({ key, order })
    } else {
      setSortProps({})
    }
  }

  return {
    sortQuery,
    sortProps,
    setSortProps,
    handleSort,
  }
}

export default useSort
