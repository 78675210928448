import * as PATHS from '../../constants/routes.constants'

import { ConfigurationBackupIcon } from '@boundlessdigital/bng-shared-components'
import { Product } from '../../config/config.types'
import { ProductEnum } from '../../types/general.types'
import { asyncComponentLoader } from '../../components/component-loader/component-loader.component'

export const configurationBackupConfig: Product = {
	title: 'Configuration Backup',
	description: 'Instantly restore network configurations from daily or custom backups.',
	value: ProductEnum.CONFIGURATION_BACKUP,
	icon: {
		title: 'Configuration Backup',
		component: ConfigurationBackupIcon,
		description: 'Improve the management of your Cisco Meraki user base and get better control.',
	},
	path: PATHS.CONFIGURATION_BACKUP,
	component: asyncComponentLoader(() => import(/* webpackChunkName: "configuration-backup" */ './configuration-backup.component')),
	isDevelopment: true,
	isLocked: false,
	configuration: {
		wrapper: {
			title: 'Configuration',
			description: 'Manage user settings, organizational structures, and system configurations.',
			path: PATHS.CONFIGURATION_BACKUP_CONFIGURATION,
			component: asyncComponentLoader(() => import(/* webpackChunkName: "configuration-backup/configuration" */ '../../pages/configuration/configuration.component')),
		},
		children: [{
			title: 'Rollback',
			index: true,
			description: 'Track and manage your activity. Easily undo and redo actions and review user operations.',
			path: 'rollback',
			component: asyncComponentLoader(() => import(/* webpackChunkName: "configuration-backup/configuration/rollback" */ '../../pages/configuration/components/rollback.component')),
		}]
	},
	children: [
		{
			path: PATHS.BACKUP_OVERVIEW,
			component: asyncComponentLoader(() => import(/* webpackChunkName: "configuration-backup/network-snapshots" */ './network-snapshots.component')),
		}
	]
}