import { Content } from './profile-setup.types'

export const DEFAULT_CONTENT: Content = {
    title: 'Create a workspace',
    description: 'Simpler, faster and more advanced IT operations are a few clicks away. Start by naming your workspace and choosing a data region for optimal efficiency.',
}

export enum WizardPage {
    Workspace = 'Workspace',
    Products = 'Products',
    ApiKey = 'API key',
    Connect = 'Connection',
    ProcessingData = 'Processing Data'
}
