import { useEffect, useState } from 'react';

const useFronteggLoading = () => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class' && mutation.target instanceof Element) {
          const target = mutation.target;
          setLoading(target.classList.contains('frontegg-loading'));
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  return isLoading;
};

export default useFronteggLoading;
