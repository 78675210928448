import { Tooltip } from 'react-tooltip'
import { ToastContainer, cssTransition } from 'react-toastify'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Loader } from '@boundlessdigital/bng-shared-components'
import Header from './components/Header/header.component'
import RootRoutes from './pages/root.routes'
import ActionModal from './components/ActionModal/ActionModal'
import { Close } from '@carbon/icons-react'
import AuthProvider from './providers/auth.provider'
import { FronteggProvider } from '@frontegg/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ProfileCheckProvider from './providers/profile-check.provider'
import { Route, Routes } from 'react-router-dom'
import { PROFILE_SETUP } from './constants/routes.constants'
import ProfileSetupWrapper from './pages/profile-setup/profile-setup.component'
import theme from './config/theme'
import { ErrorBoundary } from './components/error-boundary/error-boundary.component'
import { useVhFix } from './hooks/vh-fix.hook'
import { ThemeUIProvider } from 'theme-ui'
import useFronteggLoading from './hooks/frontegg-loading.hook'
 
const Fade = cssTransition({
  enter: 'fade-in-right',
  exit: 'fade-out-right',
})

const urlQueryParams = new URLSearchParams(window.location.search);
const organization = urlQueryParams.get('organization');

const authContextOptions = {
  baseUrl: import.meta.env.VITE_FRONT_EGG_BASE_URL,
  clientId: import.meta.env.VITE_FRONT_EGG_CLIENT_ID,
  ...(organization && {
    tenantResolver: () => ({ tenant: organization })
  }),
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000, // (5 minutes)
    },
    mutations: {
      retry: false,
    },
  },
})

const ToastCloseButton = ({ closeToast }: any) => (
  <button
    onClick={closeToast}
    type="button"
    title="close notification"
    className="cds--actionable-notification__close-button"
  >
    <Close />
  </button>
)

function App() {
  const isFronteggLoading = useFronteggLoading()

  useVhFix()

  return (
    <ErrorBoundary>
      <ThemeUIProvider theme={theme}>
        <Loader show={isFronteggLoading} withOverlay={true} fixed />
        <FronteggProvider contextOptions={authContextOptions} hostedLoginBox={true}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Routes>
                <Route path={PROFILE_SETUP} element={<ProfileSetupWrapper />} />
                <Route
                  path="*"
                  element={
                    <ProfileCheckProvider>
                      <Header />
                      <ErrorBoundary>
                        <RootRoutes />
                      </ErrorBoundary>
                    </ProfileCheckProvider>
                  }
                />
              </Routes>
            </AuthProvider>
            <Tooltip id="basic" place="top" style={{ zIndex: 10000 }}/>
            <ActionModal />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              closeButton={ToastCloseButton}
              newestOnTop={true}
              transition={Fade}
              rtl={false}
              limit={5}
              draggable={false}
              theme="colored"
              icon={false}
              pauseOnFocusLoss
              pauseOnHover
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </FronteggProvider>
      </ThemeUIProvider>
    </ErrorBoundary>
  )
}

export default App
