import { toast } from 'react-toastify'
import { Notification } from '@boundlessdigital/bng-shared-components'

export type NotifyPropsType = {
  title: string
  message?: React.ReactNode | string
  description?: string
  kind?: 'highlight' | 'info' | 'success' | 'warning' | 'error'
  actionProps?: {
    label: string
    onClick?: () => void
  }
  preventClose?: boolean
}

const notify = (props: NotifyPropsType) => {
  const autoClose = props.preventClose ? false : 5000

  return toast(
    <Notification
      newestOnTop={false}
      hideCloseButton
      isToast
      {...props}
    />
  , {
    autoClose
  })
}

export default notify;
