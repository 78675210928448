import { Box } from "theme-ui";
import Tile from "../Tile/Tile";
import theme from "../../config/theme";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelectedProduct } from "../../pages/profile-setup/profile-setup.hooks";

const NotFound = () => {
  const navigate = useNavigate()
  const product = useSelectedProduct();

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Tile
        wrapperSx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '500px'
        }}
        title="Page not found"
        description={'Click below to navigate away.'}
        titleSx={{
          paddingBottom: 20,
        }}
        descriptionSx={{ color: (theme?.colors?.text as any).secondary, paddingBottom: 20 }}
      >
        <Button onClick={() => navigate(product.path as string)}>Go to {product.title}</Button>
      </Tile>
    </Box>
  );
}

export default NotFound
