import * as Sentry from '@sentry/browser'

import { ErrorReportArgs, SentryReport } from '../types/error-reporting.types'
import { getBuildDate } from './general.utils'

export const isReportingEnabled = window.location.hostname !== 'localhost';

export function captureSentryException({
  error,
  componentStack,
  pathname,
}: ErrorReportArgs) {
  const { href } = window.location;

  const extra: SentryReport = {
    componentStack,
    'window.location.href': href,
    'router.pathname': pathname || '-',
    buildDate: getBuildDate() || '-',
    NODE_ENV: import.meta.env.MODE || '-',
  }

  if (isReportingEnabled) {
    Sentry.captureException(error, { extra } as any);
  } else {
    console.error({ error, ...extra });
  }
}

export const exportErrorMessage = (error: any) => {
  const result: any = {
    title: error.message,
    message: '',
    kind: 'error',
  }
  if (error.response?.data?.message || error.response?.data?.error) {
    result.title = error.response.data.error || error.message
    if (error.response.data.message) {
      result.message =
        typeof error.response.data.message === 'string' ? (
          error.response.data.message
        ) : (
          <>
            {error.response.data.message.map((v: string) => (
              <p key={v}>
                {v}
                <br />
              </p>
            ))}
          </>
        )
    }
  }
  return result
}

const errorMap = {
  "E1005": "Your API key no longer have access to this organization: {{organizationName}}",
  "E1006": "Your API key is not valid for this operation. Please check its status.",
  "E1010": "An internal error occurred. Please try again; if issue persists, contact support.",
  "E1011": "{{adminName}} not found. Please refresh your API key and try again.",
  "E1013": "{{organizationName}} is already enabled. Please reload this page; if issue persists, contact support.",
  "E1015": "We could not enable this organization due to insufficient permissions. To enable an organization you must first obtain full access.",
  default: "An unknown error occurred. Please try again; if issue persists, contact support.",
}

export const getErrorMessage = (error) => {
  // TODO: Check if the following list will have merakiError coming from backend.
  //       If not, they need to be included in the errorMap

  // E1000 -> MERAKI_IP_RESTRICTION_ERROR
  // E1001 -> MERAKI_INVALID_API_KEY_ERROR
  // E1002 -> MERAKI_UNKNOWN_ERROR
  // E1003 -> MERAKI_ORGANIZATION_ADMIN_NOT_FOUND
  // E1004 -> ORGANIZATION_ADMIN_NOT_DISABLED
  // E1007 -> FAILED_TO_SAVE_ENABLED_ORGANIZATION
  // E1008 -> MERAKI_UNVERIFIED_USER_ERROR
  // E1009 -> ADMIN_ALREADY_EXISTS
  // E1012 -> NOT_POSSIBLE_TO_DISABLE_ADMIN
  // E1014 -> MERAKI_API_ACCESS_NOT_ENABLED_ERROR

  const { errorCode, merakiError, metadata } = error;

  if (merakiError) {
    return merakiError;
  }

  let message = errorMap[errorCode] || errorMap.default;

  const regex = /\{\{(\w+)\}\}/g;

  return message.replace(regex, (_, key) => metadata.hasOwnProperty(key) ? metadata[key] : "");
}