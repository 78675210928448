import APIAbstract from '../api.abstract'
import REGIONS_URLS from './regionsUrls'
import { AxiosResponse } from 'axios'
import { Region } from '../../constants/region.constants'

class RegionsAPI extends APIAbstract {
  public getRegions = (): Promise<AxiosResponse<Region[]>> => {
    return this.get(REGIONS_URLS.GET_REGIONS)
  }
}

export default new RegionsAPI()
