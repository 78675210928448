/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react'
import { BoxProps, ThemeUIStyleObject, Box } from 'theme-ui'

export interface IButtonGroup extends BoxProps {
  sx?: ThemeUIStyleObject
  gap?: string
}

const rootSx = (gap: string) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  '& > *:not(:last-child)': {
    marginRight: gap,
  },
})

const ButtonGroup: React.FC<PropsWithChildren<IButtonGroup>> = ({
  sx,
  gap = '12px',
  children,
  ...props
}) => (
  <Box sx={{ ...rootSx(gap), ...sx }} {...props}>
    {children}
  </Box>
)

export default ButtonGroup
