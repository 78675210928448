import * as PATHS from '../../constants/routes.constants'

import VisionPortal from "../../components/icons/vision-portal.icon"
import { Product } from '../../config/config.types'
import { ProductEnum } from '../../types/general.types'
import { asyncComponentLoader } from '../../components/component-loader/component-loader.component'

export const visionPortalAccessControlConfig: Product = {
  title: 'Vision Portal Access Control',
	value: ProductEnum.ESTEE_LAUDER_VISION_PORTAL,
  icon: {
    title: 'Vision Portal Access',
    component: VisionPortal,
    description: 'Secure user access to the Cisco Meraki Vision Portal.',
  },
  description: 'Local access restrictions for the Meraki Vision Portal',
  path: PATHS.ESTEE_LAUDER_VISION_PORTAL,
  component: asyncComponentLoader(() => import(/* webpackChunkName: "vision-portal-access-control" */ './vision-portal-access-control.component')),
  isDevelopment: false,
  isLocked: false,
}