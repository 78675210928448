/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react'
import {
  Box,
  BoxProps,
  ThemeUIStyleObject,
  Flex,
  Container,
  Text,
} from 'theme-ui'
import { Close } from '@carbon/icons-react'
import theme from '../../config/theme'

export interface ITileProps extends Omit<BoxProps, 'title'> {
  wrapperSx?: ThemeUIStyleObject
  title?: React.ReactElement | string
  description?: string
  descriptionSx?: ThemeUIStyleObject
  titleSx?: ThemeUIStyleObject
  contentSx?: ThemeUIStyleObject
  onClose?: () => void
  footer?: React.ReactElement | string
  rightMenu?: React.ReactElement | string
}

const Tile: React.FC<PropsWithChildren<ITileProps>> = ({
  wrapperSx,
  title,
  titleSx,
  description,
  descriptionSx,
  contentSx,
  footer,
  rightMenu,
  onClose,
  children,
  ...props
}) => {

  return (
    <Container
      sx={{
        width: '100%',
        backgroundColor: (theme?.colors?.surface as any).default.primary,
        boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)',
        padding: '16px',
        borderRadius: '8px',
        ...wrapperSx
      }}
      {...props}>
      {!!(title || onClose) && (
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Text as="p" variant="displaySmall" sx={{ ...titleSx }}>
            {title}
          </Text>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {rightMenu}
            {onClose && (
              <Box
                sx={{ cursor: 'pointer', marginLeft: '12px' }}
                onClick={onClose}
              >
                <Close size={24} />
              </Box>
            )}
          </Box>
        </Flex>
      )}
      {description && (
        <Text as="p" sx={{ mt: '4px', ...descriptionSx, ...theme?.text?.body }}>
          {description}
        </Text>
      )}
      {children && <Box sx={{ pt: '16px', ...contentSx }}>{children}</Box>}
      {footer && (
        <Box pt="16px" mt="4px">
          {footer}
        </Box>
      )}
    </Container>
  )
}


export default Tile
