import { Box, Container, Flex, Text } from 'theme-ui'
import ButtonGroup from '../../../components/Button/ButtonGroup';
import Button from '../../../components/Button/Button';
import useActionModalStore from '../../../store/useActionModalStore';
import DataTable from '../../../components/DataTable/DataTable';
import { useCallback, useMemo } from 'react';
import theme from '../../../config/theme';
import { getErrorBody } from '../profile-setup.utils';
import { getErrorMessage } from '../../../utils/errors.utils';

interface MultiErrorModalProps {
  errors: any;
}

const MultiErrorModal = ({ errors }: MultiErrorModalProps) => {
  const closeModal = useActionModalStore(({closeModal}) => closeModal)

  const tableData = useMemo(() => {
    const sortedErrors = (errors || [])
      .sort((a, b) => {
        const dateA = new Date(a.metadata.date).getTime() || 0;
        const dateB = new Date(b.metadata.date).getTime() || 0;

        return dateB - dateA;
      })
      .map((error, index) => {
        return {
          id: `error-${index}`,
          record: {
            id: `error-${index}`,
            ...error,
          },
          expandableComponent: (
            <Box px="16px">{getErrorBody(error)}</Box>
          )
        }
      });
  
    return sortedErrors;
  }, [errors]);
  
  const renderCell = useCallback((cell, {record}) => {
    const cellProps = {
      variant: 'footnote',
      color: (theme?.colors?.text as any).critical
    }

    switch (cell.info.header) {
      case 'error':
        return <Text {...cellProps}>{getErrorMessage(record)}</Text>
      default:
        return <Text {...cellProps}>{cell.value}</Text>
    }
  }, [errors]);

  return (
    <Container>
      <Text as="p" variant="body" mb="24px">We have received the following errors from Meraki when trying to verify your API key:</Text>
      <Flex sx={{ flexDirection: 'column', gap: '12px' }}>
        <DataTable
          headerData={[{
            header: 'Error',
            key: 'error',
          }]}
          data={tableData}
          renderCell={renderCell}
          expandable
          noHeader
          withBorders
        />
      </Flex>
      <ButtonGroup mt="24px">
        <Button onClick={closeModal} size="md">
          Got it
        </Button>
      </ButtonGroup>
    </Container>
  )
}

export default MultiErrorModal
