const BASE_URL = '/trainings';

const getPaginationQueryParams = (pagination?: Record<string, string> | undefined): string => {
  return Object.entries(pagination || {}).map((entry) => `&${entry[0]}=${entry[1]}`).join('')
}

const TRAININGS_URLS = {
  BASE: `${BASE_URL}`,
  GET_ALL: (workspaceId: string, pagination?: Record<string, string> | undefined) => `${BASE_URL}?workspaceId=${workspaceId}${getPaginationQueryParams(pagination)}`,
}

export default TRAININGS_URLS;
