import { Theme } from 'theme-ui'

const theme: Theme = {
  breakpoints: ['48em', '64em', '80em'],
  styles: {
    hr: {
      color: 'border.secondary',
    },
  },
  text: {
    heading: {
      fontSize: [16, 17, 18],
      lineHeight: ['24px', '26px', '28px'],
      fontWeight: 600,
    },
    subheading: {
      fontSize: [12, 13, 14],
      lineHeight: ['16px', '18px', '20px'],
      fontWeight: 600,
    },
    body: {
      fontSize: [14, 15, 16],
      lineHeight: ['20px', '22px', '24px'],
    },
    button: {
      fontSize: [14, 15, 16],
      fontWeight: 500,
    },
    caption: {
      fontSize: [14, 15, 16],
      lineHeight: ['16px', '18px', '20px'],
      fontWeight: 400,
      color: 'text.secondary',
    },
    footnote: {
      fontSize: [12, 13, 14],
      lineHeight: ['14px', '15px', '16px'],
    },
    breadcrumb: {
      fontSize: [14, 15, 16],
      lineHeight: ['16px', '18px', '20px'],
    },
    displayXLarge: {
      fontSize: [38, 39, 40],
      lineHeight: ['44px', '46px', '48px'],
      fontWeight: 600,
      fontFamily: `'Overpass', sans-serif`,
    },
    displayLarge: {
      fontFamily: `'Overpass', sans-serif`,
      fontSize: [26, 27, 28],
      lineHeight: ['32px', '34px', '36px'],
      fontWeight: 600,
    },
    displayMedium: {
      fontFamily: `'Overpass', sans-serif`,
      fontSize: [24, 25, 26],
      lineHeight: ['32px', '34px', '36px'],
      fontWeight: 400,
    },
    displaySmall: {
      fontFamily: `'Overpass', sans-serif`,
      fontSize: [20, 21, 22],
      lineHeight: ['28px', '30px', '32px'],
      fontWeight: 400,
    },
  },
  forms: {
    label: {
      color: 'text.secondary',
      fontSize: [12, 13, 14],
      fontWeight: 400,
      lineHeight: ['14px', '15px', '16px'],
      pb: '8px',
    },
    input: {
      borderColor: 'border.secondary',
      background: 'text.onColor',
      color: 'text.primary',
      borderRadius: '4px',
      px: '16px',
      fontSize: [14, 15, 16],
      '& + svg': {
        color: 'text.secondary',
      },
      '&::placeholder': {
        color: 'text.secondary',
      },
      '&:focus, &:active': {
        outlineColor: 'interactive.default.primary',
      },
      '&.is-error': {
        outlineStyle: 'auto',
        outlineWidth: '2px',
        outlineColor: 'border.critical',
        pr: '32px',
        '& + svg': {
          color: 'border.critical',
        },
      },
      '&.is-warning': {
        pr: '32px',
        '& + svg': {
          color: '#F1C21B',
        },
      },
      '&[disabled]': {
        color: 'text.depressed',
        cursor: 'auto',
        '&:hover': {
          background: 'text.onColor',
        },
        '&::placeholder': {
          color: 'text.depressed',
        },
      }
    },
  },
  colors: {
    primary: '#202223',
    secondary: '#6D7175',
    text: {
      primary: '#202223',
      secondary: '#6d7175',
      depressed: '#8c9196',
      critical: '#d72c0d',
      warning: '#c7a015',
      success: '#008060',
      onColor: '#ffffff',
      disabled: '#8c9196',
    },
    background: {
      primary: '#f6f6f7',
      hovered: '#f1f2f3',
      depressed: '#edeeef',
      disabled: '#edeeef',
    },
    actions: {
      primary: {
        primary: '#20366f',
        hovered: '#1a2952',
        pressed: '#0b1a3b',
        depressed: '#0f0d32',
        disabled: '#f1f1f1',
      },
      secondary: {
        primary: '#ffffff',
        hovered: '#f6f6f7',
        pressed: '#c7dbf5',
        depressed: '#253858',
        disabled: '#f1f1f1',
      },
      critical: {
        primary: '#d82c0d',
        hovered: '#bc2200',
        depressed: '#6c0f00',
        pressed: '#a21b00',
        disabled: '#f1f1f1',
      },
    },
    interactive: {
      default: {
        primary: '#0078d4',
        hovered: '#005a9e',
        depressed: '#004578',
        disabled: '#bdc1cc',
      },
      critical: {
        primary: '#d82c0d',
        hovered: '#cd290c',
        depressed: '#670f03',
        disabled: '#fd938d',
      },
    },
    border: {
      primary: '#8c9196',
      secondary: '#c9cccf',
      hovered: '#999ea4',
      depressed: '#575959',
      interactive: '#0078d4',
      critical: '#d82c0d',
      warning: '#b98900',
      success: '#007f5f',
    },
    brand: {
      primary: '#1146ae',
      secondary: '#173773',
      tertiary: '#132a54',
    },
    products: {
      accessManagement: {
        primary: '#2b71fd',
        secondary: '#153a85',
        gradient: 'linear-gradient(270deg, #153a85 0.62%, #2b71fd 97.39%)',
      },
      networkOperations: {
        primary: '#7671ee',
        secondary: '#4944c2',
        gradient: 'linear-gradient(90deg, #7671ee 0%, #4944c2 100%)',
      },
      advancedOperations: {
        primary: '#b21d94',
        secondary: '#751765',
        gradient: 'linear-gradient(90deg, #b21d94 0%, #751765 100%)',
      },
      connectivity: {
        primary: '#03d1a5',
        secondary: '#20987a',
        gradient: 'linear-gradient(90.41deg, #03d1a5 0.35%, #20987a 99.65%)',
      },
      monitoring: {
        primary: '#0cdcc9',
        secondary: '#0daba6',
        gradient: 'linear-gradient(90deg, #0cdcc9 0%, #0daba6 100%)',
      },
      procurement: {
        primary: '#feba2b',
        secondary: '#c18e1d',
        gradient: 'linear-gradient(270deg, #c18e1d 0.62%, #feba2b 97.39%)',
      },
    },
    surface: {
      default: {
        primary: '#ffffff',
        secondary: '#fafbfb',
        hovered: '#f6f6f7',
        pressed: '#f1f2f3',
        depressed: '#edeeef',
        disabled: '#fafbfb',
      },
      neutral: {
        primary: '#E4E5E7',
        secondary: '#F6F6F7',
        pressed: '#C9CCD0',
        hovered: '#EFEFEF',
      },
      selected: {
        primary: '#f2f7fe',
        hovered: '#edf4fe',
        pressed: '#e5effd',
      },
      critical: {
        primary: '#fed3d1',
        secondary: '#fff4f4',
        hovered: '#fff0f0',
        pressed: '#ffe9e8',
      },
      warning: {
        primary: '#ffd79d',
        secondary: '#fff5ea',
        hovered: '#fff2e2',
        pressed: '#ffebd3',
      },
      success: {
        primary: '#aee9d1',
        secondary: '#f1f8f5',
        hovered: '#ecf6f1',
        pressed: '#e2f1ea',
      },
    },
  },
}

export default theme
