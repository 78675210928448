import { FC, lazy, Suspense, useEffect, useState } from 'react'
import { sleep } from '../../utils/general.utils'
import { LoadComponent, LoaderDefaultOptions } from './component-loader.types'
import { Loader } from '@boundlessdigital/bng-shared-components'
import { AnyProps } from '../../types/general.types'

export const defaultLoaderOptions = {
  delay: 300,
  minimumLoading: 700,
}

function getDelayedFallback(Fallback: FC, delay: number) {
  return function DelayedFallback(props: AnyProps) {
    const [isDelayPassed, setIsDelayPassed] = useState(false);

    useEffect(() => {
      const timerId = setTimeout(() => setIsDelayPassed(true), delay);
      return () => clearTimeout(timerId);
    }, []);

    return isDelayPassed ? <Fallback {...props} /> : null;
  };
}

const getLazyComponent = (loadComponent: LoadComponent, loaderOptions: LoaderDefaultOptions) => {
  return lazy(() => {
    const start = performance.now();
    return loadComponent().then((moduleExports) => {
      const end = performance.now();
      const diff = end - start;
      const { delay, minimumLoading } = loaderOptions;

      if (diff < delay || (diff > delay && diff > delay + minimumLoading)) {
        return moduleExports;
      }

      return sleep(delay + minimumLoading - diff).then(() => moduleExports);
    });
  });
};


function asyncComponentLoader(
  loadComponent: LoadComponent,
  additionalProps: AnyProps = {},
  loaderOptions: LoaderDefaultOptions = defaultLoaderOptions,
  FallbackWaiting: FC = Loader
) {
  const Fallback = loaderOptions.delay
    ? getDelayedFallback(FallbackWaiting, loaderOptions.delay)
    : FallbackWaiting;

  const LazyComponent = getLazyComponent(loadComponent, loaderOptions);

  return function AsyncComponent(props: AnyProps) {
    return (
      <Suspense fallback={<Fallback />}>
        <LazyComponent {...additionalProps} {...props} />
      </Suspense>
    );
  };
}

export { asyncComponentLoader };