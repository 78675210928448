export const DATE_FORMAT = {
  DEFAULT: 'MMM dd, yyyy',                            // Example: Jan 15, 2023
  DEFAULT_WITH_TIME: 'MMM dd, yyyy \'at\' HH:mm',     // Example: Jun 30, 2023 at 23.43
  MONTH_YEAR_SHORT: 'MMM, ’yy',                       // Example: Jan, ’21
  MONTH_YEAR_LONG: 'MMMM yyyy',                       // Example: January 2021
  ISO_DATE: 'yyyy-MM-dd',                             // Example: 2021-01-15
  AMERICAN_SHORT_DATE: 'MM.dd.yyyy',                  // Example: 01.15.2021
  AMERICAN_DATE_TIME_12_HOUR: 'MM-dd-yyyy | hh:mm a', // Example: 01-15-2021 | 03:30 PM
  AMERICAN_DATE_TIME_12_HOUR_SINGLE_DIGIT: 'MM-dd-yyyy | h:mm a', // Example: 01-15-2021 | 3:30 PM
};
