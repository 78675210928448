/** @jsxImportSource theme-ui */
import React, { useMemo } from 'react'
import { Box, CSSObject, Label, ThemeUIStyleObject, Text } from 'theme-ui'
import { ForwardRefProps } from 'carbon-components-react/typings/shared'
import cx from 'classnames'
import { ChevronDown, WarningAltFilled, WarningFilled } from '@carbon/icons-react'
import ReactSelect, { components, GroupBase } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'
import { Virtuoso } from 'react-virtuoso'
import Select from 'react-select/base'
import theme from '../../config/theme'
import OptionWithCheckbox from './OptionWithCheckbox'
import { Option } from '@boundlessdigital/bng-shared-components'

type OptionType = { [key: string]: any }

export interface ISelectFieldProps
  extends Omit<StateManagerProps, 'size' | 'components'> {
  sx?: ThemeUIStyleObject
  selectSx?: ThemeUIStyleObject
  label?: string | React.ReactElement
  selectClassName?: string
  size?: 'lg' | 'md' | 'sm' | number
  helperText?: string | React.ReactElement
  isError?: boolean
  isWarning?: boolean
  options?: OptionType[]
  disabled?: boolean
  width?: string | number
  creatable?: boolean
  components?: any
  useVirtualizedList?: any
  withCheckbox?: boolean
}

export const selectTheme = (
  theme: any,
  size: {
    size?: 'lg' | 'md' | 'sm' | number
  }) => {
  let controlHeight;

  if (typeof size === 'string') {
    controlHeight = selectSizes[size];
  } else {
    controlHeight = size;
  }

  return {
    ...theme,
    colors: {
      ...theme.colors,
      neutral20: '#c9cccf',
      primary: '#0078D4',
    },
    spacing: {
      ...theme.spacing,
      controlHeight: controlHeight,
      baseUnit: 8,
    },
  }
}

const rootSx: ThemeUIStyleObject = {}

const selectSizes = {
  lg: 48,
  md: 40,
  sm: 32,
}

const controlStyles: (props: {
  hoverChange?: boolean
  isDisabled?: boolean
}) => CSSObject = ({ hoverChange, isDisabled }) => ({
  ...(isDisabled
    ? {
      background: '#FFFFFF',
      borderColor: '#c9cccf',
    }
    : undefined),
  '&:hover': {
    background: hoverChange ? '#F6F6F7' : '#FFFFFF',
  },
})

const getOptionBackgroundColor = (state: any) => {
  if (state.isFocused) {
    return '#edf4fe'
  } else if (state.isActive) {
    return '#e5effd'
  } else if (state.isSelected) {
    return '#F2F7FE'
  } else {
    return 'transparent'
  }
}

export const styles: (
  props?: { 
    width?: number | string,
    border?: string,
    paddingLeft?: string
    maxHeight?: string
  }
) => any = (
  props
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    ...controlStyles({
      isDisabled: state.isDisabled,
      hoverChange: !state.isFocused && !state.menuIsOpen && !state.isDisabled,
    }),
    ...(props?.width ? { width: props.width} : {}),
    background: '#FFFFFF',
    ...(props?.paddingLeft ? {paddingLeft: props.paddingLeft} : {}),
    ...(props?.border ? {border: props.border} : {})
  }),
  valueContainer: (baseStyles: any, state: any) => ({
    ...baseStyles,
    paddingLeft: '14px',
    paddingRight: '14px',
    overflowY: 'scroll',
    ...(props?.maxHeight ? { maxHeight: props.maxHeight} : {}),
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    padding: '8px',
  }),
  menuPortal: (baseStyles: any) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
  menu: (base: any) => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    border: 0,
    borderRadius: 8,
    marginTop: '8px',
    marginBottom: '8px',
  }),
  placeholder: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: state.isDisabled ? '#8C9196' : '#202223',
  }),
  input: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: state.isDisabled ? '#8C9196' : '#202223',
    margin: 0
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    position: 'relative',
    borderRadius: 4,
    paddingTop: '0',
    paddingBottom: '0',
    height: 40,
    color: '#202223',
    background: getOptionBackgroundColor(state),
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    borderRadius: 1000,
    background: '#E0E0E0',
  }),
  singleValue: (baseStyles: any) => ({
    ...baseStyles,
    overflow: 'initial',
    cursor: 'pointer'
  }),
  multiValueLabel: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: 12,
    lineHeight: '16px',
    color: '#161616',
    padding: '4px 8px',
    paddingLeft: 8,
  }),
  multiValueRemove: (baseStyles: any, state: any) => {
    if (state.isDisabled) {
      return {
        display: 'none',
      }
    }
    return {
      ...baseStyles,
      color: '#161616',
      borderRadius: 20,
      cursor: 'pointer',
      '&:hover': {
        color: '#161616',
        background: '#efefef',
      },
    }
  },
  clearIndicator: (baseStyles: any) => ({
    ...baseStyles,
    padding: 0,
    paddingRight: 4,
    paddingLeft: 4,
  }),
  dropdownIndicator: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: state.isDisabled ? '#8C9196' : '#202223',
    padding: '8px',
    paddingLeft: '0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown style={{ marginRight: 4 }} />
    </components.DropdownIndicator>
  )
}

const VirtualizedMenuList = (props: any) => {
  const rows = props.children
  return rows.length > 0 ? (
    <Virtuoso
      overscan={50}
      style={{ height: 250 }}
      totalCount={rows.length}
      itemContent={(index: number) => rows[index]}
    />
  ) : (
    <components.MenuList {...props} children={rows} />
  )
}

const SelectField: React.FC<
  ForwardRefProps<
    Select<unknown, boolean, GroupBase<unknown>>,
    ISelectFieldProps
  >
> = React.forwardRef(
  (
    {
      sx,
      selectSx,
      label,
      id,
      size = 'md',
      helperText,
      isError,
      isWarning,
      selectClassName,
      options,
      disabled,
      width,
      creatable,
      components,
      withCheckbox,
      useVirtualizedList,
      ...props
    },
    ref
  ) => {
    const { colors, text, forms } = theme as any;
    const selectClass = cx('react-select-container', selectClassName, {
      [`is-error`]: !!isError,
      [`is-warning`]: !!isWarning,
    })

    const SelectComponent = useMemo(
      () => (creatable ? CreatableSelect : ReactSelect),
      [creatable]
    )

    const modifiedComponents = useVirtualizedList
      ? {
        MenuList: VirtualizedMenuList,
      }
      : {}

    const formatCreateLabel = (inputValue: any) => {
      return (
        <Text sx={text.body}>
          <span style={{ color: '#8C9196' }}>Create</span> {inputValue}
        </Text >
      )
    }

    const renderWarning = () => {
      if (isError) {
        return <WarningFilled />
      } else if (isWarning) {
        return <WarningAltFilled />
      } else {
        return null
      }
    }

    const getWarningColor = () => {
      if (isError) {
        return colors.text.critical
      } else if (isWarning) {
        return colors.text.warning
      } else {
        return colors.text.secondary
      }
    }

    return (
      <Box sx={{ ...rootSx, ...sx }}>
        {label && <Label htmlFor={id} sx={forms.label}>{label}</Label>}
        <Box
          sx={{
            position: 'relative',
            '& > svg': {
              position: 'absolute',
              right: '14px',
              top: '50%',
              transform: 'translateY(-50%)',
              '& [data-icon-path="inner-path"]': { fill: '#000000' },
            },
          }}
        >
          <SelectComponent
            options={options}
            className={selectClass}
            theme={selectTheme(theme, size as any)}
            ref={ref}
            isDisabled={disabled}
            components={{
              Option: withCheckbox ? OptionWithCheckbox : Option,
              DropdownIndicator,
              ...modifiedComponents,
              ...components,
            }}
            styles={styles({ width })}
            menuPlacement="auto"
            formatCreateLabel={creatable ? formatCreateLabel : undefined}
            isClearable={false}
            sx={{ ...forms.input.fontSize }}
            {...props}
          />
          {renderWarning()}
        </Box>
        {helperText && (
          <Text
            as="p"
            sx={{ pt: '4px', ...text.footnote }}
            color={getWarningColor()}
          >
            {helperText}
          </Text>
        )}
      </Box>
    )
  }
)

export default SelectField
