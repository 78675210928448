import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'

import { useActiveWorkspace } from '../users/userWorkspacesHooks'

import TrainingsAPI from './trainings.api'


import TRAININGS_URLS from './trainings.urls'

export const useTrainings = () => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useInfiniteQuery<any, Error>(
    [TRAININGS_URLS.GET_ALL(activeWorkspaceId)],
    ({ pageParam = '' }) => TrainingsAPI.getAll(activeWorkspaceId, { cursor: pageParam, pageSize: '100' }),
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
      enabled: !!activeWorkspaceId,
      select: (data) => ({
        ...data,
        pages: data.pages.map(page => ({
          ...page,
          data: page.data
        }))
      }),
    },
  );
};
