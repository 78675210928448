/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react'
import { Notification } from '@boundlessdigital/bng-shared-components'
import ProductSelectTile from '../components/product-select-tile.component'
import DataLoadingTile from '../components/data-loading-tile.component'
import { ChevronDown, Network_1, TagGroup } from '@carbon/icons-react'
import Link from '../../../components/Link'
import { Text } from 'theme-ui'
import { useNavigate } from 'react-router-dom'
import { useSelectedProduct } from '../profile-setup.hooks'
import Tile from '../../../components/Tile/Tile'
import theme from '../../../config/theme'

const RANDOM_LOADING_TIME = Math.random() * 1000 + 2000;
const NOTIFICATION_DELAY = 1000;
const PROCESS_DELAY = 1000;

const ProcessingDataStep = () => {
  const navigate = useNavigate()
  const [showNotification, setShowNotification] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const product = useSelectedProduct()
  const [tagsStatus, setTagsStatus] = useState('pending')
  const [networksStatus, setNetworksStatus] = useState('pending')

  useEffect(() => {
    setTimeout(() => {
      setTagsStatus('loading');
      setTimeout(() => {
        setTagsStatus('success');
        setNetworksStatus('loading');
        setTimeout(() => {
          setNetworksStatus('success');
          setTimeout(() => setShowNotification(true), NOTIFICATION_DELAY);
        }, RANDOM_LOADING_TIME);
      }, RANDOM_LOADING_TIME);
    }, PROCESS_DELAY);
  }, []);


  return (
    <>
      <ProductSelectTile
        key={product.title}
        icon={product.icon}
        label={product.title}
        caption={product.description}
        sx={{ mt: '16px' }}
        disabled
      />
      <Tile
        wrapperSx={{
          mt: '16px',
          '& .cds--progress-bar__label': { display: 'none' },
          '& .cds--actionable-notification__action-button': {
            height: '40px',
            mt: '10px',
          },
        }}
        title="Connecting data from the Meraki dashboard"
      >
        {showNotification ? (
          <Notification
            title="Congratulations!"
            kind="success"
            message="Your Cisco Meraki organizations are now connected to Boundless."
            actionProps={{
              label: `Go to ${product.title}`,
              kind: 'primary',
              onClick: () => navigate(product.path as string),
            }}
            hideCloseButton
            isToast
          />
        ) : null}

        {!showNotification || showSummary ? (
          <>
            <DataLoadingTile
              label="Networks"
              icon={<Network_1 size={24} />}
              status={tagsStatus}
              isLoading={tagsStatus === 'loading'}
            />
            <DataLoadingTile
              label="Tags"
              icon={<TagGroup size={24} />}
              status={networksStatus}
              isLoading={networksStatus === 'loading'}
            />
          </>
        ) : null}
      </Tile>
    </>
  )
}

export default ProcessingDataStep
