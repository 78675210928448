export enum APIKeyStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum APIKeyScopeType {
  PRIVATE = 'PRIVATE',
  WORKSPACE = 'WORKSPACE',
  SHARED = 'SHARED',
}

export enum Vendors {
  MERAKI = 'Meraki',
}

export enum OnboardingStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum MerakiAccessType {
  full = 'Full',
  'read-only' = 'Read-only',
  none = 'None',
}

export enum Service {
  API_KEY_MANAGEMENT = 'api-key-management',
  USER_MANAGEMENT = 'user-management',
  OPERATION_MANAGEMENT = 'operation-management',
  ESTEE_LAUDER_VISION_PORTAL = 'el-vp',
  SAML = 'saml',
}

export enum SAMLTarget {
  MERAKI = 'meraki',
  MERAKI_VISION = 'meraki_vision',
}
