export type ColorTypes =
  | 'success'
  | 'warning'
  | 'critical'
  | 'primary'
  | 'secondary'
  | 'depressed'
  | 'onColor'
  | 'disabled'

export interface PageChange {
  page: number
  pageSize: number
}

export enum ACTION_ITEM_TYPE {
  ACTION_ITEM,
  SECTION_TITLE,
}

export interface AnyProps {
  [key: string]: any;
}

export type WithId<T> = T & { id: string };


export enum OrganizationAccessEnum {
  FULL = 'full',
  READ_ONLY = 'read-only',
  NONE = 'none',
  GUEST_AMBASSADOR = 'guest-ambassador',
  MONITOR_ONLY = 'monitor-only'
}

export enum ProductEnum {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  CONFIGURATION_BACKUP = 'CONFIGURATION_BACKUP',
  SWITCH_PORT_MANAGEMENT = 'SWITCH_PORT_MANAGEMENT',
  ESTEE_LAUDER_VISION_PORTAL = 'ESTEE_LAUDER_VISION_PORTAL'
}

export enum ErrorCodeEnum {
  MERAKI_IP_RESTRICTION_ERROR = 'E1000',
  MERAKI_INVALID_API_KEY_ERROR = 'E1001',
  MERAKI_UNKNOWN_ERROR = 'E1002',
  MERAKI_ORGANIZATION_ADMIN_NOT_FOUND = 'E1003',
  ORGANIZATION_ADMIN_NOT_DISABLED = 'E1004',
  API_KEY_NO_ORGANIZATION_ACCESS = 'E1005',
  NO_API_KEY_FOUND_FOR_ORGANIZATION = 'E1006',
  FAILED_TO_SAVE_ENABLED_ORGANIZATION = 'E1007',
  MERAKI_UNVERIFIED_USER_ERROR = 'E1008',
  ADMIN_ALREADY_EXISTS = 'E1009',
  INTERNAL_SERVER_ERROR = 'E1010',
  ADMIN_NOT_FOUND = 'E1011',
  NOT_POSSIBLE_TO_DISABLE_ADMIN = 'E1012',
  ORGANIZATION_ALREADY_ENABLED = 'E1013',
  MERAKI_API_ACCESS_NOT_ENABLED_ERROR = 'E1014',
  ONLY_ORGANIZATION_WITH_FULL_ACCESS_CAN_BE_ENABLED = 'E1015',
}
