import APIAbstract from '../api.abstract'

import { API_USER_MANAGEMENT_BASE_URL } from '../constants'
import TRAININGS_URLS from './trainings.urls';

class TrainingsAPI extends APIAbstract {
  public getAll = async (
    workspaceId: string,
    pagination?: Record<string, string>,
  ): Promise<any> => {
    const response = await this.get(TRAININGS_URLS.GET_ALL(workspaceId, pagination));
    return response.data;
  }

  public create = async (payload: { admins: any[], workspaceId: string }): Promise<any> => {
    const response = await this.post(TRAININGS_URLS.BASE, payload)
    return response.data;
  }


}

export default new TrainingsAPI({ apiEndpoint: API_USER_MANAGEMENT_BASE_URL })
