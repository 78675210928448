import apiClient from './api.client'
import { AxiosInstance } from 'axios'
import { BASE_URL } from './constants'

type APIAbstractParamsType = {
  apiClient?: AxiosInstance
  apiEndpoint?: string
}

class APIAbstract {
  protected readonly apiClient: AxiosInstance = apiClient
  protected readonly apiEndpoint: string = BASE_URL

  constructor(props?: APIAbstractParamsType) {
    if (props) {
      const { apiClient: _apiClient, apiEndpoint } = props
      if (_apiClient) {
        this.apiClient = _apiClient
        this.apiEndpoint = '';
      }
      if (apiEndpoint) {
        this.apiEndpoint = apiEndpoint
      }
    }
  }

  public get(url: string, params = {}): Promise<any> {
    return this.apiClient.get(`${this.apiEndpoint}${url}`, { params })
  }

  public post(url: string, data?: unknown, params = {}): Promise<any> {
    return this.apiClient.post(`${this.apiEndpoint}${url}`, data, params)
  }

  public put(url: string, data?: unknown, params = {}): Promise<any> {
    return this.apiClient.put(`${this.apiEndpoint}${url}`, data, params)
  }

  public patch(url: string, data?: unknown, params = {}): Promise<any> {
    return this.apiClient.patch(`${this.apiEndpoint}${url}`, data, params)
  }

  public delete(url: string, data?: unknown): Promise<any> {
    return this.apiClient.delete(`${this.apiEndpoint}${url}`, { data });
  }
}

export default APIAbstract
