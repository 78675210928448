import * as PATHS from '../../constants/routes.constants'

import SwitchPortIcon from "../../components/icons/switch-port.icon"
import { Product } from '../../config/config.types'
import { ProductEnum } from '../../types/general.types'
import { asyncComponentLoader } from '../../components/component-loader/component-loader.component'

export const switchPortManagementConfig: Product = {
  title: 'Switch Port Manager',
  value: ProductEnum.SWITCH_PORT_MANAGEMENT,
  icon: {
    title: 'Switch Port Manager',
    component: SwitchPortIcon,
    description: 'Configure hundreds of switch ports consistently, in one go.',
  },
  description: 'Mass configure switch ports across multiple networks.',
  path: PATHS.SWITCH_PORT_MANAGER,
  component: asyncComponentLoader(() => import(/* webpackChunkName: "switch-port-management" */ './switch-port-management.component')),
  isDevelopment: true,
  isLocked: false,
}