import APIAbstract from '../api.abstract'
import {
    IOnboardingApiKeyPayload,
    IOnboardingOrganizationsPayload,
    IOnboardingProductsPayload,
    IOnboardingWorkspacePayload,
    IUser,
    IWorkspace,
} from './types'
import USERS_URLS from './usersUrls'
import { AxiosResponse } from 'axios'

class UsersAPI extends APIAbstract {
  public getUserById = (userId: string): Promise<AxiosResponse<IUser>> => {
    return this.get(USERS_URLS.GET_USER_BY_ID.replace(':id', userId))
  }

  public getWorkspaceList = (userId: string): Promise<AxiosResponse<{ data: IWorkspace[] }>> => {
    return this.get(USERS_URLS.WORKSPACE_LIST.replace(':id', userId))
  }

  public getWorkspaceDefault = (userId: string) => {
    return this.get(USERS_URLS.WORKSPACE_GET_DEFAULT.replace(':id', userId))
  }

  public setWorkspaceDefault = (userId: string, workspaceId: string) => {
    return this.post(
      USERS_URLS.WORKSPACE_SET_DEFAULT.replace(':id', userId).replace(
        ':workspaceId',
        workspaceId
      )
    )
  }

  public setOnboardingWorkspaceDetails = (
    userId: string,
    payload: IOnboardingWorkspacePayload
  ) => {
    return this.post(
      USERS_URLS.ONBOARDING_WORKSPACE.replace(':id', userId),
      payload
    )
  }

  public setOnboardingProducts = (
    userId: string,
    payload: IOnboardingProductsPayload
  ) => {
    return this.post(
      USERS_URLS.ONBOARDING_PRODUCTS.replace(':id', userId),
      payload
    )
  }

  public setOnboardingApiKey = (
    userId: string,
    payload: IOnboardingApiKeyPayload
  ) => {
    return this.post(
      USERS_URLS.ONBOARDING_API_KEY.replace(':id', userId),
      payload
    )
  }

  public setOnboardingOrganizations = (
    userId: string,
    payload: IOnboardingOrganizationsPayload
  ) => {
    return this.post(
      USERS_URLS.ONBOARDING_ORGANIZATIONS.replace(':id', userId),
      payload
    )
  }

  public getUsersByWorkspace = (workspaceId: string) => {
    return this.get(USERS_URLS.GET_USERS_BY_WORKSPACE.replace(':id', workspaceId))
  }
}

export default new UsersAPI()
