import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'

import { useActiveWorkspace } from '../users/userWorkspacesHooks'

import AdminsAPI from './admins.api'
import ADMINS_URLS from './admins.urls'
import { AdminsResponse } from './admins.types'
import { fetchAdminWithPermissions, handleAdminUpdateApiResponse, handleCreateApiResponse, handleDeleteApiResponse, transformAdministratorData, transformAdministratorList } from './admins.utils'
import OPERATIONS_URLS from '../operations/operations.urls'
import { groupTagsAndNetworksByPrivilege } from '../../pages/user-management/user-overview/user-overview.utils'
import { PRIVILEGES, backendPrivilegesMap } from '../../constants/privileges.constants'
import { parseAdminToUpdate } from '../../pages/user-management/update-admin/update-admin.utils'
import useActionModalStore from '../../store/useActionModalStore'

export const useAdmins = () => {
  const { activeWorkspaceId } = useActiveWorkspace();

  return useInfiniteQuery<AdminsResponse, Error>(
    [ADMINS_URLS.GET_ADMINS(activeWorkspaceId)],
    async ({ pageParam = '' }) => {
      if (pageParam !== null) {
        const response = await AdminsAPI.getAdmins(activeWorkspaceId, { cursor: pageParam });
        return response;
      }

      return Promise.resolve({ data: [], cursor: null });
    },
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
      enabled: !!activeWorkspaceId,
      select: (data) => ({
        ...data,
        pages: data.pages.map(page => ({
          ...page,
          data: transformAdministratorList(page.data),
        })),
      }),
    },
  );
}

export const useAdminById = (id: string) => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useQuery(
    [ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, id)],
    () => AdminsAPI.getAdminById(activeWorkspaceId, id).then(transformAdministratorData),
    {
      enabled: !!activeWorkspaceId && !!id,
    },
  )
}

export const useAdminByIdWithDetailedPermissions = (id: string) => {
  const { activeWorkspaceId } = useActiveWorkspace();

  const adminQuery = useQuery(
    [ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, id)],
    () => AdminsAPI.getAdminById(activeWorkspaceId, id).then(transformAdministratorData),
    {
      enabled: !!activeWorkspaceId && !!id,
    }
  );

  const organizationsWithDetailedPermissions = adminQuery.data?.organizations?.filter((org) => org.hasDetailedPermissions || org.organizationAccess === PRIVILEGES.NONE) || []

  const organizationQueriesConfig = organizationsWithDetailedPermissions.map((org) => ({
      queryKey: [ADMINS_URLS.GET_ADMIN_ORGANIZATION_DETAILED_PERMISSIONS(activeWorkspaceId, id, org.organizationId)],
      queryFn: () => AdminsAPI.getAdminOrganizationDetailedPermissions(activeWorkspaceId, id, org.organizationId),
      enabled: !!activeWorkspaceId && !!id && !!org.organizationId,
      meta: { orgId: org.organizationId },
    })) || [];

  const organizationQueries = useQueries({
    queries: organizationQueriesConfig,
  });

  const detailedPermissionsData = organizationQueries.reduce((acc, query, index) => {
    if (query.isSuccess && query.data) {
      const orgId = organizationQueriesConfig[index].meta.orgId;
      acc[orgId] = query.data;
    }
    return acc;
  }, {});

  const transformedData = adminQuery.data && {
    ...adminQuery.data,
    organizations: (adminQuery?.data?.organizations || []).map((org) => ({
      ...org,
      privilege: org.organizationAccess,
      detailedPermissions: groupTagsAndNetworksByPrivilege(detailedPermissionsData[org.organizationId]) || null,
    })),
  };

  return {
    ...adminQuery,
    isLoading: !([adminQuery, ...organizationQueries].every((query => query.isSuccess))),
    data: transformedData,
  };
}

export const useAdminsByIdsWithDetailedPermissions = (adminIds: string[]) => {
  const { activeWorkspaceId } = useActiveWorkspace();
  
  const adminQueries = useQueries({
    queries: adminIds.map(id => ({
      queryKey: ['adminWithPermissions', id],
      queryFn: () => fetchAdminWithPermissions(activeWorkspaceId, id),
      enabled: !!id,
    }))
  })

  const isLoading = adminQueries.some(query => query.isLoading);
  const isError = adminQueries.some(query => query.isError);
  const error = adminQueries.find(query => query.error)?.error;
  const data = adminQueries.map(query => query.data).filter(Boolean);

  return { isLoading, isError, error, data };
}

export const useUserSummary = () => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useQuery(
    [ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)],
    () => AdminsAPI.getUserSummary(activeWorkspaceId),
    {
      enabled: !!activeWorkspaceId,
    },
  )
}

export const useOrganizationsByAdminId = (id: string, shouldFetch: boolean) => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useQuery(
    [ADMINS_URLS.GET_ADMIN_ORGANIZATIONS(activeWorkspaceId, id)],
    () => AdminsAPI.getAdminById(activeWorkspaceId, id),
    {
      enabled: !!activeWorkspaceId && !!id && shouldFetch,
      select: (result) => {
        return (result?.organizations || []).map(({
          organizationId,
          organizationName,
          organizationAccess,
          hasDetailedPermissions,
        }) => {
          return {
            organizationId,
            organizationName,
            organizationAccess,
            hasDetailedPermissions,
          }
        })
      },
    },
  )
}

export const useAdminsByIds = (ids = []) => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useQueries({
    queries: ids.map(id => ({
      queryKey: [ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, id)],
      queryFn: () => AdminsAPI.getAdminById(activeWorkspaceId, id).then(transformAdministratorData),
      enabled: !!activeWorkspaceId && !!id,
    })),
  })
}

export const useCreateAdmin = () => {
  const { activeWorkspaceId } = useActiveWorkspace()
  const queryClient = useQueryClient()
  const openModal = useActionModalStore((state) => state.openModal)


  const createAdmin = async (newAdmins: any[]) => {
    const responses = await AdminsAPI.createAdmin({
      admins: newAdmins,
      workspaceId: activeWorkspaceId,
    })

    handleCreateApiResponse(responses, newAdmins, openModal)

    return responses
  }

  return useMutation(createAdmin, {
    onSuccess: () => {
      Promise.all([
        queryClient.refetchQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)]),
        queryClient.invalidateQueries([OPERATIONS_URLS.GET_OPERATIONS(activeWorkspaceId)]),
        queryClient.invalidateQueries([ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)])
      ]);
    },
  })
}

export const useUpdateAdmin = () => {
  const { activeWorkspaceId } = useActiveWorkspace()
  const queryClient = useQueryClient()
  const openModal = useActionModalStore((state) => state.openModal)

  const updateAdmin = async (updatedAdmins: any[]) => {
    const parsedAdmins = updatedAdmins.map(parseAdminToUpdate)
    
    const response = await AdminsAPI.updateAdmin({
      admins: parsedAdmins,
      workspaceId: activeWorkspaceId,
    });

    handleAdminUpdateApiResponse(response.data.data, updatedAdmins.map(admin => ({ id: admin.id, record: admin})), openModal)
    if (response.status === 207) {
      throw new Error("Partial update success");
    }
    
    return response.data;
  };

  

  return useMutation(updateAdmin, {
    onMutate: async (updatedAdmins) => {
      await queryClient.cancelQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])

      const previousAdmins = updatedAdmins.map(admin => {
        const adminData: any = queryClient.getQueryData([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, admin.id)]);
        return { adminId: admin.id, data: {...adminData, adminId: admin.id} };
      });      

      queryClient.setQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)], (oldData: any) => {
        if (!oldData) return

        return {
          ...oldData,
          pages: oldData.pages.map(page => ({
            ...page,
            data: page.data.map(admin => {
              const updatedAdmin = updatedAdmins.find(uAdmin => uAdmin.id === admin.id)
              return updatedAdmin ? { ...admin, ...updatedAdmin } : admin
            }),
          })),
        }
      })

      updatedAdmins.forEach(admin => {
        queryClient.setQueryData([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, admin.adminId)], (oldData: any) => {
          if (!oldData) return

          const organizations = oldData.organizations.map((organization) => {
            const newMatchedOrganization = admin.organizations.find((newOrganization) => newOrganization.organizationId === organization.organizationId)
            
            if (!newMatchedOrganization) {
              return null
            }

            const groupedTagsAndNetworks = groupTagsAndNetworksByPrivilege({ networks: newMatchedOrganization.networks, tags: newMatchedOrganization.tags })

            const detailedPermissions = groupedTagsAndNetworks?.length ? { hasDetailedPermissions: true, detailedPermissions: groupedTagsAndNetworks } : {}

            if (backendPrivilegesMap[newMatchedOrganization.organizationAccess] !== organization.organizationAccess) {
              return {
                ...organization,
                organizationAccess: backendPrivilegesMap[newMatchedOrganization.organizationAccess],
                ...detailedPermissions
              }
            }
            return organization
          }).filter(Boolean)

          return {
            ...oldData,
            organizations
          }
        })
      })

      return {
        previousAdmins,
        updatedAdmins,
      }
    },
    onError: (err, newAdmins, context) => {
      if (context?.previousAdmins.length) {
        context?.previousAdmins.forEach(admin => {
          queryClient.setQueryData([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, admin.adminId)], admin.data);
          queryClient.invalidateQueries([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, admin.adminId)]);
          queryClient.invalidateQueries([ADMINS_URLS.GET_ADMIN_ORGANIZATIONS(activeWorkspaceId, admin.adminId)]);
        })
      }
    },
    onSuccess: async (data, variables, context) => {
      const getQueriesForUpdatedAdmins = (admins) => {
        return admins.flatMap(admin => {
          const baseQueries = [
            ['adminWithPermissions', admin.adminId]
          ];
    
          const organizationQueries = admin.organizations.map(org => {
            if (org.hasDetailedPermissions) {
              return [ADMINS_URLS.GET_ADMIN_ORGANIZATION_DETAILED_PERMISSIONS(activeWorkspaceId, admin.adminId, org.organizationId)]
            }
            
            return null
          }).filter(Boolean)
    
          return [...baseQueries, ...organizationQueries];
        });
      };
    
      const queriesToInvalidate = [
        [OPERATIONS_URLS.GET_OPERATIONS(activeWorkspaceId)],
        [ADMINS_URLS.GET_ADMINS(activeWorkspaceId)],
        [ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)],
        ...getQueriesForUpdatedAdmins(context?.updatedAdmins || [])
      ];

      try {
        await Promise.all(queriesToInvalidate.map(queryKey => queryClient.invalidateQueries(queryKey)));
      } catch (error) {
        console.error("Error invalidating queries:", error);
      }
    },
  })
}

export const useUpdateAdminNameAndTrainings = () => {
  const { activeWorkspaceId } = useActiveWorkspace()
  const queryClient = useQueryClient()

  const updateAdminNameAndTrainings = (updatedAdmin: any) => {
    return AdminsAPI.updateAdminNameAndTrainings({
      updatedAdmin,
      workspaceId: activeWorkspaceId,
    })
  }

  return useMutation(updateAdminNameAndTrainings, {
    onMutate: async (updatedAdmin) => {
      await queryClient.cancelQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])

      const previousAdmins = queryClient.getQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])

      queryClient.setQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)], (old: any) => {
        if (!old) return

        return {
          ...old,
          pages: old.pages.map(page => ({
            ...page,
            data: page.data.map(admin => {
              return updatedAdmin.id === admin.adminId ? {
                ...admin,
                ...{
                  ...updatedAdmin,
                  adminName: updatedAdmin.fullName,
                },
              } : admin
            }),
          })),
        }
      })

      queryClient.setQueryData([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, updatedAdmin.id)], (old: any) => {
        if (!old) return
        const parsedUpdateTrainings = updatedAdmin.trainings.map(({ label, value }) => {
          const trainingStatus = old.trainings.find(oldTraining => oldTraining.trainingId === value)?.trainingStatus || 'PENDING'

          return {
            trainingName: label,
            trainingId: value,
            trainingStatus
          }
        })

        return {
          ...old,
          ...updatedAdmin,
          trainings: parsedUpdateTrainings,
          adminName: updatedAdmin.fullName,
        }
      })

      return { previousAdmins }
    },
    onError: (err, variables, context) => {
        queryClient.setQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)], context?.previousAdmins)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([OPERATIONS_URLS.GET_OPERATIONS(activeWorkspaceId)])
      queryClient.invalidateQueries([ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)])
    },
    onSettled: (data, error, variables, context) => {
      queryClient.refetchQueries([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, variables.id)])
      queryClient.invalidateQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])
    },
  })
}

export const useDownloadAdminsAsCsv = () => {
  const { activeWorkspaceId } = useActiveWorkspace()

  const downloadAdminsAsCsv = () => {
    return AdminsAPI.downloadAdminAsCsv({
      workspaceId: activeWorkspaceId,
    })
  }

  return useMutation(downloadAdminsAsCsv)
}

export const useUpdateAdminTrainingsStatus = () => {
  const { activeWorkspaceId } = useActiveWorkspace();
  const queryClient = useQueryClient();

  const updateAdminTrainingsStatus = ({ adminId, trainings }) => {
    return AdminsAPI.updateAdminTrainingsStatus({
      adminId,
      trainings,
      workspaceId: activeWorkspaceId,
    });
  };

  return useMutation(updateAdminTrainingsStatus, {
    onMutate: async ({ adminId, trainings }) => {
      const adminQueryKey = [ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, adminId)];

      await queryClient.cancelQueries(adminQueryKey);

      const previousAdmin = queryClient.getQueryData(adminQueryKey);

      queryClient.setQueryData(adminQueryKey, (oldAdminData: any) => {
        const safeOldAdminData = oldAdminData || {};

        const parsedTrainings = trainings.map((training) => {
          const trainingName = (oldAdminData?.trainings || []).find((oldTraining => oldTraining.trainingId === training.trainingId))?.trainingName
          
          return {
            ...training,
            trainingName
          }
        })

        return {
          ...safeOldAdminData,
          trainings: parsedTrainings,
        }
      });

      return { previousAdmin };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        [ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, variables.adminId)],
        context?.previousAdmin
      );
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([OPERATIONS_URLS.GET_OPERATIONS(activeWorkspaceId)])
      queryClient.invalidateQueries([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, variables.adminId)])
      queryClient.invalidateQueries([ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)])
    },
  });
}

export const useResendAdminOrganizationInvite = () => {
  const { activeWorkspaceId } = useActiveWorkspace();

  return useMutation(
    ({ adminId, organizationId }: any) => AdminsAPI.resendAdminOrganizationInvite({ 
      adminId, 
      organizationId, 
      workspaceId: activeWorkspaceId 
    })
  );
}

export const useResendAdminInvites = () => {
  const { activeWorkspaceId } = useActiveWorkspace();
  const queryClient = useQueryClient()

  const resendInvites = async ({ adminId }: any) => {
    const response = await AdminsAPI.resendAdminInvites({ 
      adminId, 
      workspaceId: activeWorkspaceId 
    })

    return response
  }

  return useMutation(resendInvites, {
    onSettled: (data, variables, context) => {
      queryClient.refetchQueries([ADMINS_URLS.GET_ADMIN_BY_ID(activeWorkspaceId, context.adminId)])
    },
  });
}

export const useDeleteAdmin = () => {
  const { activeWorkspaceId } = useActiveWorkspace()
  const queryClient = useQueryClient()
  const openModal = useActionModalStore((state) => state.openModal)

  const deleteAdmin = async (selectedUsers: any[]) => {
    const admins = selectedUsers.map(({record}) => {
      return {
        adminId: record.id,
        adminName: record.adminName
      }
    })

    const response = await AdminsAPI.deleteAdmin({
      admins,
      workspaceId: activeWorkspaceId,
    })

    handleDeleteApiResponse(response.data, selectedUsers, openModal)

    return response
  }

  return useMutation(deleteAdmin, {
    onMutate: async (adminIdsToDelete) => {
      await queryClient.cancelQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])

      const previousData = queryClient.getQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])

      queryClient.setQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)], (oldData: any) => {
        if (!oldData) return

        return {
          ...oldData,
          pages: oldData.pages.map(page => ({
            ...page,
            data: page.data.filter(admin => !adminIdsToDelete.includes(admin.adminId)),
          })),
        }
      })

      return { previousData }
    },
    onError: (err, adminIdsToDelete, context) => {
      if (context?.previousData) {
        queryClient.setQueryData([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)], context.previousData)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([OPERATIONS_URLS.GET_OPERATIONS(activeWorkspaceId)])
      queryClient.invalidateQueries([ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)])
      queryClient.invalidateQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])
    },
  })
}

export const useAdminOrganizationDetailedPermissions = ({
  adminId,
  organizationId,
}) => {
  const { activeWorkspaceId } = useActiveWorkspace();

  return useQuery(
    [ADMINS_URLS.GET_ADMIN_ORGANIZATION_DETAILED_PERMISSIONS(activeWorkspaceId, adminId, organizationId)],
    () => AdminsAPI.getAdminOrganizationDetailedPermissions(activeWorkspaceId, adminId, organizationId),
    {
      enabled: !!activeWorkspaceId && !!adminId && !!organizationId,
    },
  );
}

