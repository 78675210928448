import { useEffect, useMemo, useState } from 'react'
import { useUserMe } from '../../api/users/usersHooks'
import { WizardPage } from './profile-setup.constants'
import { Content } from './profile-setup.types'
import { getContentByPage } from './profile-setup.utils'
import ApiKeysAPI from '../../api/api-keys/api-keys.api';
import { useActiveWorkspace } from '../../api/users/userWorkspacesHooks'
import { useApiKey, useApiKeys } from '../../api/api-keys/api-keys.hooks'
import { productsConfig } from '../../config/products.config'
import useUserPermissions from '../../hooks/user-permissions.hook'

export const useSelectedProduct = () => {
  const { data } = useUserMe()
  const {accessibleProducts} = useUserPermissions(data)
  

  return useMemo(() => {
    const filteredProduct = productsConfig.filter(product => accessibleProducts.includes(product.value))

    if (data?.onboarding.product?.name) {
      return (
        filteredProduct.find(
          (item) => item.value === data.onboarding.product?.name
        ) ?? filteredProduct[0]
      )
    }
    return filteredProduct[0]
  }, [data?.onboarding.product?.name])
}


export const useContent = (page: WizardPage, product?: { title: string }): Content => {
  return useMemo(() => getContentByPage(page, product), [page, product]);
};

export const useTotalCounts = () => {
  const [totalNetworks, setTotalNetworks] = useState(0);
  const [totalTags, setTotalTags] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { activeWorkspaceId } = useActiveWorkspace();
  const { data: apiKeysData } = useApiKeys(true);
  const { data: apiKey } = useApiKey(apiKeysData?.personalKeys[0]?.apiKeyId || '');
  const organizationIds = useMemo(() => (apiKey?.organizations || []).map(org => org.organizationId), [apiKey]);

  useEffect(() => {
    const fetchTotalCounts = async () => {
      setIsLoading(true);

      const allPromises = organizationIds.flatMap(orgId => [
        ApiKeysAPI.getOrganizationNetworks(activeWorkspaceId, orgId, { pageSize: '1000' }).then(res => res.data.length),
        ApiKeysAPI.getOrganizationTags(activeWorkspaceId, orgId, { pageSize: '1000' }).then(res => res.data.length)
      ]);

      try {
        const results = await Promise.all(allPromises);
        const totalCounts = results.reduce((acc, count, index) => {
          if (index % 2 === 0) {
            acc.networks += count;
          } else {
            acc.tags += count;
          }
          return acc;
        }, { networks: 0, tags: 0 });

        setTotalNetworks(totalCounts.networks);
        setTotalTags(totalCounts.tags);
      } catch (error) {
        console.error('Failed to fetch total counts:', error);
      }

      setIsLoading(false);
    };

    if (organizationIds.length > 0 && activeWorkspaceId) {
      fetchTotalCounts();
    }
  }, [organizationIds, activeWorkspaceId]);

  return { totalNetworks, totalTags, isLoading };
};
