/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, ChevronUp, Switcher } from '@carbon/icons-react'
import { Header, HeaderName, HeaderGlobalBar } from '@carbon/react'
import { Box, Container } from 'theme-ui'
import { HeaderNameProps } from 'carbon-components-react/lib/components/UIShell/HeaderName'
import { ControlledMenu } from '@szhsin/react-menu'
import BasicMenu, { IBasicMenuProps } from '../Menu/BasicMenu'
import Button from '../Button/Button'


interface IHeaderProps {
  /**
   * Header title
   */
  headerProductText?: string
  /**
   * Header icon
   */
  headerProductIcon?: React.ReactElement | null
  /**
   * List of menu items.
   */
  headerProductDropdownList?: IBasicMenuProps['list']
  /**
   * Header name props
   */
  headerNameProps?: HeaderNameProps
  /**
   * Content in header dropdown menu
   */
  renderHeaderMenuContent?: (closeMenu: any) => React.ReactNode
  /**
   * Array of items to render in global bar
   */
  headerItems?: { key: string; item: React.ReactNode }[]
}
const dropdownArrowWrapper = {
  display: 'inline-flex',
  cursor: 'pointer',
  color: 'white',
}

const AppHeader = ({
  headerProductText,
  headerProductIcon,
  headerProductDropdownList,
  headerNameProps,
  renderHeaderMenuContent,
  headerItems,
}: IHeaderProps) => {
  const [headerMenuIsOpen, setHeaderMenuIsOpen] = useState(false)
  const headerMenuButtonRef = useRef<HTMLButtonElement | null>(null)

  const handleHeaderMenuButton = () => setHeaderMenuIsOpen((prev) => !prev)

  return (
    <Header aria-label="App header">
      {!!renderHeaderMenuContent && (
        <>
          <Button
            ref={headerMenuButtonRef}
            className={headerMenuIsOpen ? 'menu-active' : ''}
            kind="ghost"
            onMouseDown={handleHeaderMenuButton}
          >
            <Switcher size={20} />
          </Button>
          <ControlledMenu
            className="content-menu-root"
            state={headerMenuIsOpen ? 'open' : undefined}
            anchorRef={headerMenuButtonRef}
            onClose={handleHeaderMenuButton}
            direction="bottom"
            position="anchor"
          >
            <Container>
              {renderHeaderMenuContent(handleHeaderMenuButton)}
            </Container>
          </ControlledMenu>
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .cds--header__name': {
            paddingRight: '8px',
          },
          '& .svg-icon': {
            display: 'inline-flex',
            marginRight: '4px',
            marginLeft: '8px',
          },
        }}
      >
        {headerProductIcon &&
          React.cloneElement(headerProductIcon, {
            size: 32,
          })}
        {headerProductText && (
          <HeaderName prefix="" {...headerNameProps}>
            {headerProductText}
          </HeaderName>
        )}
        {headerProductDropdownList && (
          <BasicMenu
            customMenuButton={({ open }: any) => (
              <div sx={dropdownArrowWrapper}>
                {open ? <ChevronUp /> : <ChevronDown />}
              </div>
            )}
            list={headerProductDropdownList}
            direction="bottom"
            align="start"
            offsetY={8}
          />
        )}
      </Box>

      {!!headerItems?.length && (
        <HeaderGlobalBar>
          <span className="header-spacer" />
          {headerItems.map((item) => (
            <React.Fragment key={`header-item-${item.key}`}>
              <span className="header-spacer" />
              {item.item}
            </React.Fragment>
          ))}
        </HeaderGlobalBar>
      )}
    </Header>
  )
}

export default AppHeader
