import { asyncComponentLoader } from '../components/component-loader/component-loader.component';
import * as PATHS from '../constants/routes.constants'
import { Page } from './config.types';

export const pagesConfig: Page[] = [{
  path: PATHS.HOMEPAGE,
	value: 'HOMEPAGE',
  component: asyncComponentLoader(() => import(/* webpackChunkName: "homepage" */ '../pages/homepage/Homepage')),
  isDevelopment: false,
}, {
  path: PATHS.WORKSPACE,
  value: 'WORKSPACE',
  component: asyncComponentLoader(() => import(/* webpackChunkName: "workspace" */ '../pages/workspace/Workspace')),
  isDevelopment: false,
}, {
  path: PATHS.API_KEY_MANAGEMENT,
	value: 'API_KEY_MANAGEMENT',
	component: asyncComponentLoader(() => import(/* webpackChunkName: "api-key-management" */ '../pages/api-key-management/ApiKeyManagement')),
  isDevelopment: false,
	children: [
		{
			path: PATHS.CONNECT_API_KEY,
			component: asyncComponentLoader(() => import(/* webpackChunkName: "api-key-management/connect" */ '../pages/api-key-management/ConnectApiKey')),
		},  {
			path: PATHS.VIEW_API_KEY,
			component: asyncComponentLoader(() => import(/* webpackChunkName: "api-key-management/view" */ '../pages/api-key-management/ApiKeyView')),
		}, {
			path: PATHS.EDIT_API_KEY,
			component: asyncComponentLoader(() => import(/* webpackChunkName: "api-key-management/edit" */ '../pages/api-key-management/EditApiKey')),
		}
	]
}]