const USERS_BASE = '/users'
const USER_BY_ID = `${USERS_BASE}/:id`

const ONBOARDING = `${USER_BY_ID}/onboarding`

const WORKSPACES = `${USER_BY_ID}/workspaces`

const USERS_URLS = {
  GET_USER_BY_ID: USER_BY_ID, // GET by id

  ONBOARDING_WORKSPACE: `${ONBOARDING}/workspace-details`, // POST by id
  ONBOARDING_PRODUCTS: `${ONBOARDING}/products`, // POST by id
  ONBOARDING_API_KEY: `${ONBOARDING}/api-key`, // POST by id
  ONBOARDING_ORGANIZATIONS: `${ONBOARDING}/organizations`, // POST by id

  WORKSPACE_SET_DEFAULT: `${WORKSPACES}/:workspaceId/default`, // POST by id
  WORKSPACE_GET_DEFAULT: `${WORKSPACES}/default`, // GET by id
  WORKSPACE_LIST: `${WORKSPACES}`, // GET by id
  GET_USERS_BY_WORKSPACE: `/workspaces/:id/users`, // GET by workspaceId
}

export default USERS_URLS
