// userPersistedStore.ts
import { create, Mutate, StoreApi, UseBoundStore } from 'zustand'
import { persist } from 'zustand/middleware'

export interface PersistedState {
  activeWorkspace: { id: string; name: string } | null
  setActiveWorkspace: (activeWorkspace: { id: string; name: string }) => void
}

type useUserPersistedStore = UseBoundStore<
  Mutate<StoreApi<PersistedState>, [['zustand/persist', PersistedState]]>
>

export const useUserPersistedStore: useUserPersistedStore = create(
  persist(
    (set) => ({
      activeWorkspace: null,
      setActiveWorkspace: (activeWorkspace) => set(() => ({ activeWorkspace })),
    }),
    { name: 'userPersistedStore' }
  )
)
