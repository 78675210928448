import APIAbstract from '../api.abstract'
import API_KEYS_URLS from './api-keys.urls'
import { IApiKeysResponse, ICreateApiKey, IUpdateApiKey } from './api-keys.types'
import { API_KEY_BASE_URL } from '../constants'
import { AxiosResponse } from 'axios'

class ApiKeysAPI extends APIAbstract {
  public getApiKeys = (
    workspaceId?: string
  ): Promise<AxiosResponse<IApiKeysResponse>> => {
    return this.get(API_KEYS_URLS.GET_API_KEYS, { workspaceId })
  }

  public getApiKey = (id: string) => {
    return this.get(API_KEYS_URLS.GET_API_KEY.replace(':id', id))
  }

  public getApiKeyOrganizationDevices = (
    apiKeyId: string,
    organizationId: string
  ) => {
    return this.get(
      API_KEYS_URLS.GET_API_KEY_ORGANIZATION_DEVICES.replace(
        ':id',
        apiKeyId
      ).replace(':organizationId', organizationId)
    )
  }

  public getOrganizationsByApiKey = (apiKeyValue: string) => {
    return this.post(API_KEYS_URLS.GET_ORGANIZATIONS_BY_API_KEY, {
      apiKeyValue,
    })
  }

  public getOrganizationTags = async (
    workspaceId: string,
    organizationId: string,
    pagination?: Record<string, string>
  ): Promise<any> => {
    const response = await this.get(API_KEYS_URLS.GET_ORGANIZATIONS_TAGS(workspaceId, organizationId, pagination));
    return response.data;
  }
  
  public getOrganizationNetworks = async (
    workspaceId: string,
    organizationId: string,
    pagination?: Record<string, string>
  ): Promise<any> => {
    const response = await this.get(API_KEYS_URLS.GET_ORGANIZATIONS_NETWORKS(workspaceId, organizationId, pagination));
    return response.data;
  }

  public createApiKey = (newApiKeyData: ICreateApiKey) => {
    return this.post(API_KEYS_URLS.CREATE_API_KEY, newApiKeyData)
  }

  public updateApiKey = (id: string, updatedApiKeyData: IUpdateApiKey) => {
    return this.put(
      API_KEYS_URLS.UPDATE_API_KEY.replace(':id', id),
      updatedApiKeyData
    )
  }

  public shareApiKeyUsers = (id: string, userIds: string[]) => {
    return this.post(API_KEYS_URLS.SHARE_API_KEY.replace(':id', id), {
      userIds,
    })
  }

  public revokeApiKeyUsers = (id: string, userIds: string[]) => {
    return this.post(API_KEYS_URLS.REVOKE_API_KEY.replace(':id', id), {
      userIds,
    })
  }

  public enableOrganizations = (id: string, organizationId: string) => {
    return this.post(API_KEYS_URLS.ENABLE_ORGANIZATIONS.replace(':id', id).replace(':organizationId', organizationId), {
      organizationId,
    })
  }

  public disableOrganizations = (id: string, organizationId: string) => {
    return this.post(API_KEYS_URLS.DISABLE_ORGANIZATIONS.replace(':id', id).replace(':organizationId', organizationId), {
      organizationId,
    })
  }

  public deleteApiKey = (id: string) => {
    return this.delete(API_KEYS_URLS.DELETE_API_KEY.replace(':id', id))
  }

  public disableApiKey = (id: string) => {
    return this.post(API_KEYS_URLS.DISABLE_API_KEY.replace(':id', id))
  }

  public enableApiKey = (id: string) => {
    return this.post(API_KEYS_URLS.ENABLE_API_KEY.replace(':id', id))
  }

  public refreshApiKey = (id: string) => {
    return this.post(API_KEYS_URLS.REFRESH_API_KEY.replace(':id', id))
  }
}

export default new ApiKeysAPI({ apiEndpoint: API_KEY_BASE_URL })
