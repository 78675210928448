import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import {
    IApiKey,
    IApiKeyOrganizationDevicesResponse,
    IApiKeysResponse,
    ICreateApiKey,
    IOrganization,
    IUpdateApiKey,
} from './api-keys.types'
import ApiKeysAPI from './api-keys.api'
import API_KEYS_URLS from './api-keys.urls'
import { ApiDataResponse } from '../types'
import notify from '../../utils/notify.utils'
import { useActiveWorkspace, useUserDefaultWorkspace } from '../users/userWorkspacesHooks'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { exportErrorMessage } from '../../utils/errors.utils'
import ADMINS_URLS from '../admin/admins.urls'

type UseApiKeysType = (
  defaultWorkspace?: boolean
) => UseQueryResult<IApiKeysResponse, Error>

export const useApiKeys: UseApiKeysType = (defaultWorkspace = false) => {
  const { data } = useUserDefaultWorkspace()
  const { activeWorkspaceId } = useActiveWorkspace()
  const workspaceId = useMemo(() => {
    if (defaultWorkspace) {
      return data?.workspaceId
    } else {
      return activeWorkspaceId
    }
  }, [defaultWorkspace, activeWorkspaceId, data?.workspaceId])

  return useQuery(
    [API_KEYS_URLS.GET_API_KEYS, workspaceId],
    () => ApiKeysAPI.getApiKeys(workspaceId),
    {
      enabled: !!workspaceId,
      select: (result) => result.data,
    }
  )
}

type UseOrganizationsByApiKeyType = (
  apiKey: string
) => UseQueryResult<IOrganization[], Error>

export const useOrganizationsByApiKey: UseOrganizationsByApiKeyType = (
  apiKey
) => {
  return useQuery(
    [API_KEYS_URLS.GET_ORGANIZATIONS_BY_API_KEY, apiKey],
    () => ApiKeysAPI.getOrganizationsByApiKey(apiKey),
    {
      enabled: !!apiKey,
      select: (result) => result.data,
    }
  )
}

export const useOrganizationsTags = (organizationId) => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useInfiniteQuery(
    [API_KEYS_URLS.GET_ORGANIZATIONS_TAGS(activeWorkspaceId, organizationId)],
    ({ pageParam = '' }) => ApiKeysAPI.getOrganizationTags(activeWorkspaceId, organizationId, { cursor: pageParam, pageSize: '100' }),
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
      enabled: !!activeWorkspaceId && !!organizationId,
      select: (data) => ({
        ...data,
        pages: data.pages.map(page => ({
          ...page,
          data: page.data,
        })),
      }),
    },
  )
}

export const useOrganizationNetworks = (organizationId) => {
  const { activeWorkspaceId } = useActiveWorkspace()

  return useInfiniteQuery(
    [API_KEYS_URLS.GET_ORGANIZATIONS_NETWORKS(activeWorkspaceId, organizationId)],
    ({ pageParam }) => ApiKeysAPI.getOrganizationNetworks(activeWorkspaceId, organizationId, { cursor: pageParam?.cursor || '', apiKeyId: pageParam?.apiKeyId || '', pageSize: '500' }),
    {
      getNextPageParam: ({ cursor, apiKeyId }) => {
        return {cursor, apiKeyId}
      },
      enabled: !!activeWorkspaceId && !!organizationId,
      select: (data) => ({
        ...data,
        pages: data.pages.map(page => ({
          ...page,
          data: page.data,
        })),
      }),
    },
  )
}

type UseApiKeyType = (id?: string) => UseQueryResult<IApiKey, Error>

export const useApiKey: UseApiKeyType = (id?: string) => {
  const { activeWorkspaceId } = useActiveWorkspace()
  const queryClient = useQueryClient()

  return useQuery(
    [API_KEYS_URLS.GET_API_KEY, id],
    () => ApiKeysAPI.getApiKey(id ?? ''),
    {
      enabled: !!id,
      select: (result) => result.data,
      placeholderData: () => {
        // Fetches the cache data for the GET_API_KEYS query
        const apiKeysData = queryClient.getQueryData<
          AxiosResponse<IApiKeysResponse>
        >([API_KEYS_URLS.GET_API_KEYS, activeWorkspaceId])
        if (!apiKeysData?.data) return undefined
        const allKeys = [
          ...(apiKeysData.data?.personalKeys ?? []),
          ...(apiKeysData.data?.workspaceKeys ?? []),
          ...(apiKeysData.data?.sharedKeys ?? []),
        ]
        if (!allKeys.length) return undefined

        // Tries to find the key with the provided id in the list
        const record = allKeys.find((key) => key.apiKeyId === id)
        if (record) {
          return { data: record }
        }
        return undefined
      },
    }
  )
}

type UseApiKeyOrganizationDevicesType = (
  apiKeyId: string,
  organizationId: string
) => UseQueryResult<IApiKeyOrganizationDevicesResponse, Error>

export const useApiKeyOrganizationDevices: UseApiKeyOrganizationDevicesType = (
  apiKeyId: string,
  organizationId: string
) => {
  return useQuery(
    [API_KEYS_URLS.GET_API_KEY_ORGANIZATION_DEVICES, apiKeyId, organizationId],
    () => ApiKeysAPI.getApiKeyOrganizationDevices(apiKeyId, organizationId),
    {
      select: (result) => result.data,
    }
  )
}

type UseCreateApiKeyType = () => UseMutationResult<
  AxiosResponse<any>,
  Error,
  ICreateApiKey
>

export const useCreateApiKey: UseCreateApiKeyType = () => {
  const queryClient = useQueryClient()

  const createApiKey = async (newApiKeyData: ICreateApiKey) => {
    const response = await ApiKeysAPI.createApiKey(newApiKeyData)
    
    return response
  }

  return useMutation(createApiKey, {
    onSuccess: () => {
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS])
    },
  })
}

type UseUpdateApiKeyType = (
  apiKeyId: string
) => UseMutationResult<AxiosResponse<IApiKey>, Error, IUpdateApiKey>

export const useUpdateApiKey: UseUpdateApiKeyType = (apiKeyId: string) => {
  const queryClient = useQueryClient()
  return useMutation((params) => ApiKeysAPI.updateApiKey(apiKeyId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS])
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEY, apiKeyId])
    },
    onError: (error) => {
      notify(exportErrorMessage(error))
    },
  })
}

type UseUpdateApiKeyUsersType = (
  apiKeyId: string
) => UseMutationResult<
  AxiosResponse<IApiKey>,
  Error,
  { userIds: string[]; revoke?: boolean }
>

export const useUpdateApiKeyUsers: UseUpdateApiKeyUsersType = (
  apiKeyId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ userIds, revoke = false }) =>
      revoke
        ? ApiKeysAPI.revokeApiKeyUsers(apiKeyId, userIds)
        : ApiKeysAPI.shareApiKeyUsers(apiKeyId, userIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS])
        queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEY, apiKeyId])
      },
      onError: (error) => {
        notify(exportErrorMessage(error))
      },
    }
  )
}

type UseUpdateApiKeyOrganizationsType = (
  apiKeyId: string
) => UseMutationResult<
  AxiosResponse<IApiKey>,
  Error,
  { organizationId: string; disable?: boolean; shouldInvalidate?: boolean }
>

export const useUpdateApiKeyOrganizations: UseUpdateApiKeyOrganizationsType = (
  apiKeyId: string
) => {
  const queryClient = useQueryClient()
  const { activeWorkspaceId } = useActiveWorkspace()

  const updateApiKeyOrganizations = async ({ organizationId, disable = false, shouldInvalidate = true }) => {
    const response = await disable
      ? ApiKeysAPI.disableOrganizations(apiKeyId, organizationId)
      : ApiKeysAPI.enableOrganizations(apiKeyId, organizationId)
    
      return response
  }

  return useMutation(updateApiKeyOrganizations,
    {
      onMutate: async ({ organizationId, disable }) => {
        const apiKeyQueryKey = [API_KEYS_URLS.GET_API_KEY, apiKeyId];
  
        await queryClient.cancelQueries(apiKeyQueryKey);
  
        const previousData = queryClient.getQueryData(apiKeyQueryKey);
  
        // queryClient.setQueryData(apiKeyQueryKey, (oldData: any) => {
        //   const safeOldAdminData = oldData || {};

        //   const newOrganizations = oldData?.data?.organizations.map((organization) => {
        //     if (organization.organizationId === organizationId) {
        //       return {
        //         ...organization,
        //         organizationEnabledStatus: disable ? "DISABLED" : "ENABLED",
        //         organizationEnabledErrors: []
        //       }
        //     }
        //     return organization
        //   })

        //   return {
        //     ...safeOldAdminData,
        //     data: {
        //       ...oldData.data,
        //       organizations: newOrganizations
        //     }
        //   }
        // });
  
        return { previousData };
      },
      // onError: (err, variables, context) => {
      //   queryClient.setQueryData([API_KEYS_URLS.GET_API_KEY, apiKeyId], context?.previousData);
      // },
      onSettled(data, error, variables, context) {
        if (variables?.shouldInvalidate !== false) {
          queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS]);
          queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEY, apiKeyId]);
          queryClient.invalidateQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])
          queryClient.invalidateQueries([ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)])
        }
      },
    }
  )
}

type UseDeleteApiKeyType = (
  apiKeyId: string
) => UseMutationResult<ApiDataResponse<unknown>, Error, void>

export const useDeleteApiKey: UseDeleteApiKeyType = (apiKeyId: string) => {
  const queryClient = useQueryClient()
  return useMutation(() => ApiKeysAPI.deleteApiKey(apiKeyId), {
    onSuccess: () => {
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS])
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEY, apiKeyId])
    },
    onError: (error) => {
      notify(exportErrorMessage(error))
    },
  })
}

type UseToggleStatusApiKeyType = (
  apiKeyId: string
) => UseMutationResult<ApiDataResponse<unknown>, Error, boolean>

export const useToggleStatusApiKey: UseToggleStatusApiKeyType = (
  apiKeyId: string
) => {
  const queryClient = useQueryClient()
  const { activeWorkspaceId } = useActiveWorkspace()
  return useMutation(
    (enable) =>
      enable
        ? ApiKeysAPI.enableApiKey(apiKeyId)
        : ApiKeysAPI.disableApiKey(apiKeyId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS])
        queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEY, apiKeyId])
      },
      onError: (error) => {
        notify(exportErrorMessage(error))
      },
      onSettled() {
        queryClient.invalidateQueries([ADMINS_URLS.GET_ADMINS(activeWorkspaceId)])
        queryClient.invalidateQueries([ADMINS_URLS.GET_USER_SUMMARY(activeWorkspaceId)])
      },
    }
  )
}

type UseRefreshApiKey = (
  apiKeyId: string
) => UseMutationResult<ApiDataResponse<unknown>, Error, void>

export const useRefreshApiKey: UseRefreshApiKey = (apiKeyId: string) => {
  const queryClient = useQueryClient()
  return useMutation(() => ApiKeysAPI.refreshApiKey(apiKeyId), {
    onSuccess: () => {
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEYS])
      queryClient.invalidateQueries([API_KEYS_URLS.GET_API_KEY, apiKeyId])
    },
    onError: (error) => {
      notify(exportErrorMessage(error))
    },
  })
}
