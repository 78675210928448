import { Modal } from '@carbon/react'
import useActionModalStore from '../../store/useActionModalStore'

const ActionModal = () => {
  const [open, props, closeModal] = useActionModalStore(
    (state) => [
      state.open,
      state.props,
      state.closeModal,
    ]
  )

  return (
    <Modal
      open={open}
      modalHeading={props?.title}
      passiveModal
      onRequestClose={closeModal}
      {...props?.modalProps}
    >
      {props?.body}
    </Modal>
  )
}

export default ActionModal
